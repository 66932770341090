import { put, takeEvery } from 'redux-saga/effects';
import produce from 'immer';
import axios from 'utils/axios';

export const GET_NOTIFICATION_COUNT = '@app/notification/GET_NOTIFICATION_COUNT';
export const GET_NOTIFICATION_COUNT_SUCCESS = '@app/notification/GET_NOTIFICATION_COUNT_SUCCESS';
export const GET_NOTIFICATION_COUNT_ERROR = '@app/notification/GET_NOTIFICATION_COUNT_ERROR';

export const RESET_NOTIFICATION_COUNT = '@app/notification/RESET_NOTIFICATION_COUNT';

const _state = {
    boughtTipiosCount: null,
    interestedCount: null,
    receivedOffersCount: null,
    notificationsCount: null
};

export default (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_NOTIFICATION_COUNT_SUCCESS: {
                return action.data;
            }
            case RESET_NOTIFICATION_COUNT: {
                return _state;
            }
            case GET_NOTIFICATION_COUNT_ERROR: {
                draft.error = action.error.toString();
                break;
            }
            default:
                break;
        }
    });

export const actions = {
    request: () => ({ type: GET_NOTIFICATION_COUNT }),
    reset: () => ({ type: RESET_NOTIFICATION_COUNT })
};

export const sagas = {
    *requestCount() {
        try {
            const response = yield axios.get('/notifications-count');
            yield put({ type: GET_NOTIFICATION_COUNT_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: GET_NOTIFICATION_COUNT_ERROR, error });
        }
    }
};

export const watcher = function*() {
    yield takeEvery(GET_NOTIFICATION_COUNT, sagas.requestCount);
};
