import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';
import { actions as uiActions } from '../ui';

export const REQUEST = '@app/search/meta/REQUEST';
export const SUCCESS = '@app/search/meta/SUCCESS';
export const ERROR = '@app/search/meta/ERROR';
export const RESET = '@app/search/meta/RESET';

export const JOIN_TIPIO_SEARCH = 'tipioJoin/JOIN_TIPIO_SEARCH';
export const JOIN_TIPIO_SEARCH_SUCCESS = 'tipioJoin/JOIN_TIPIO_SEARCH_SUCCESS';
export const JOIN_TIPIO_SEARCH_ERROR = 'tipioJoin/JOIN_TIPIO_SEARCH_ERROR';

const _state = {
    loading: false,
    data: 0
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                return state;
            case SUCCESS:
                draft.data = action.payload;
                break;
            case JOIN_TIPIO_SEARCH_SUCCESS: {
                const nextState = produce(state, (draftState) => {
                    const tipio_id = action.data.tipio_id;
                    draftState.data[tipio_id] = {
                        ...draftState.data[tipio_id],
                        joined: true,
                        join_count: (parseInt(draftState.data[tipio_id].join_count) + 1).toString()
                    };
                });
                return nextState;
            }
            case JOIN_TIPIO_SEARCH_ERROR:
                draft.error = action.payload;
                break;
            case ERROR:
                draft.error = action.payload;
                break;
            case RESET:
                return _state;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    request: (payload) => createAction(REQUEST, { payload }),
    joinTipio: (payload) => createAction(JOIN_TIPIO_SEARCH, { payload })
};

export const sagas = {
    *request({ payload }) {
        const { query, page, sorter, filter } = payload;
        try {
            let url = `/search/meta/${page}?q=${query}&sorter=${sorter}`;
            if (filter.length) {
                url += `&filters=${filter}`;
            }
            const response = yield axios.get(url);
            const result = response.data;
            if (result.status === 200) {
                yield put(createAction(SUCCESS, { payload: result.data }));
            } else {
                yield put(createAction(ERROR, { payload: 'ERROR!' }));
            }
        } catch (error) {
            yield put(createAction(ERROR, { payload: 'ERROR!' }));
        }
    },
    *joinTipio({ payload }) {
        try {
            yield put(uiActions.notAuthorizedUserRequest());
            let response;
            if (payload.user) {
                response = yield axios.post('/temp-users/tipio-join', {
                    tipio_id: payload.tipio_id,
                    user: payload.user,
                    subscribe: payload.subscribe
                });
            } else {
                response = yield axios.post('/tipio-join', {
                    tipio_id: payload.tipio_id
                });
            }
            yield put({ type: JOIN_TIPIO_SEARCH_SUCCESS, data: { tipio_id: payload.tipio_id } });
            yield put(uiActions.notAuthorizedUserSuccess({ success: true, data: response.data.data }));
        } catch (error) {
            yield put({ type: JOIN_TIPIO_SEARCH_ERROR, error });
            yield put(uiActions.notAuthorizedUserError(error.response.data.errors));
        }
    }
};

export const watcher = function* w() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(JOIN_TIPIO_SEARCH, sagas.joinTipio);
};
