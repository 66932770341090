import { takeLatest, put, takeEvery } from 'redux-saga/effects';
import produce from 'immer';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';
import { actions as uiActions } from '../ui';
export const REQUEST = '@app/viewAllBindingTipios/REQUEST';
export const SUCCESS = '@app/viewAllBindingTipios/SUCCESS';
export const SET_ERROR = '@app/viewAllBindingTipios/SET_ERROR';
export const SET_LOADING = '@app/viewAllBindingTipios/SET_LOADING';

export const JOIN_TIPIO_REQUEST = '@app/viewAllBindingTipios/tipio/JOIN_TIPIO';
export const JOIN_TIPIO_SUCCESS = '@app/viewAllBindingTipios/tipio/JOIN_TIPIO_SUCCESS';
export const JOIN_TIPIO_ERROR = '@app/viewAllBindingTipios/tipio/JOIN_TIPIO_ERROR';

const _state = {
    error: null,
    loading: true,
    data: [],
    meta: {},
    stats: {}
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUCCESS:
                draft.data = action.payload.tipios;
                draft.meta = action.payload.meta;
                draft.stats = action.payload.stats ? action.payload.stats.data : {};
                break;
            case SET_LOADING:
                draft.loading = action.payload;
                break;
            case JOIN_TIPIO_REQUEST:
                draft.isJoinLoading = true;
                break;
            case JOIN_TIPIO_SUCCESS:
                return {
                    ...state,
                    stats: {
                        ...state.stats,
                        [action.data.tipio_id]: {
                            ...state.stats[action.data.tipio_id],
                            unlocked: true,
                            unlock_count:
                                state.stats[action.data.tipio_id] &&
                                state.stats[action.data.tipio_id].unlock_count &&
                                parseInt(state.stats[action.data.tipio_id].unlock_count, 10) + 1
                        }
                    }
                };
            case JOIN_TIPIO_ERROR:
                draft.isJoinLoading = false;
                draft.errorJoin = action.data;
                break;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    request: (payload) => createAction(REQUEST, { payload }),
    setError: (payload) => createAction(SET_ERROR, { payload }),
    joinTipio: (payload) => ({ type: JOIN_TIPIO_REQUEST, payload })
};

export const sagas = {
    *request(action) {
        const { payload } = action;
        yield put(createAction(SET_LOADING, { payload: true }));
        try {
            let url = `/tipio-binding-page/page/${payload.pageParam}/sorted?sorter=${payload.sorted}`;
            if (payload.filter.length) {
                url += `&filters=${payload.filter}`;
            }
            const response = yield axios.get(url);
            const result = response.data;
            if (result.status === 200) {
                yield put(createAction(SET_LOADING, { payload: false }));
                yield put(createAction(SUCCESS, { payload: result.data }));
            } else {
                yield put(
                    createAction(SET_ERROR, {
                        payload: {
                            success: false,
                            message: response.data
                        }
                    })
                );
            }
        } catch (error) {
            // error response handle
            yield put(createAction(SET_LOADING, { payload: false }));
            let errorDescription = '';
            if (error.response) {
                if (error.response.status === 422 || error.status === 500) {
                    errorDescription = error.response.data.message;
                }
            }
            if (errorDescription === '') {
                errorDescription = error.toString();
            }
            yield put(
                createAction(SET_ERROR, {
                    payload: {
                        success: false,
                        message: errorDescription
                    }
                })
            );
        }
    },
    *joinTipio({ payload }) {
        try {
            let response;
            if (payload.user) {
                response = yield axios.post('/temp-users/offer-unlock/', {
                    offer_unlock_demand: { offer_id: payload.offer_id },
                    user: payload.user,
                    subscribe: payload.subscribe
                });
            } else {
                response = yield axios.post('/offer-unlock/', {
                    offer_id: payload.offer_id
                });
            }
            yield put({
                type: JOIN_TIPIO_SUCCESS,
                data: { tipio_id: payload.tipio_id }
            });
            yield put(uiActions.notAuthorizedUserSuccess({ success: true, data: response.data.data }));
        } catch (error) {
            yield put({ type: JOIN_TIPIO_ERROR, error });
            yield put(uiActions.notAuthorizedUserError(error.response.data.errors));
        }
    }
};

export const watcher = function* w() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeEvery(JOIN_TIPIO_REQUEST, sagas.joinTipio);
};
