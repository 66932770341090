import { useState } from 'react';
import useKeys from '@rooks/use-keys';

import { useTranslation } from 'react-i18next';
import { isDev, isStaging } from '../utils/env';

const useL10N = () => {
    const { t } = useTranslation();
    const [displayKey, setDisplayKey] = useState(false);

    // setup key listener
    useKeys(
        ['l', 't'], // L+T
        () => setDisplayKey(!displayKey)
    );

    return {
        t: (key, options) => {
            return displayKey && (isDev() || isStaging()) ? key : t(key, options);
        }
    };
};

export default useL10N;
