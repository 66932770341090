import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';
import produce from 'immer';
import { actions as authActions } from '../auth/auth';

export const USER_VERIFY_REQUEST = '@app/user/VERIFY_REQUEST';
export const USER_VERIFY_SUCCESS = '@app/user/VERIFY_REQUEST_SUCCESS';
export const USER_VERIFY_ERROR = '@app/user/VERIFY_REQUEST_ERROR';

const _state = {
    isLoading: false,
    error: null,
    success: false
};

export default (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case USER_VERIFY_REQUEST: {
                draft.isLoading = true;
                break;
            }
            case USER_VERIFY_SUCCESS: {
                draft.isLoading = false;
                draft.success = true;
                break;
            }
            case USER_VERIFY_ERROR: {
                draft.isLoading = false;
                draft.error = action.payload;
                break;
            }
            default:
                break;
        }
    });

export const actions = {
    request: (payload) => ({ type: USER_VERIFY_REQUEST, payload })
};

export const sagas = {
    *userVerify({ payload }) {
        try {
            const res = yield axios.post('/email/verify', { code: payload });
            yield put(authActions.updateToken(res.data.data.signed.token));
            yield put({ type: USER_VERIFY_SUCCESS });
        } catch (error) {
            yield put({ type: USER_VERIFY_ERROR, error });
        }
    }
};

export const watcher = function*() {
    yield takeEvery(USER_VERIFY_REQUEST, sagas.userVerify);
};
