const getOptionIdForTitle = (offerOptions, id) => {
    for (let i = 0; i < offerOptions.length; i += 1) {
        const offerOption = offerOptions[i];
        if (offerOption.id === id) {
            return offerOption.title;
        }
    }
};

export default (tipio, company_id, offer_id, hasOptions, sizeValue, price, isIceProducts) => {
    let item = {};
    let tipioOffer = tipio?.tipio_offer;
    delete tipio?.tipio_offer;
    tipioOffer.tipio = tipio;
    item.tipio_offer = tipioOffer;
    item.price = isIceProducts ? price.toString() : tipioOffer.price;
    item.company_id = company_id;
    item.hasOptions = hasOptions;
    item.quantity = '1';
    item.tipio_offer_id = offer_id;
    item.sale_offer = null;
    item.sale_offer_id = null;
    item.stripe_session_id = null;
    item.id = offer_id;
    if (hasOptions) {
        item.tipio_offer_option_id = sizeValue;
        item.tipio_offer_option_title = getOptionIdForTitle(tipioOffer.tipio_offer_options, sizeValue);
        item.tipio_offer_option_type = 'SIZE';
    } else {
        item.tipio_offer_option_id = null;
        item.tipio_offer_option_title = null;
        item.tipio_offer_option_type = null;
    }
    return { item, meta: { total_price: isIceProducts ? price : tipioOffer.price } };
};
