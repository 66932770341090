import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';
import describeTipioError from 'utils/describeTipioError';

export const REQUEST = '@app/tipio/edit/REQUEST';
export const SUCCESS = '@app/tipio/edit/SUCCESS';
export const SET_FORM_IS_SUBMITTING = '@app/tipio/edit/SET_FORM_IS_SUBMITTING';
export const SET_FORM_STATUS = '@app/tipio/edit/SET_FORM_STATUS';
export const SET_ERROR = '@app/tipio/edit/SET_ERROR';
export const CLEAR_FORM = '@app/tipio/edit/CLEAR_FORM';
export const CLEAN_UP = '@app/tipio/edit/CLEAN_UP';
export const MANUAL = '@app/tipio/edit/MANUAL';

const _state = {
    error: null,
    isSubmitting: false,
    formStatus: false,
    tipio: null
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SET_FORM_IS_SUBMITTING:
                draft.isSubmitting = action.payload;
                break;
            case SET_FORM_STATUS:
                draft.formStatus = action.payload;
                break;
            case SUCCESS:
                draft.tipio = action.payload;
                break;
            case CLEAN_UP:
                return _state;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    request: (payload) => createAction(REQUEST, { payload }),
    setError: (payload) => createAction(SET_ERROR, { payload }),
    setFormIsSubmiting: (payload) => createAction(SET_FORM_IS_SUBMITTING, { payload }),
    clear: (payload) => createAction(CLEAR_FORM, { payload }),
    cleanUp: (payload) => createAction(CLEAN_UP, { payload }),
    success: (payload) => createAction(SUCCESS, { payload }),
    manual: (payload) => createAction(MANUAL, { payload }),
    clearForm: (payload) => createAction(SET_FORM_STATUS, { payload })
};

export const sagas = {
    *request(action) {
        yield put(actions.setFormIsSubmiting(true));
        const { payload } = action;
        try {
            const files = payload.files.filter((image) => image.tipio_file_store === undefined);
            const imgs = payload.files.filter((image) => !files.includes(image));
            const response = yield axios.patch(
                `/tipios/${payload.id}`,
                Object.assign({}, payload, { images: imgs, files: undefined, id: undefined })
            );
            const result = response.data;

            try {
                if (files.length) {
                    for (let i = 0; i < files.length; i += 1) {
                        const formData = new FormData();
                        const file = files[i];
                        formData.append('files', file);

                        const imagesResponse = yield axios.post(`/tipios/${result.data.id}/files`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });

                        const images = imagesResponse.data;
                        result.data.images = [...result.data.images, ...images.data];
                    }

                    yield put(createAction(SUCCESS, { payload: result.data }));
                    yield put(actions.setFormIsSubmiting(false));
                    yield put(
                        createAction(SET_FORM_STATUS, {
                            payload: {
                                success: true
                            }
                        })
                    );
                } else {
                    yield put(createAction(SUCCESS, { payload: result.data }));
                    yield put(actions.setFormIsSubmiting(false));
                    yield put(
                        createAction(SET_FORM_STATUS, {
                            payload: {
                                success: true
                            }
                        })
                    );
                }
            } catch (e) {
                yield put(actions.setFormIsSubmiting(false));
                yield put(
                    createAction(SET_FORM_STATUS, {
                        payload: {
                            success: false,
                            message: 'Er en begrensning på max 9 bilder'
                        }
                    })
                );
            }
        } catch (error) {
            yield put(actions.setFormIsSubmiting(false));
            yield put(actions.setError((error.response.data && describeTipioError(error.response.data)) || 'Error!'));
        }
    },
    *setError(action) {
        yield put(createAction(SET_FORM_IS_SUBMITTING, { payload: false }));
        yield put(
            createAction(SET_FORM_STATUS, {
                payload: {
                    success: false,
                    message: action.payload
                }
            })
        );
    },
    *clearForm() {
        yield put(actions.clearForm(false));
    }
};

export const watcher = function*() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(SET_ERROR, sagas.setError);
    yield takeLatest(CLEAR_FORM, sagas.clearForm);
};
