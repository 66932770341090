import React from 'react';
import { Router } from 'react-router-dom';
import 'lazysizes';
import ScrollToTop from 'components/Utils/ScrollToTop';
import PageTitle from './pages/PageTitle';
import history from './utils/history';
import Layout from './layout/Layout';
import Routes from './routes/routes';
import UserNotCompleted from 'components/UserNotCompleted';
import './styles/main.scss';

const App = () => (
    <Router history={history}>
        <Layout>
            <UserNotCompleted />
            <ScrollToTop />
            <PageTitle />
            <Routes />
        </Layout>
    </Router>
);

export default App;
