export default (
    additionalSale,
    company_id,
    price,
    additionalSaleId,
    additionalSale_sale_offer_id,
    tipioId,
    binding_expires_in
) => {
    let item = {
        additional_sale_id: additionalSaleId,
        company_id: company_id,
        price: price,
        quantity: '1',
        sale_offer: {
            id: additionalSale_sale_offer_id,
            tipio_offer: { tipio_id: parseInt(tipioId), tipio: { binding_expires_in } }
        },
        sale_offer_id: additionalSale_sale_offer_id,
        tipio_offer: { inventory_available: 10, tipio: additionalSale.tipio }
    };

    return { item, meta: { total_price: price } };
};
