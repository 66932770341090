import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

// export const FETCH_USER = 'FETCH_USER';
// export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
// export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export default function(state = [], action) {
    switch (action.type) {
        case REGISTER_USER_SUCCESS:
            return { ...state, isAuthenticated: true, verified: false, error: null };
        case REGISTER_USER_ERROR:
            return { ...state, error: action.error.toString() };
        // case FETCH_USER_SUCCESS:
        //     return { ...state, user: action.data.data };
        default:
            return state;
    }
}

export const actions = {
    //fetchUser: () => action(FETCH_USER),
    createUser: (user) => ({ type: REGISTER_USER, user })
};

export const watcher = function*() {
    yield takeEvery(REGISTER_USER, sagas.register);
    // yield takeEvery(FETCH_USER, sagas.fetchUser);
};

export const sagas = {
    // fetchUser: function* fetchUserSaga (payload) {
    //     try {
    //         const response = yield axios.get('users/profile', payload);
    //         yield put({ type: FETCH_USER_SUCCESS, response });
    //     } catch (error) {
    //         yield put({ type: FETCH_USER_ERROR, error });
    //     }
    // },
    register: function* registerSaga(payload) {
        try {
            const response = yield axios.post('users', payload);
            yield put({ type: REGISTER_USER_SUCCESS, response });
        } catch (error) {
            yield put({ type: REGISTER_USER_ERROR, error });
        }
    }
};
