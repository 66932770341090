import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';

export const REQUEST = '@app/Tipios/REQUEST';
export const SUCCESS = '@app/Tipios/SUCCESS';
export const ERROR = '@app/Tipios/ERROR';

const _state = {
    loading: false,
    results: [],
    meta: {},
    stats: {}
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                return { ...state, loading: true };
            case SUCCESS:
                draft.loading = false;
                draft.results = action.payload.tipios;
                draft.stats = action.payload.stats.data;
                draft.meta = action.payload.meta;
                break;
            case ERROR:
                draft.loading = false;
                draft.error = action.payload;
                break;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    request: (payload) => createAction(REQUEST, { payload })
};

export const sagas = {
    *request({ payload }) {
        const { filter, page } = payload;
        try {
            let url = '/voting-tipios-private/page/' + page;

            if (parseInt(filter) === 1) {
                url = '/received-offers/page/' + page;
            } else if (parseInt(filter) === 2) {
                url = '/tipio-binding-page/page/' + page;
            }
            const response = yield axios.get(url);
            const result = response.data;
            if (result.status === 200) {
                yield put(createAction(SUCCESS, { payload: result.data }));
            } else {
                yield put(createAction(ERROR, { payload: 'ERROR!' }));
            }
        } catch (error) {
            yield put(createAction(ERROR, { payload: 'ERROR!' }));
        }
    }
};

export const watcher = function* w() {
    yield takeLatest(REQUEST, sagas.request);
};
