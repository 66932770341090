import { put, takeEvery } from 'redux-saga/effects';
import action from 'utils/action-creator';
import axios from 'utils/axios';

export const REQUEST = '@app/incrementTipio/VIEWS/REQUEST';
export const SUCCESS = '@app/incrementTipio/VIEWS/SUCCESS';
export const FAILURE = '@app/incrementTipio/VIEWS/FAILURE';

export default function(state = {}, { type, payload }) {
    switch (type) {
        case REQUEST:
            return { loading: true };
        case SUCCESS:
            return { success: true };
        case FAILURE:
            return { error: payload, success: false };
        default:
            return state;
    }
}

export const actions = {
    request: (payload) => action(REQUEST, { payload }),
    success: () => action(SUCCESS),
    failure: (payload) => action(FAILURE, { payload })
};

export const sagas = {
    *request({ payload }) {
        try {
            yield axios.patch(`/tipios/${payload.tipioId}/views`);
            yield put(actions.success());
        } catch (error) {
            yield put(actions.failure(error.toString()));
        }
    }
};

export const watcher = function*() {
    yield takeEvery(REQUEST, sagas.request);
};
