import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import useL10N from 'hooks/useL10N';
import HeadTitle from '../components/HeadTitle';

const PageTitle: FC<RouteComponentProps> = (props) => {
    const { t } = useL10N();
    const routesWithParams = [
        '/search/query',
        '/tipio/bind',
        '/tipio/vote',
        '/add-additional-sale',
        '/voting-tipios',
        '/expired-offers',
        '/orders',
        '/cart',
        '/search',
        '/error',
        '/exists',
        '/all-binding',
        '/all-votings'
    ];
    const pathName = props.location.pathname;
    let pathId: Array<string> | string = routesWithParams.filter((x) => pathName.indexOf(x) !== -1);
    pathId = pathId.length ? pathId[0] : pathName.replace('/', 'index_');

    return <HeadTitle title={t(`page_title_${pathId}`)} />;
};

export default withRouter(PageTitle);
