import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router/immutable';

import sagas from './sagas/index';
import reducers from './reducers';

export const configureStore = (history, initState) => {
    const sagaMiddleware = createSagaMiddleware();

    const middlewares = [sagaMiddleware, routerMiddleware(history)];

    const store = createStore(
        reducers(),
        initState,
        compose(applyMiddleware(...middlewares), window.devToolsExtension ? window.devToolsExtension() : (f) => f)
    );

    sagaMiddleware.run(sagas);
    return store;
};
