import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR';
export const ORDERS_SHOW_MORE = 'ORDERS_SHOW_MORE';

const initState = {
    loading: false,
    data: [],
    showMore: false
};

export default function(state = initState, action) {
    switch (action.type) {
        case FETCH_ORDERS:
            return { ...state, loading: true, error: null };
        case FETCH_ORDERS_SUCCESS:
            return { ...state, data: action.data, loading: false };
        case FETCH_ORDERS_ERROR:
            return { ...state, error: action.error.toString(), loading: false };
        case ORDERS_SHOW_MORE:
            return { ...state, showMore: true };
        default:
            return state;
    }
}

export const actions = {
    fetchOrders: () => ({ type: FETCH_ORDERS }),
    showMore: () => ({ type: ORDERS_SHOW_MORE })
};

export const sagas = {
    fetchOrders: function* fetchOrders() {
        try {
            const response = yield axios.get('/orders');
            yield put({ type: FETCH_ORDERS_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: FETCH_ORDERS_ERROR, error });
        }
    }
};

export const watcher = function*() {
    yield takeEvery(FETCH_ORDERS, sagas.fetchOrders);
};
