import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import { setLocale } from 'yup';
import { I18N_API_URL } from '../config/i18n';

i18n.use(initReactI18next)
    .use(XHR)
    .init(
        {
            lng: 'nb',
            fallbackLng: 'en',
            react: {
                wait: true
            },
            interpolation: {
                escapeValue: false // react already safes from xss
            },
            backend: {
                crossDomain: true,
                loadPath: I18N_API_URL + 'localization/{{lng}}?dot=true',
                parse: (payload) => JSON.parse(payload).data.content
            }
        },
        (err, t) => {
            const validation = t('form.validation', { returnObjects: true });
            if (typeof validation !== 'string') {
                setLocale(validation);
            }
        }
    );

export default i18n;
