import React from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import mixpanel from 'mixpanel-browser';

export const Loading = () => <div>Loading SDK...</div>;

export const Timedout = () => <div>SDK timed out (check your API key)</div>;

export const getMixpanelDistinctId = () => {
    let distinctId: string;
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        loaded() {
            distinctId = mixpanel.get_property('user_id') || mixpanel.get_distinct_id();
        }
    });
    return distinctId;
};

const FetchSplitKey = () => {
    const {
        auth: { user }
    } = useSelector((state: RootStateOrAny) => state.app);

    return user ? user.email : 'notLoggedIn';
};

export default FetchSplitKey;

// Config section
export const splitSdkConfig = () => {
    const distinctId = getMixpanelDistinctId();
    const keys = {
        core: {
            authorizationKey: process.env.REACT_APP_SPLITIO_API_KEY,
            key: distinctId
        }
    };
    return keys;
};

// The names must match what you defined in the split.io dashboard
export const userTest = 'userTest';
export const adminTest = 'adminTest';
export const splitTest = 'splitTest';
