import { watcher as editTipioWatchers } from './editTipio';
import { watcher as incrementTipioViewsWatchers } from './incrementTipioViews';
import { watcher as reportTipioWatchers } from './reportTipio';
import { watcher as tipioWatchers } from './tipio';
import { watcher as tipioJoinWatchers } from './tipioJoin';
import { watcher as tipioOfferWatchers } from './tipioOffer';
import { watcher as tipioRestoreWatchers } from './tipioRestore';
import { watcher as tipioStatusWatchers } from './tipioStatus';
import { watcher as tipioViewsWatchers } from './tipioView';
import { createTipioWatchers } from './create';

export const tipiosWatchers = [
    editTipioWatchers(),
    incrementTipioViewsWatchers(),
    reportTipioWatchers(),
    tipioWatchers(),
    tipioJoinWatchers(),
    tipioOfferWatchers(),
    tipioRestoreWatchers(),
    tipioStatusWatchers(),
    tipioViewsWatchers(),
    ...createTipioWatchers
];
