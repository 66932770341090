import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';
import produce from 'immer';
import { actions as uiActions } from '../ui';

export const CHECK_IF_USER_BOUGHT_TIPIO = '@app/tipioBind/CHECK_IF_USER_BOUGHT_TIPIO';
export const CHECK_IF_USER_BOUGHT_TIPIO_SUCCESS = '@app/tipioBind/CHECK_IF_USER_JOINED_TIPIO_SUCCESS';
export const CHECK_IF_USER_BOUGHT_TIPIO_ERROR = '@app/tipioBind/CHECK_IF_USER_JOINED_TIPIO_ERROR';

export const NOTIFY_USER_NO_OFFER = '@app/tipioBind/NOTIFY_USER_NO_OFFER';
export const NOTIFY_USER_NO_OFFER_SUCCESS = '@app/tipioBind/NOTIFY_USER_NO_OFFER_SUCCESS';
export const NOTIFY_USER_NO_OFFER_ERROR = '@app/tipioBind/NOTIFY_USER_NO_OFFER_ERROR';

export const REQUEST_META = '@app/tipioBind/REQUEST_META';
export const SUCCESS_META = '@app/tipioBind/SUCCESS_META';
export const ERROR_META = '@app/tipioBind/ERROR_META';

export const REQUSET_ADDITIONAL_SALE_REQUEST = '@app/tipioBind/REQUSET_ADDITIONAL_SALE_REQUEST';
export const SUCCESS_ADDITIONAL_SALE_REQUEST = '@app/tipioBind/SUCCESS_ADDITIONAL_SALE_REQUEST';
export const ERROR_ADDITIONAL_SALE_REQUEST = '@app/tipioBind/ERROR_ADDITIONAL_SALE_REQUEST';

export const REQUEST_RELATED_TIPIO_REQUEST = '@app/tipioBind/REQUEST_RELATED_TIPIO_REQUEST';
export const SUCCESS_RELATED_TIPIO_REQUEST = '@app/tipioBind/SUCCESS_RELATED_TIPIO_REQUEST';
export const ERROR_RELATED_TIPIO_REQUEST = '@app/tipioBind/ERROR_RELATED_TIPIO_REQUEST';

export const JOIN_RELATED_TIPIO = '@app/tipioBind/relatedTipioJoin/JOIN_TIPIO';
export const JOIN_RELATED_TIPIO_SUCCESS = '@app/tipioBind/relatedTipioJoin/JOIN_TIPIO_SUCCESS';
export const JOIN_RELATED_TIPIO_ERROR = '@app/tipioBind/relatedTipioJoin/JOIN_TIPIO_ERROR';

export const JOIN_TIPIO_REQUEST = '@app/tipioBind/tipio/JOIN_TIPIO';
export const JOIN_TIPIO_SUCCESS = '@app/tipioBind/tipio/JOIN_TIPIO_SUCCESS';
export const JOIN_TIPIO_ERROR = '@app/tipioBind/tipio/JOIN_TIPIO_ERROR';

export const REMOVE_JOIN_TIPIO_REQUEST = '@app/tipioBind/tipio/REMOVE_JOIN_TIPIO';
export const REMOVE_JOIN_TIPIO_SUCCESS = '@app/tipioBind/tipio/REMOVE_JOIN_TIPIO_SUCCESS';
export const REMOVE_JOIN_TIPIO_ERROR = '@app/tipioBind/tipio/REMOVE_JOIN_TIPIO_ERROR';

const _state = {
    buyTipioLoading: false,
    bought: false,
    buyTipioError: null,
    notifyUserNoOffer: false,
    notifyUserUserBuyTimeOver: false,
    notifyUserNewOffer: false,
    notifyUserMoreSales: false,
    tipio_bind: null,
    meta: null,
    additionalSales: null,
    additionalSalesLoading: false,
    relatedTipios: null,
    relatedTipiosLoading: false,
    isJoinLoading: false,
    errorJoin: null
};

export default (state = _state, action) =>
    produce(state, (draft) => {
        draft.tipioFetchLoading = false;
        switch (action.type) {
            case CHECK_IF_USER_BOUGHT_TIPIO:
                return _state;
            case CHECK_IF_USER_BOUGHT_TIPIO_SUCCESS:
                if (action.data) {
                    draft.bought = true;
                    draft.tipio_bind = action.data;
                }
                break;
            case CHECK_IF_USER_BOUGHT_TIPIO_ERROR:
                draft.error = action.error.toString();
                break;
            case NOTIFY_USER_NO_OFFER_SUCCESS: {
                draft.notifyUserNoOffer = action.data.notifyUserNoOffer;
                draft.notifyUserUserBuyTimeOver = action.data.notifyUserUserBuyTimeOver;
                draft.notifyUserNewOffer = action.data.notifyUserNewOffer;
                draft.notifyUserMoreSales = action.data.notifyUserMoreSales;
                break;
            }
            case NOTIFY_USER_NO_OFFER_ERROR:
                break;
            case REQUEST_META:
                draft.isLoading = true;
                break;
            case SUCCESS_META:
                draft.meta = action.data;
                draft.isLoading = false;
                break;
            case ERROR_META:
                draft.meta = action.data;
                draft.isLoading = false;
                break;
            case REQUSET_ADDITIONAL_SALE_REQUEST:
                draft.additionalSalesLoading = true;
                break;
            case SUCCESS_ADDITIONAL_SALE_REQUEST:
                draft.additionalSales = action.data;
                draft.additionalSalesLoading = false;
                // Add a success here to know if we should render the related tipios afterwards
                break;
            case ERROR_ADDITIONAL_SALE_REQUEST:
                draft.additionalSales = action.data;
                draft.additionalSalesLoading = false;
                break;
            case REQUEST_RELATED_TIPIO_REQUEST:
                draft.relatedTipiosLoading = true;
                break;
            case SUCCESS_RELATED_TIPIO_REQUEST:
                draft.relatedTipios = action.data;
                draft.relatedTipiosLoading = false;
                // Add a success here to know if we should render the related tipios afterwards
                break;
            case ERROR_RELATED_TIPIO_REQUEST:
                draft.relatedTipios = action.data;
                draft.relatedTipiosLoading = false;
                break;
            case JOIN_RELATED_TIPIO_SUCCESS: {
                const nextState = produce(state, (draftState) => {
                    const tipio_id = action.data.tipio_id;
                    draftState.relatedTipios.stats.data[tipio_id] = {
                        ...draftState.relatedTipios.stats.data[tipio_id],
                        joined: true,
                        join_count: (parseInt(draftState.relatedTipios.stats.data[tipio_id].join_count) + 1).toString()
                    };
                });
                return nextState;
            }
            case JOIN_TIPIO_REQUEST:
                draft.isJoinLoading = true;
                break;
            case JOIN_TIPIO_SUCCESS:
                draft.isJoinLoading = false;
                const unlocked_count = parseInt(state.meta.unlock_count) + 1;
                draft.meta.unlock_count = unlocked_count.toString();
                draft.meta.unlocked = true;
                break;
            case JOIN_TIPIO_ERROR:
                draft.isJoinLoading = false;
                draft.errorJoin = action.data;
                break;
            case REMOVE_JOIN_TIPIO_SUCCESS:
                draft.isJoinLoading = false;
                const unlock_count = parseInt(state.meta.unlock_count) - 1;
                draft.meta.unlock_count = unlock_count.toString();
                draft.meta.unlocked = false;
                break;
            default:
                break;
        }
    });

export const actions = {
    requestCheck: (tipioId) => ({
        type: CHECK_IF_USER_BOUGHT_TIPIO,
        id: tipioId
    }),
    meta: (tipioId) => ({ type: REQUEST_META, id: tipioId }),
    fetchAdditionalSales: (tipioId) => ({
        type: REQUSET_ADDITIONAL_SALE_REQUEST,
        id: tipioId
    }),
    fetchRelatedTipios: (tipioId) => ({
        type: REQUEST_RELATED_TIPIO_REQUEST,
        id: tipioId
    }),
    joinRelatedTipio: (payload) => ({ type: JOIN_RELATED_TIPIO, payload }),
    joinTipio: (payload) => ({ type: JOIN_TIPIO_REQUEST, payload }),
    removeJoinTipio: (payload) => ({ type: REMOVE_JOIN_TIPIO_REQUEST, payload })
};

export const sagas = {
    *requestCheck(payload) {
        try {
            const response = yield axios.get('/tipio-binding/' + payload.id);
            yield put({
                type: CHECK_IF_USER_BOUGHT_TIPIO_SUCCESS,
                data: response.data.data
            });
            yield put({
                type: NOTIFY_USER_NO_OFFER,
                id: response.data.data.id
            });
            // yield put({ type: UPDATE_LAST_VISITED_NOW, id: response.data.data.id });
            // update last_seen
        } catch (error) {
            yield put({ type: CHECK_IF_USER_BOUGHT_TIPIO_ERROR, error });
        }
    },
    *notifyUserNoOffer(payload) {
        try {
            const response = yield axios.get('/tipio-binding/notify-user-no-offer/' + payload.id);
            yield put({
                type: NOTIFY_USER_NO_OFFER_SUCCESS,
                data: response.data.data
            });
        } catch (error) {
            yield put({ type: NOTIFY_USER_NO_OFFER_ERROR, error });
        }
    },
    *meta(payload) {
        try {
            const response = yield axios.get('/company/tipio-meta/binding/' + payload.id);
            yield put({ type: SUCCESS_META, data: response.data.data });
        } catch (error) {
            yield put({ type: ERROR_META, error });
        }
    },
    *fetchAdditionalSales(payload) {
        try {
            const response = yield axios.get(`/company/tipio-meta/${payload.id}/additional-sales`);
            yield put({
                type: SUCCESS_ADDITIONAL_SALE_REQUEST,
                data: response.data.data
            });
        } catch (error) {
            yield put({ type: ERROR_ADDITIONAL_SALE_REQUEST, error });
        }
    },
    *fetchRelatedTipios(payload) {
        try {
            const response = yield axios.get(`/related/binding/${payload.id}`);
            yield put({
                type: SUCCESS_RELATED_TIPIO_REQUEST,
                data: response.data.data
            });
        } catch (error) {
            yield put({ type: ERROR_RELATED_TIPIO_REQUEST, error });
        }
    },
    *joinRelatedTipio({ payload }) {
        try {
            yield put(uiActions.notAuthorizedUserRequest());
            let response;
            if (payload.user) {
                response = yield axios.post('/temp-users/tipio-join', {
                    tipio_id: payload.tipio_id,
                    user: payload.user,
                    subscribe: payload.subscribe
                });
            } else {
                response = yield axios.post('/tipio-join', {
                    tipio_id: payload.tipio_id
                });
            }
            yield put({
                type: JOIN_RELATED_TIPIO_SUCCESS,
                data: { tipio_id: payload.tipio_id }
            });
            yield put(uiActions.notAuthorizedUserSuccess({ success: true, data: response.data.data }));
        } catch (error) {
            yield put({ type: JOIN_RELATED_TIPIO_ERROR, error });
            yield put(uiActions.notAuthorizedUserError(error.response.data.errors));
        }
    },
    *joinTipio({ payload }) {
        try {
            let response;
            if (payload.user) {
                response = yield axios.post('/temp-users/offer-unlock/', {
                    offer_unlock_demand: { offer_id: payload.tipio_id },
                    user: payload.user,
                    subscribe: payload.subscribe
                });
            } else {
                response = yield axios.post('/offer-unlock/', {
                    offer_id: payload.tipio_id
                });
            }
            yield put({
                type: JOIN_TIPIO_SUCCESS,
                data: { tipio_id: payload.tipio_id }
            });
            yield put(uiActions.notAuthorizedUserSuccess({ success: true, data: response.data.data }));
        } catch (error) {
            yield put({ type: JOIN_TIPIO_ERROR, error });
            yield put(uiActions.notAuthorizedUserError(error.response.data.errors));
        }
    },
    *removeJoinTipio({ payload }) {
        try {
            yield axios.delete('/offer-unlock/' + payload.offer_id);
            yield put({
                type: REMOVE_JOIN_TIPIO_SUCCESS,
                data: { tipio_id: payload.tipio_id }
            });
        } catch (error) {
            yield put({ type: REMOVE_JOIN_TIPIO_ERROR, error });
        }
    }
};

export const watcher = function*() {
    yield takeEvery(CHECK_IF_USER_BOUGHT_TIPIO, sagas.requestCheck);
    yield takeEvery(NOTIFY_USER_NO_OFFER, sagas.notifyUserNoOffer);
    yield takeEvery(REQUEST_META, sagas.meta);
    yield takeEvery(REQUSET_ADDITIONAL_SALE_REQUEST, sagas.fetchAdditionalSales);
    yield takeEvery(REQUEST_RELATED_TIPIO_REQUEST, sagas.fetchRelatedTipios);
    yield takeEvery(JOIN_RELATED_TIPIO, sagas.joinRelatedTipio);
    yield takeEvery(JOIN_TIPIO_REQUEST, sagas.joinTipio);
    yield takeEvery(REMOVE_JOIN_TIPIO_REQUEST, sagas.removeJoinTipio);
};
