import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';

export const FETCH_TIPIO = 'tipio/FETCH_TIPIO';
export const FETCH_TIPIO_SUCCESS = 'tipio/FETCH_TIPIO_SUCCESS';
export const FETCH_TIPIO_ERROR = 'tipio/FETCH_TIPIO_ERROR';

export const FETCH_RELATED_OFFERS = 'tipioView/FETCH_RELATED_OFFERS';
export const FETCH_RELATED_OFFERS_SUCCESS = 'tipioView/FETCH_RELATED_OFFERS_SUCCESS';
export const FETCH_RELATED_OFFERS_ERROR = 'tipioView/FETCH_RELATED_OFFERS_ERROR ';

const initialState = {
    loading: true,
    relatedOffers: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_TIPIO:
            return {
                ...state,
                loading: true,
                error: null,
                item: initialState.item
            };
        case FETCH_TIPIO_SUCCESS:
            return { ...state, ...action.data, loading: false };
        case FETCH_TIPIO_ERROR:
            return { ...state, error: action.error.toString(), loading: false };
        case FETCH_RELATED_OFFERS:
            return { ...state };
        case FETCH_RELATED_OFFERS_SUCCESS:
            return { ...state, relatedOffers: action.data, loading: false };
        case FETCH_RELATED_OFFERS_ERROR:
            return { ...state, error: action.error.toString(), loading: false };
        default:
            return state;
    }
}

export const actions = {
    fetchTipio: (orderId) => ({ type: FETCH_TIPIO, id: orderId })
};

export const watcher = function*() {
    yield takeEvery(FETCH_TIPIO, sagas.fetchTipio);
    yield takeEvery(FETCH_RELATED_OFFERS, sagas.fetchRelatedOffers);
};

export const sagas = {
    fetchTipio: function* fetchTipio(payload) {
        try {
            const response = yield axios.get('/tipio-offers/' + payload.id);
            yield put({ type: FETCH_TIPIO_SUCCESS, data: response.data.data });
            yield put({ type: FETCH_RELATED_OFFERS, id: payload.id });
        } catch (error) {
            yield put({ type: FETCH_TIPIO_ERROR, error });
        }
    },
    fetchRelatedOffers: function* fetchRelatedOffers(payload) {
        try {
            const response = yield axios.get('/tipio-offer-related/' + payload.id + '/limit/8');
            yield put({ type: FETCH_RELATED_OFFERS_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: FETCH_RELATED_OFFERS_ERROR, error });
        }
    }
};
