import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router';
import SearchIcon from '../static/assets/images/search.svg';
import SearchClose from '../static/assets/images/close.svg';
import withL10N from 'hoc/withL10N';
import axios from 'utils/axios';
const qs = require('query-string');

const SearchNav = (props) => {
    const [value, setValue] = useState('');
    const [suggestionList, setSuggestionList] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
        const params = qs.parse(props.location.search, {
            ignoreQueryPrefix: true
        });
        setValue(params.q);
    }, [props.location.search]);

    const getSearchSuggestions = (query) => {
        if (query) {
            let url = '/search/suggest/' + query;
            axios
                .get(url)
                .then((response) => {
                    const suggestionList = response.data.data.tipios;
                    setSuggestionList(suggestionList);
                    if (suggestionList.length > 0) props.setAddOverlay(true);
                })
                .catch((_) => {
                    props.setAddOverlay(false);
                });
        } else {
            props.setAddOverlay(false);
            setSuggestionList([]);
        }
    };

    const searchHandleKeyUp = (e) => {
        if (e.key !== 'Escape') {
            getSearchSuggestions(e.target.value);
        }
    };

    const _onChange = (e) => {
        setValue(e.target.value);
    };

    const _onCloseSearch = (_) => {
        props.setAddOverlay(false);
        setValue('');
        props.searchButtonOnClick();
    };
    const _onSuggestionItemClick = (e) => {
        setValue(e.target.id);
        props.searchSuggestionItemOnClick(e.target.id);
    };

    const { t } = props;
    return (
        <>
            <div className="search show">
                <div className="search-form">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t('private.search.placeholder')}
                        onKeyDown={props.searchHandleKeyDown}
                        onKeyUp={searchHandleKeyUp}
                        onChange={_onChange}
                        value={value || ''}
                        ref={inputRef}
                    />

                    <img src={SearchIcon} className="search-icon" alt="" width="20" height="20" />

                    <span onClick={_onCloseSearch}>
                        <img src={SearchClose} alt="" className="search-close" />
                    </span>
                </div>

                {props.addOverlay && suggestionList.length !== 0 && (
                    <div className="search-suggestion">
                        <ul>
                            <li key="found">
                                <small className="text-primary">{t('private.search.foundResult')}</small>
                            </li>
                            {suggestionList.length !== 0 &&
                                suggestionList.map((item, i) => (
                                    <li key={i}>
                                        <span id={item.title} onClick={_onSuggestionItemClick}>
                                            {item.title}
                                        </span>
                                    </li>
                                ))}
                            {value.length > 0 && suggestionList.length === 0 && <p>{t('private.search.noResult')}</p>}
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
};

export default withRouter(withL10N(SearchNav));
