import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';

export const GET_TIPIO_STATUS = 'timeline/GET_TIPIO_STATUS';
export const GET_TIPIO_STATUS_SUCCESS = 'timeline/GET_TIPIO_STATUS_SUCCESS';
export const GET_TIPIO_STATUS_ERROR = 'timeline/GET_TIPIO_STATUS_ERROR';
export const SET_JOINED = 'timeline/SET_JOINED';

const initialState = {
    loading: true,
    data: {}
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_TIPIO_STATUS:
            return initialState;
        case GET_TIPIO_STATUS_SUCCESS:
            return { ...state, data: action.data, loading: false };
        case GET_TIPIO_STATUS_ERROR:
            return { ...state, loading: false, error: action.error };
        case SET_JOINED:
            return {
                ...state,
                data: {
                    ...state.data,
                    tipio: { ...state.data.tipio, meta: { ...state.data.tipio.meta, joined: action.joined } }
                }
            };
        default:
            return state;
    }
}

export const actions = {
    getTipioStatus: (tipioId) => ({ type: GET_TIPIO_STATUS, data: tipioId }),
    setJoined: (joined) => ({ type: SET_JOINED, data: joined })
};

export const watcher = function*() {
    yield takeEvery(GET_TIPIO_STATUS, sagas.getTipioStatus);
};

export const sagas = {
    getTipioStatus: function* getTipioStatus(payload) {
        try {
            const response = yield axios.get('/tipio-status/' + payload.data);
            yield put({ type: GET_TIPIO_STATUS_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: GET_TIPIO_STATUS_ERROR, error });
        }
    }
};
