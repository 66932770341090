import { watcher as checkoutWatchers } from './checkout';
import { watcher as cartWatchers } from './cart';
import { watcher as cartAdditionalSaleWatchers } from './cartAdditionalSales';
import { watcher as iceCheckoutWatcher } from './iceCheckout';
import { watcher as purchseWithoutAuthWatcher } from './purchaseWithoutAuth';
import { watcher as couponsWatcher } from './coupons';

export const checkoutsWatchers = [
    checkoutWatchers(),
    cartWatchers(),
    cartAdditionalSaleWatchers(),
    iceCheckoutWatcher(),
    purchseWithoutAuthWatcher(),
    couponsWatcher()
];
