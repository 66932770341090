import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';
import createAction from 'utils/action-creator';

export const PARSE_URL_FAILURE = '@app/parserSiteRules/PARSE_URL_FAILURE';
export const PARSE_URL_REQUEST = '@app/parserSiteRules/PARSE_URL_REQUEST';
export const PARSE_URL_SUCCESS = '@app/parserSiteRules/PARSE_URL_SUCCESS';
export const RESET = '@app/parserSiteRules/RESET';

const _state = {
    loading: false,
    data: {}
};

export default function(state = _state, action) {
    switch (action.type) {
        case PARSE_URL_REQUEST:
            return { loading: true };
        case PARSE_URL_FAILURE:
            return { ...state, data: action.payload.response.data, loading: false };
        case PARSE_URL_SUCCESS:
            return { ...state, data: action.payload, loading: false };
        case RESET:
            return _state;
        default:
            return state;
    }
}

export const actions = {
    request: (payload) => createAction(PARSE_URL_REQUEST, { payload }),
    reset: () => createAction(RESET)
};

export const watcher = function*() {
    yield takeEvery(PARSE_URL_REQUEST, sagas.request);
};

export const sagas = {
    *request(action) {
        const { payload } = action;
        try {
            const response = yield axios.get('/parse/' + encodeURIComponent(payload) + '?debug=true');
            const result = response.data;
            yield put(createAction(PARSE_URL_SUCCESS, { payload: result.data }));
        } catch (error) {
            yield put(createAction(PARSE_URL_FAILURE, { payload: error }));
        }
    }
};
