import { put, takeEvery } from 'redux-saga/effects';
import action from 'utils/action-creator';
import axios from 'utils/axios';

export const ADD_FAVORITE_REQUEST = 'ADD_FAVORITE_REQUEST';
export const ADD_FAVORITE_SUCCESS = 'ADD_FAVORITE_SUCCESS';
export const ADD_FAVORITE_FAILURE = 'ADD_FAVORITE_FAILURE';
export const REMOVE_FAVORITE_REQUEST = 'REMOVE_FAVORITE_REQUEST';
export const REMOVE_FAVORITE_SUCCESS = 'REMOVE_FAVORITE_SUCCESS';
export const REMOVE_FAVORITE_FAILURE = 'REMOVE_FAVORITE_FAILURE';
export const CLEAR_FAVORITE_MAP = 'CLEAR_FAVORITE_MAP';

// const _state = {
//     tipioFavorite: {}
// };

export default function(state = [], action) {
    switch (action.type) {
        case ADD_FAVORITE_REQUEST:
            return { ...state, loading: true };
        case ADD_FAVORITE_SUCCESS: {
            let tipioFavorite = state.tipioFavorite || {};
            tipioFavorite[action.data.tipio_id] = true;
            return { loading: false, tipioFavorite };
        }
        case ADD_FAVORITE_FAILURE:
            return { ...state, loading: false, error: action.error };
        case REMOVE_FAVORITE_REQUEST:
            return { ...state, loading: true };
        case REMOVE_FAVORITE_SUCCESS:
            return { ...state, loading: true };
        case REMOVE_FAVORITE_FAILURE:
            return { ...state, loading: true };
        case CLEAR_FAVORITE_MAP:
            return {};
        default:
            return state;
    }
}

export const actions = {
    request: (tipioId) => action(ADD_FAVORITE_REQUEST, { tipioId }),
    success: (data) => action(ADD_FAVORITE_SUCCESS, { data }),
    failure: (error) => action(ADD_FAVORITE_FAILURE, { error }),
    removeRequest: (favoriteId) => action(REMOVE_FAVORITE_REQUEST, { favoriteId }),
    removeSuccess: (data) => action(REMOVE_FAVORITE_SUCCESS, { data }),
    removeFailure: (error) => action(REMOVE_FAVORITE_FAILURE, { error }),
    clearFavoriteMap: () => action(CLEAR_FAVORITE_MAP)
};

export const watcher = function*() {
    yield takeEvery(ADD_FAVORITE_REQUEST, sagas.request);
    yield takeEvery(REMOVE_FAVORITE_REQUEST, sagas.removeRequest);
};

export const sagas = {
    request: function*({ tipioId }) {
        try {
            const response = yield axios.post('tipio-favorites', { tipio_id: tipioId });
            yield put({ type: ADD_FAVORITE_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: ADD_FAVORITE_FAILURE, error });
        }
    },
    removeRequest: function*({ favoriteId }) {
        try {
            const response = yield axios.delete('tipio-favorites/' + favoriteId);
            yield put({ type: REMOVE_FAVORITE_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: REMOVE_FAVORITE_FAILURE, error });
        }
    }
};
