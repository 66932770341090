import { put, takeEvery } from 'redux-saga/effects';
import action from 'utils/action-creator';
import decode from 'jwt-decode';
import axios from 'utils/axios';
import produce from 'immer';
import { push, replace } from 'react-router-redux';
import { CLEAR_CART } from '../checkout/cart';
import { FETCH_SHOPPINGBAG_REQUEST } from '../shoppingBag/shoppingBag';
import { actions as notificationActions } from '../notification';
import { reloadInterceptors } from '../../../utils/axios';

export const AUTH_REQUEST = '@app/auth/AUTH_REQUEST';
export const AUTH_SUCCESS = '@app/auth/AUTH_SUCCESS';
export const USER_CREATE_SUCCESS = '@app/auth/USER_CREATE_SUCCESS';
export const USER_COMPLETED_SUCCESS = '@app/auth/USER_COMPLETED_SUCCESS';
export const AUTH_FAILURE = '@app/auth/AUTH_FAILURE';
export const AUTH_INIT = '@app/auth/AUTH_INIT';
export const AUTH_LOGOUT = '@app/auth/AUTH_LOGOUT';
export const AUTH_LOGOUT_SUCCESS = '@app/auth/AUTH_LOGOUT_SUCCESS';
export const AUTH_EXP_CHECK = '@app/auth/AUTH_EXP_CHECK';

export const FETCH_USER = '@app/auth/FETCH_USER';
export const FETCH_USER_SUCCESS = '@app/auth/FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = '@app/auth/FETCH_USER_ERROR';

export const UPDATE_TOKEN = '@app/auth/UPDATE_TOKEN';
export const UPDATE_TOKEN_SUCCESS = '@app/auth/UPDATE_TOKEN_SUCCESS';

export const USER_PROFILE_PICTURE_CHANGED = '@app/auth/USER_PROFILE_PICTURE_CHANGED';

export const SET_JOIN_TIPIO_TOUR_VIEWED_REQUEST = '@app/auth//SET_JOIN_TIPIO_TOUR_VIEWED_REQUEST';
export const SET_JOIN_TIPIO_TOUR_VIEWED_SUCCESS = '@app/auth//SET_JOIN_TIPIO_TOUR_VIEWED_SUCCESS';
export const SET_JOIN_TIPIO_TOUR_VIEWED_FAILURE = '@app/auth//SET_JOIN_TIPIO_TOUR_VIEWED_FAILURE';

// join_tipio_on_board_viewed
export const SET_JOIN_TIPIO_ON_BOARD_VIEWED_REQUEST = '@app/auth/SET_JOIN_TIPIO_ON_BOARD_VIEWED_REQUEST';
export const SET_JOIN_TIPIO_ON_BOARD_VIEWED_SUCCESS = '@app/auth/SET_JOIN_TIPIO_ON_BOARD_VIEWED_SUCCESS';
export const SET_JOIN_TIPIO_ON_BOARD_VIEWED_FAILURE = '@app/auth/SET_JOIN_TIPIO_ON_BOARD_VIEWED_FAILURE';

export const SET_BUY_TIPIO_ON_BOARD_VIEWED_REQUEST = '@app/auth/SET_BUY_TIPIO_ON_BOARD_VIEWED_REQUEST';
export const SET_BUY_TIPIO_ON_BOARD_VIEWED_SUCCESS = '@app/auth/SET_BUY_TIPIO_ON_BOARD_VIEWED_SUCCESS';
export const SET_BUY_TIPIO_ON_BOARD_VIEWED_FAILURE = '@app/auth/SET_BUY_TIPIO_ON_BOARD_VIEWED_FAILURE';

export const INCREASE_FRONTPAGE_VISITS_REQUEST = '@app/auth/INCREASE_FRONTPAGE_VISITS_REQUEST';
export const INCREASE_FRONTPAGE_VISITS_SUCCESS = '@app/auth/INCREASE_FRONTPAGE_VISITS_SUCCESS';
export const INCREASE_FRONTPAGE_VISITS_FAILURE = '@app/auth/INCREASE_FRONTPAGE_VISITS_FAILURE';

export const SET_REDIRECT_URL = '@app/auth/SET_REDIRECT_URL';
export const CLEAR_FORM = '@app/auth/CLEAR_FORM';
export const RESET_FORM_STATUS = '@app/auth/RESET_FORM_STATUS';

const _state = {
    token: null,
    exp: null,
    isAuthenticated: false,
    error: null,
    user: {}
};

export default function(state = [], action) {
    switch (action.type) {
        case AUTH_REQUEST:
            return { ...state, loading: true };
        case AUTH_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                error: null,
                token: action.token,
                loading: false,
                user: action.user
            };
        case USER_CREATE_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                error: null,
                token: action.token,
                loading: false,
                user: action.user
            };
        case USER_COMPLETED_SUCCESS:
            return {
                ...state,
                user: { ...state.user, ...action.data },
                loading: false,
                completed: true
            };
        case USER_PROFILE_PICTURE_CHANGED: {
            const nextState = produce(state, (draftState) => {
                draftState.user.profile_picture = action.data.blob_url;
            });
            return nextState;
        }
        case AUTH_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                error: action.error,
                loading: false
            };
        case AUTH_LOGOUT:
            return { ...state };
        case AUTH_LOGOUT_SUCCESS:
            return _state;
        case AUTH_EXP_CHECK:
            return { ...state, exp: action.exp };
        case FETCH_USER:
            return { ...state, user: action.data, fetchUserLoading: true };
        case FETCH_USER_SUCCESS:
            return { ...state, user: action.data, fetchUserLoading: false };
        case FETCH_USER_ERROR:
            return { ...state, loading: false, error: action.error };
        case UPDATE_TOKEN:
            return { ...state, user: { ...state }, loading: true };
        case UPDATE_TOKEN_SUCCESS:
            return { ...state, user: { ...state.user.user, verified: true, email: action.email }, loading: false };
        case SET_JOIN_TIPIO_TOUR_VIEWED_REQUEST:
            return { ...state, loading: true };
        case SET_JOIN_TIPIO_TOUR_VIEWED_SUCCESS:
            return { ...state, user: { ...state.user, join_tipio_tour_viewed: true }, loading: false };
        case SET_JOIN_TIPIO_TOUR_VIEWED_FAILURE:
            return { ...state, loading: false };
        case SET_JOIN_TIPIO_ON_BOARD_VIEWED_REQUEST:
            return { ...state, loading: true };
        case SET_JOIN_TIPIO_ON_BOARD_VIEWED_SUCCESS:
            return { ...state, user: { ...state.user, join_tipio_on_board_viewed: true }, loading: false };
        case SET_BUY_TIPIO_ON_BOARD_VIEWED_SUCCESS:
            return { ...state, user: { ...state.user, buy_tipio_on_board_viewed: true }, loading: false };
        case SET_JOIN_TIPIO_ON_BOARD_VIEWED_FAILURE:
            return { ...state, loading: false };
        case CLEAR_FORM:
            return _state;
        case RESET_FORM_STATUS:
            return { ...state, loading: false };
        case INCREASE_FRONTPAGE_VISITS_SUCCESS:
            return { ...state, user: { ...state.user, frontpage_visits: action.data } };
        default:
            return state;
    }
}

export const actions = {
    init: () => action(AUTH_INIT),
    request: (payload) => action(AUTH_REQUEST, { payload }),
    success: (token, user) => action(AUTH_SUCCESS, { token, user }),
    userCreateSuccess: (token, user) => action(USER_CREATE_SUCCESS, { token, user }),
    userCompletedSuccess: (data) => action(USER_COMPLETED_SUCCESS, { data }),
    resetFormStatus: () => action(RESET_FORM_STATUS),
    userProfilePictureChanged: (data) => action(USER_PROFILE_PICTURE_CHANGED, { data }),
    failure: (error) => action(AUTH_FAILURE, { error }),
    logout: (redirectToLogin) => action(AUTH_LOGOUT, { redirectToLogin }),
    exp: (exp) => action(AUTH_EXP_CHECK, { exp }),
    profile: () => action(FETCH_USER),
    updateToken: (token) => action(UPDATE_TOKEN, { token }),
    updateTokenSuccess: (data) => action(UPDATE_TOKEN_SUCCESS, data),
    setJoinTipioTourViewedTrue: () => action(SET_JOIN_TIPIO_TOUR_VIEWED_REQUEST),
    setJoinTipioOnBoardViewedTrue: () => action(SET_JOIN_TIPIO_ON_BOARD_VIEWED_REQUEST),
    setBuyTipioOnBoardViewedTrue: () => action(SET_BUY_TIPIO_ON_BOARD_VIEWED_REQUEST),
    setRedirectUrl: (redirectUrl) => action(SET_REDIRECT_URL, { redirectUrl }),
    clearForm: () => action(CLEAR_FORM),
    increaseFrontpageVisits: () => action(INCREASE_FRONTPAGE_VISITS_REQUEST)
};

export const watcher = function*() {
    yield takeEvery(AUTH_REQUEST, sagas.request);
    yield takeEvery(AUTH_SUCCESS, sagas.success);
    yield takeEvery(AUTH_INIT, sagas.init);
    yield takeEvery(AUTH_LOGOUT, sagas.logout);
    yield takeEvery(FETCH_USER, sagas.profile);
    yield takeEvery(SET_JOIN_TIPIO_TOUR_VIEWED_REQUEST, sagas.setJoinTipioTourViewedTrue);
    yield takeEvery(SET_JOIN_TIPIO_ON_BOARD_VIEWED_REQUEST, sagas.setJoinTipioOnBoardViewedTrue);
    yield takeEvery(SET_BUY_TIPIO_ON_BOARD_VIEWED_REQUEST, sagas.setBuyTipioOnBoardViewedTrue);
    yield takeEvery(SET_REDIRECT_URL, sagas.setRedirectUrl);
    yield takeEvery(USER_COMPLETED_SUCCESS, sagas.userCompletedSuccess);
    yield takeEvery(INCREASE_FRONTPAGE_VISITS_REQUEST, sagas.increaseFrontpageVisits);
    yield takeEvery(UPDATE_TOKEN, sagas.updateToken);
};

export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.post('/auth/login', payload);
            yield put(actions.success(response.data.data.token, response.data.data.user));
        } catch (error) {
            if (error.response) {
                if (error.response.status === 422 || error.status === 500) {
                    yield put(actions.failure(error.response.data.message));
                } else {
                    yield put(actions.failure(error.toString()));
                }
            } else {
                yield put(actions.failure(error.toString()));
            }
        }
    },
    *success({ token }) {
        try {
            if (token) {
                const decoded = decode(token);
                const now = Date.now() / 1000;
                const { exp } = decoded;

                // Log out if experation time is less than now!
                if (exp < now) {
                    yield put(actions.logout());
                } else {
                    yield put(actions.exp(exp));
                    yield localStorage.setItem('auth.token', token);
                }
                yield put({ type: FETCH_USER });
                yield put({ type: FETCH_SHOPPINGBAG_REQUEST });
                yield put(notificationActions.request());
                yield reloadInterceptors();

                const redirect = localStorage.getItem('auth.redirect_url') || getCurrentPath() || '/';
                yield localStorage.removeItem('auth.redirect_url');
                return yield put(push(redirect));
            }
        } catch (e) {
            console.log('DECODE TOKEN ERROR', e);
        }
    },
    *updateToken({ token }) {
        const decoded = decode(token);
        const now = Date.now() / 1000;

        const { exp, email } = decoded;

        if (exp < now) {
            yield put(actions.logout());
        } else {
            yield localStorage.removeItem('auth.token');
            yield put(actions.exp(exp));
            yield localStorage.setItem('auth.token', token);
        }
        yield put(actions.updateTokenSuccess({ email }));
    },
    *init() {
        const token = yield localStorage.getItem('auth.token');
        if (token) {
            yield put(actions.success(token));
        }
    },
    *logout({ redirectToLogin }) {
        yield localStorage.removeItem('auth.token');
        yield localStorage.removeItem('auth.redirect_url');
        yield put({ type: CLEAR_CART });
        yield put(notificationActions.reset());
        if (redirectToLogin) {
            yield put(replace('/auth/login'));
        } else {
            yield put(replace('/'));
        }
        yield put({ type: AUTH_LOGOUT_SUCCESS });
    },
    *profile(payload) {
        try {
            const response = yield axios.get('users/profile', payload);
            yield put({ type: FETCH_USER_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: FETCH_USER_ERROR, error: error.toString() });
        }
    },
    *setJoinTipioTourViewedTrue() {
        try {
            const response = yield axios.patch('users', { join_tipio_tour_viewed: true });
            yield put({ type: SET_JOIN_TIPIO_TOUR_VIEWED_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: SET_JOIN_TIPIO_TOUR_VIEWED_FAILURE, error: error.toString() });
        }
    },
    *setJoinTipioOnBoardViewedTrue() {
        try {
            const response = yield axios.patch('users', { join_tipio_on_board_viewed: true });
            yield put({ type: SET_JOIN_TIPIO_ON_BOARD_VIEWED_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: SET_JOIN_TIPIO_ON_BOARD_VIEWED_FAILURE, error: error.toString() });
        }
    },
    *setBuyTipioOnBoardViewedTrue() {
        try {
            const response = yield axios.patch('users', { buy_tipio_on_board_viewed: true });
            yield put({ type: SET_BUY_TIPIO_ON_BOARD_VIEWED_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: SET_BUY_TIPIO_ON_BOARD_VIEWED_FAILURE, error: error.toString() });
        }
    },
    *setRedirectUrl({ redirectUrl }) {
        yield localStorage.setItem('auth.redirect_url', redirectUrl);
    },
    *userCompletedSuccess({ data }) {
        // if (data && !data.verified) {
        //     return yield put(push('/user/not-verified'));
        // }
        //yield put(actions.logout(true));
    },
    *increaseFrontpageVisits() {
        try {
            const response = yield axios.post('users/increase-visits', {});
            yield put({ type: INCREASE_FRONTPAGE_VISITS_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: INCREASE_FRONTPAGE_VISITS_FAILURE, error: error.toString() });
        }
    }
};

/**
 * Util methods
 */
function getCurrentPath() {
    const path = window.location.pathname + window.location.search + window.location.hash;
    if (path === '/auth/login') return '/';
    return path;
}
