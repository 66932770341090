import React, { FC } from 'react';
import { actions as uiActions } from 'sagas/app/ui';
import { actions as completePasswordActions } from 'sagas/app/user/completePassword';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import useL10N from 'hooks/useL10N';
// @ts-ignore
import useForm, { SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
// @ts-ignore
import { SecondaryButton, Modal, ModalType, Input } from 'tipio-app-core';

const UserNotCompleted: FC = () => {
    /**
     * Selectors
     */
    const {
        ui: { notCompletedUser },
        completeUser: { loading, error }
    } = useSelector((state: RootStateOrAny) => state.app);
    /**
     * Hooks
     */
    const { t } = useL10N();
    const dispatch = useDispatch();
    let shape = {
        password: yup
            .string()
            .min(8)
            .required(t('private.newPassword.required'))
            .label(t('private.updatePassword.newPassword'))
    };
    const validation = yup.object().shape(shape);
    /**
     * Hooks
     */
    const { register, handleSubmit, errors } = useForm({
        validationSchema: validation
    });

    /**
     * Event hadndlers
     */

    const _onSubmit: SubmitHandler = (values: { password: string }) => {
        dispatch(completePasswordActions.resendUserPassword(values));
    };
    const onCloseButton = () => {
        dispatch(uiActions.toogleNotCompletedUser(false));
    };

    return (
        <>
            {notCompletedUser && (
                <Modal showCloseButton type={ModalType.LARGE} onCloseButtonClick={onCloseButton}>
                    <div className="userNotVerifiedModal">
                        <p className="userNotVerifiedModal__title">{t('private.userNotCompleted.title')}</p>
                        <p className="userNotVerifiedModal__description">
                            {t('private.userNotCompleted.description')}{' '}
                        </p>
                        <form className="userAddress__form" onSubmit={handleSubmit(_onSubmit)}>
                            <div className="mt-4">
                                <Input
                                    type={'password'}
                                    placeholder={t('private.updatePassword.newPassword')}
                                    name="password"
                                    ref={register}
                                    errors={errors}
                                    displayErrors
                                />
                            </div>
                            {error && (
                                <div>
                                    <p>{error}</p>
                                </div>
                            )}
                            <div className="mt-4">
                                <SecondaryButton
                                    text={t('private.userNotAuthorized.submit')}
                                    type="submit"
                                    loading={loading}
                                />
                            </div>
                        </form>
                    </div>
                </Modal>
            )}
        </>
    );
};
export default UserNotCompleted;
