import React, { FC } from 'react';
// @ts-ignore
import Helmet from 'react-helmet';
import { FB_APP_ID } from '../config/fb';

export interface IHeadTitle {
    title: string;
    description?: string;
    keywords?: string[];
    image?: string;
    share_title?: string;
}
const HeadTitle: FC<IHeadTitle> = ({ title, description = '', keywords = [], image = '', share_title = '' }) => {
    const _title = title ? title : 'Hvorfor kjøpe dyrt alene når vi kan handle billig sammen';
    const _desc = description ? description : 'Markedsplassen som gir deg unike tilbud gjennom gruppekjø';

    /**
     * Render functions
     */
    const renderFBImage = () => (image ? <meta property="og:image" content={`${image}`} /> : '');

    return (
        <Helmet>
            <title>{`${title} | Tipio AS`}</title>
            <meta name="title" content={`${_title}`} />
            <meta name="keywords" content={`${keywords.join(' ')}`} />
            <meta name="description" content={`${_desc}`} />

            <meta property="og:title" content={share_title ? `${share_title}` : `${_title}`} />
            <meta property="og:site_name" content={`Tipio AS`} />
            <meta property="og:description" content={`${_desc}`} />
            <meta property="og:url" content={window.location.href} />
            <meta property="fb:app_id" content={`${FB_APP_ID}`} />
            {renderFBImage()}
        </Helmet>
    );
};

export default HeadTitle;
