import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';
import { actions as editTipioActions } from './editTipio';
export const FETCH_TIPIO = 'tipioRestore/FETCH_TIPIO';
export const FETCH_TIPIO_SUCCESS = 'tipioRestore/FETCH_TIPIO_SUCCESS';
export const FETCH_TIPIO_ERROR = 'tipioRestore/FETCH_TIPIO_ERROR';

export const RESTORE_TIPIO = 'tipioRestore/RESTORE_TIPIO';
export const RESTORE_TIPIO_SUCCESS = 'tipioRestore/RESTORE_TIPIO_SUCCESS';
export const RESTORE_TIPIO_ERROR = 'tipioRestore/RESTORE_TIPIO_ERROR';

export const EDIT_TIPIO = 'tipioRestore/EDIT_TIPIO';
export const EDIT_TIPIO_SUCCESS = 'tipioRestore/EDIT_TIPIO_SUCCESS';
export const EDIT_TIPIO_ERROR = 'tipioRestore/EDIT_TIPIO_ERROR';
export const SET_FORM_STATUS = '@app/tipio/edit/SET_FORM_STATUS';
const initialState = {
    loading: true,
    error: null,
    item: {},
    restoreTipioLoading: false,
    restored: false,
    edited: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_TIPIO:
            return initialState;
        case FETCH_TIPIO_SUCCESS:
            let restored = false;
            if (new Date(action.data.expires_in).getTime() > new Date().getTime()) {
                restored = false;
            }
            return { ...state, item: action.data, loading: false, restored };
        case FETCH_TIPIO_ERROR:
            return { ...state, error: action.error.toString(), loading: false };
        case RESTORE_TIPIO:
            return { ...state, restoreTipioLoading: true };
        case RESTORE_TIPIO_SUCCESS:
            return { ...state, restoreTipioLoading: false, restored: true, item: action.data };
        case RESTORE_TIPIO_ERROR:
            return { ...state, error: action.error.toString(), restoreTipioLoading: false };
        case EDIT_TIPIO_SUCCESS:
            state.item.title = action.data.title;
            state.item.market_price = action.data.market_price;
            state.item.brand = action.data.brand;
            state.item.description = action.data.description;
            state.item.category_id = action.data.category_id;
            state.item.images = action.data.files;
            state.item.temporaryImages = action.data.files.map((x) => ({ blob_url: x.blob_url || x.preview }));
            return { ...state, edited: true };
        case SET_FORM_STATUS:
            return { ...state, formStatus: action.payload };
        default:
            return state;
    }
}
export const actions = {
    fetchTipio: (tipioId) => ({ type: FETCH_TIPIO, id: tipioId }),
    restoreTipio: (tipioId) => ({ type: RESTORE_TIPIO, id: tipioId }),
    editTipio: (data) => ({ type: EDIT_TIPIO, data })
};

export const watcher = function*() {
    yield takeEvery(FETCH_TIPIO, sagas.fetchTipio);
    yield takeEvery(RESTORE_TIPIO, sagas.restoreTipio);
    yield takeEvery(EDIT_TIPIO, sagas.editTipio);
};

export const sagas = {
    fetchTipio: function* fetchTipio(payload) {
        try {
            const response = yield axios.get('/tipio-restore/' + payload.id);
            yield put({ type: FETCH_TIPIO_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: FETCH_TIPIO_ERROR, error });
        }
    },
    restoreTipio: function* restoreTipio(payload) {
        try {
            const response = yield axios.patch('/tipio-restore/vote', { id: payload.id.id });
            if (response) {
                yield put(editTipioActions.request(payload.id));
            }
        } catch (error) {
            yield put({ type: RESTORE_TIPIO_ERROR, error });
        }
    },
    editTipio: function* editTipio(payload) {
        try {
            yield put({ type: EDIT_TIPIO_SUCCESS, data: payload.data });
        } catch (error) {
            yield put({ type: EDIT_TIPIO_ERROR, error });
        }
    }
};
