export default ({ errors }) => {
    let errorBuilder = '';
    Object.keys(errors).forEach((key) => {
        const arr = errors[key];
        arr.forEach((item) => {
            errorBuilder += `${item}\n`;
        });
        errorBuilder += '\n';
    });
    return errorBuilder;
};
