import axios from 'axios';
import { API_BASE } from 'config/app';
// import actions
import { actions as uiActions } from 'sagas/app/ui';
import { actions as authActions } from 'sagas/app/auth/auth';

import subscribeToTokenChangeWithDispatch from './events/subscribeToTokenChangeWithDispatch';

// configure axios defaults
axios.defaults.baseURL = API_BASE;
axios.defaults.config = {
    headers: { 'Content-Type': 'multipart/form-data' }
};

let store = null;

export const setupAxios = (_store) => {
    store = _store;
    // reqeust intercpetor
    axios.interceptors.request.use((config) => {
        // config token as default per request
        const _token = window.localStorage.getItem('auth.token');
        if (_token) {
            config.headers.Authorization = `Bearer ${_token}`;
        }
        return config;
    });

    const token = window.localStorage.getItem('auth.token');
    if (token && token !== '' && token !== 'null') {
        store.dispatch(authActions.init());
    }

    // Add a response interceptor
    axios.interceptors.response.use(
        (response) => {
            // if error: "No permission!"
            // logout & go to login page
            if (response && response.data && response.data.error === 'No permission!') {
                store.dispatch(authActions.logout());
            }

            return response;
        },
        (error) => {
            const _error = { ...error };
            if (
                _error.response &&
                _error.response.status === 403 &&
                _error.response?.data?.data === 'user.notVerified'
            ) {
                store.dispatch(uiActions.toogleSendVerificationModalUser(true));
            } else if (_error.response && _error.response.status === 401) {
                store.dispatch(uiActions.toogleNotCompletedUser(true));
            }
            return Promise.reject(_error);
        }
    );
};

export const reloadInterceptors = () => {
    const token = window.localStorage.getItem('auth.token');
    axios.interceptors.request.use((config) => {
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });
    subscribeToTokenChangeWithDispatch(token, { authActions, dispatch: store.dispatch });
};

export default axios;
