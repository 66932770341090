import { put, takeEvery } from 'redux-saga/effects';
import action from 'utils/action-creator';
import axios from 'utils/axios';
import { FETCH_SHOPPINGBAG_REQUEST } from '../shoppingBag/shoppingBag';

export const COMPLETE_ICE_CHECKOUT_REQUEST = 'cart/COMPLETE_ICE_CHECKOUT_REQUEST';
export const COMPLETE_ICE_CHECKOUT_SUCCES = 'cart/COMPLETE_ICE_CHECKOUT_SUCCES';
export const COMPLETE_ICE_CHECKOUT_FAILURE = 'cart/COMPLETE_ICE_CHECKOUT_FAILURE';
export const RESET_IS_COMPLETED = 'cart/RESET_IS_COMPLETED';

const _state = {
    loading: false,
    success: false,
    error: null
};

export default function(state = _state, action) {
    switch (action.type) {
        case COMPLETE_ICE_CHECKOUT_REQUEST:
            return { ...state, loading: true };
        case COMPLETE_ICE_CHECKOUT_SUCCES:
            return { ...state, loading: false, success: true };
        case COMPLETE_ICE_CHECKOUT_FAILURE:
            return { ...state, loading: false, error: action.error };
        case RESET_IS_COMPLETED:
            return { ...state, loading: false, success: false };
        default:
            return state;
    }
}

export const actions = {
    completeIceCheckout: (data) => action(COMPLETE_ICE_CHECKOUT_REQUEST, { data }),
    resetIsCompleted: () => action(RESET_IS_COMPLETED)
};

export const sagas = {
    *completeIceCheckout({ data }) {
        try {
            yield axios.post('cart/complete/ice', data);
            yield put({ type: COMPLETE_ICE_CHECKOUT_SUCCES });
            yield put({
                type: FETCH_SHOPPINGBAG_REQUEST
            });
        } catch (error) {
            yield put({ type: COMPLETE_ICE_CHECKOUT_FAILURE, error: error.response.data.message });
        }
    }
};

export const watcher = function*() {
    yield takeEvery(COMPLETE_ICE_CHECKOUT_REQUEST, sagas.completeIceCheckout);
};
