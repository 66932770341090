import { HOTJAR_ID, HOTJAR_SV } from '../config/hotjar';
import { isProd } from '../utils/env';

const hotjar = () => {
    (function(h, o, t, j) {
        h.hj =
            h.hj ||
            function() {
                (h.hj.q = h.hj.q || []).push(arguments);
            };
        h._hjSettings = { hjid: HOTJAR_ID, hjsv: HOTJAR_SV };
        const a = o.getElementsByTagName('head')[0];
        const r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
    })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
};

if (isProd()) {
    hotjar();
}
