import ReactGA from 'react-ga';
import { GA_ID } from '../config/ga';
import { isProd } from '../utils/env';
import history from '../utils/history';

const ga = () => {
    ReactGA.initialize(GA_ID);

    history.listen((location) => {
        ReactGA.pageview(location.pathname + location.search);
    });
};

if (isProd()) {
    ga();
}
