import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const AuthRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated } = useSelector((state) => state.app.auth);

    if (isAuthenticated) return <Redirect to="/" />;

    return <Route {...rest} component={Component} />;
};

export default AuthRoute;
