import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';
import produce from 'immer';

export const FETCH_SETTINGS = '@app/settings/FETCH_SETTINGS';
export const FETCH_SETTINGS_SUCCESS = '@app/settings/FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_ERROR = '@app/settings/FETCH_SETTINGS_ERROR';

export const POST_SETTINGS = '@app/settings/POST_SETTINGS';
export const POST_SETTINGS_SUCCESS = '@app/settings/POST_SETTINGS_SUCCESS';
export const POST_SETTINGS_ERROR = '@app/settings/POST_SETTINGS_ERROR';
export const POST_FORM_RESET = '@app/settings/POST_FORM_RESET';

const _state = {
    isLoading: true,
    data: null,
    firstRequest: true,
    formState: {
        loading: false,
        success: false
    }
};

export default (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FETCH_SETTINGS:
                return _state;
            case FETCH_SETTINGS_SUCCESS:
                draft.data = action.data;
                draft.isLoading = false;
                break;
            case FETCH_SETTINGS_ERROR:
                draft.error = action.error.toString();
                draft.isLoading = false;
                break;
            case POST_SETTINGS:
                draft.formState.loading = true;
                draft.firstRequest = false;
                break;
            case POST_SETTINGS_SUCCESS:
                draft.data = action.data;
                draft.formState = { loading: false, success: true };
                break;
            case POST_SETTINGS_ERROR:
                draft.error = action.error.toString();
                draft.formState = { loading: false, success: false };
                break;
            case POST_FORM_RESET:
                draft.formState = { success: false };
                break;
            default:
                break;
        }
    });

export const actions = {
    request: () => ({ type: FETCH_SETTINGS }),
    post: (payload) => ({ type: POST_SETTINGS, payload }),
    reset: () => ({ type: POST_FORM_RESET })
};

export const sagas = {
    fetchSettings: function* fetchSettings() {
        try {
            const response = yield axios.get('/settings');
            yield put({ type: FETCH_SETTINGS_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: FETCH_SETTINGS_ERROR, error });
        }
    },
    post: function* post({ payload }) {
        try {
            const response = yield axios.post('/settings', payload);
            yield put({ type: POST_SETTINGS_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: POST_SETTINGS_ERROR, error });
        }
    }
};

export const watcher = function* fetch() {
    yield takeEvery(FETCH_SETTINGS, sagas.fetchSettings);
    yield takeEvery(POST_SETTINGS, sagas.post);
};
