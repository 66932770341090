import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import action from 'utils/action-creator';
import axios from 'utils/axios';

import produce from 'immer';
import { TOGGLE_SHOPPING_BAG } from '../ui';
import { FETCH_RELATED_OFFERS_REQUEST } from '../tipio/tipioOffer';
import { FETCH_PROFILE } from 'sagas/app/profile/profile';
import { REMOVE_SHOPPINGBAG_ITEMS, ADD_SHOPPINGBAG_ITEM, FETCH_SHOPPINGBAG_REQUEST } from '../shoppingBag/shoppingBag';
import { actions as cartActions } from './cartAdditionalSales';

export const ADD_TO_CHECKOUT_REQUEST = 'checkout/ADD_TO_CHECKOUT_REQUEST';
export const ADD_TO_CHECKOUT_SUCCESS = 'checkout/ADD_TO_CHECKOUT_SUCCESS';
export const ADD_TO_CHECKOUT_FAILURE = 'checkout/ADD_TO_CHECKOUT_FAILURE';

export const SET_STATUS_FALSE = 'checkout/SET_STATUS_FALSE';

export const FETCH_CHECKOUT_REQUEST = 'checkout/FETCH_CHECKOUT_REQUEST';
export const FETCH_CHECKOUT_SUCCESS = 'checkout/FETCH_CHECKOUT_SUCCESS';
export const FETCH_CHECKOUT_FAILURE = 'checkout/FETCH_CHECKOUT_FAILURE';

export const REMOVE_FROM_CHECKOUT_REQUEST = 'checkout/REMOVE_FROM_CHECKOUT_REQUEST';
export const REMOVE_FROM_CHECKOUT_SUCCESS = 'checkout/REMOVE_FROM_CHECKOUT_SUCCESS';
export const REMOVE_FROM_CHECKOUT_FAILURE = 'checkout/REMOVE_FROM_CHECKOUT_FAILURE';

export const COMPLETE_CHECKOUT_REQUEST = 'checkout/COMPLETE_CHECKOUT_REQUEST';
export const COMPLETE_CHECKOUT_SUCCES = 'checkout/COMPLETE_CHECKOUT_SUCCES';
export const COMPLETE_CHECKOUT_FAILURE = 'checkout/COMPLETE_CHECKOUT_FAILURE';

export const UPDATE_CHECKOUT_QUANTITY_REQUEST = 'checkout/UPDATE_CHECKOUT_QUANTITY_REQUEST';
export const UPDATE_CHECKOUT_QUANTITY_SUCCESS = 'checkout/UPDATE_CHECKOUT_QUANTITY_SUCCESS';
export const UPDATE_CHECKOUT_QUANTITY_FAILURE = 'checkout/UPDATE_CHECKOUT_QUANTITY_FAILURE';

export const UPDATE_CHECKOUT_OPTION_REQUEST = 'checkout/UPDATE_CHECKOUT_OPTION_REQUEST';
export const UPDATE_CHECKOUT_OPTION_SUCCES = 'checkout/UPDATE_CHECKOUT_OPTION_SUCCES';
export const UPDATE_CHECKOUT_OPTION_FAILURE = 'checkout/UPDATE_CHECKOUT_OPTION_FAILURE';

export const SET_ACTIVE_CART_REQUEST = 'checkout/SET_ACTIVE_CART_REQUEST_REQUEST';
export const SET_ACTIVE_CART_SUCCES = 'checkout/SET_ACTIVE_CART_REQUEST_SUCCES';
export const SET_ACTIVE_CART_FAILURE = 'checkout/SET_ACTIVE_CART_REQUEST_FAILURE';

export const RESET_IS_COMPLETED = 'checkout/RESET_IS_COMPLETED';
export const SET_COMPANY_ID = 'checkout/SET_COMPANY_ID';
export const CLEAR_CART = 'checkout/CLEAR_CART';

const _state = {
    data: [],
    meta: {},
    loading: false,
    status: false,
    isCompleted: false,
    activeCard: false,
    company_id: null,
    deleted_items: {}
};

export default function(state = _state, action) {
    switch (action.type) {
        case ADD_TO_CHECKOUT_REQUEST:
            return { ...state, loading: true, status: false };
        case ADD_TO_CHECKOUT_SUCCESS: {
            const nextState = produce(state, (draftState) => {
                const data = state.data[action.data.item.company_id];
                data.push(action.data.item);
                draftState.data[action.data.item.company_id] = data;
                draftState.meta.prices[action.data.item.company_id].total_price = parseInt(
                    action.data.meta.total_price
                );
                draftState.loading = false;
                draftState.status = true;
                draftState.error = null;
            });
            return nextState;
        }
        case ADD_TO_CHECKOUT_FAILURE:
            return { ...state, loading: false, errorAdding: action.error, status: false };
        case SET_STATUS_FALSE:
            return { ...state, status: false };
        case FETCH_CHECKOUT_REQUEST:
            return { ...state, loading: true };
        case FETCH_CHECKOUT_SUCCESS:
            let datas = {};
            Object.keys(action.data.data).forEach((d) => {
                if (action.data.deleted_items && action.data.deleted_items[d]) {
                    datas[d] = [...action.data.data[d], ...action.data.deleted_items[d]];
                    delete action.data.deleted_items[d];
                } else {
                    datas[d] = [...action.data.data[d]];
                }
            });
            Object.keys(action.data.deleted_items).forEach((d) => {
                if (action.data.deleted_items[d]) {
                    datas[d] = [...action.data.deleted_items[d]];
                }
            });

            return {
                ...state,
                loading: false,
                data: datas,
                meta: action.data.meta
            };
        case FETCH_CHECKOUT_FAILURE:
            return { ...state, loading: false, error: action.error };
        case REMOVE_FROM_CHECKOUT_REQUEST:
            return { ...state };
        case REMOVE_FROM_CHECKOUT_SUCCESS: {
            let returnData;
            const companyId = action.data.companyId;
            const data = state.data[companyId].filter((x) => x.id !== action.data.item.id);

            if (data.length) {
                returnData = { ...state.data, [companyId]: data };
            } else {
                returnData = { ...state.data };
                delete returnData[companyId];
            }
            return {
                ...state,
                data: returnData,
                meta: {
                    ...state.meta,
                    companyCount: Object.keys(returnData).length,
                    prices: {
                        ...state.meta.prices,
                        [companyId]: { total_price: parseInt(action.data.meta.total_price) }
                    }
                }
            };
        }
        case REMOVE_FROM_CHECKOUT_FAILURE:
            return { ...state, error: action.error };
        case COMPLETE_CHECKOUT_REQUEST:
            return { ...state };
        case COMPLETE_CHECKOUT_SUCCES:
            return { ..._state, isCompleted: true };
        case COMPLETE_CHECKOUT_FAILURE:
            return { ...state, error: action.error };
        case UPDATE_CHECKOUT_QUANTITY_REQUEST:
            return { ...state };
        case UPDATE_CHECKOUT_QUANTITY_SUCCESS: {
            const { companyId, item, meta } = action.data;

            const nextState = produce(state, (draftState) => {
                const { data } = state;

                const itemIndex = data[companyId].findIndex((x) => x.id === item.id);
                data[companyId][itemIndex].quantity = item.quantity;

                draftState.data = data;
                draftState.meta.prices[companyId].total_price = parseInt(meta.total_price);
            });
            return nextState;
        }
        case UPDATE_CHECKOUT_QUANTITY_FAILURE:
            return { ...state, loading: false, error: action.error };
        case UPDATE_CHECKOUT_OPTION_REQUEST:
            return { ...state };
        case UPDATE_CHECKOUT_OPTION_SUCCES: {
            const { companyId, item } = action.data;
            const nextState = produce(state, (draftState) => {
                const { data } = state;
                const itemIndex = data[companyId].findIndex((item) => item.id === action.data.item.id);
                data[companyId][itemIndex].tipio_offer_option_id = item.tipio_offer_option_id;
                data[companyId][itemIndex].tipio_offer_option_type = item.tipio_offer_option_type;
                data[companyId][itemIndex].tipio_offer_option_title = item.tipio_offer_option_title;
                data[companyId][itemIndex].quantity = item.quantity;
                data[companyId][itemIndex].tipio_offer.inventory_available = item.tipio_offer.inventory_available;

                // let t = data.filter((x) => {
                //     return x.id !== action.data.id && x.tipio_offer_option_id !== action.data.tipio_offer_option_id;
                // });
                const index = data[companyId].findIndex((x) => {
                    return x.tipio_offer_option_id === action.data.tipio_offer_option_id && x.id !== action.data.id;
                });
                if (index !== -1) {
                    data.splice(index, 1);
                }

                draftState.data = data;
            });
            return nextState;
        }
        case UPDATE_CHECKOUT_OPTION_FAILURE:
            return { ...state, error: action.error };
        case RESET_IS_COMPLETED:
            return { ...state, loading: false, isCompleted: false };
        case SET_ACTIVE_CART_REQUEST:
            return { ...state, loading: true };
        case SET_ACTIVE_CART_SUCCES:
            return { ...state, activeCard: action.data.companyId, loading: false };
        case SET_ACTIVE_CART_FAILURE:
            return { ...state, error: action.error, loading: false };
        case SET_COMPANY_ID:
            return { ...state, company_id: action.payload };
        case CLEAR_CART:
            return _state;
        default:
            return state;
    }
}

export const actions = {
    request: (id, companyId, hasOptions, showShoppingBagModal, fetchRelatedProducts, isAdditionalSale, saleOfferId) =>
        action(ADD_TO_CHECKOUT_REQUEST, {
            id,
            companyId,
            hasOptions,
            showShoppingBagModal,
            fetchRelatedProducts,
            isAdditionalSale,
            saleOfferId
        }),
    success: (data) => action(ADD_TO_CHECKOUT_SUCCESS, { data }),
    failure: (error) => action(ADD_TO_CHECKOUT_FAILURE, { error }),
    setStatusFalse: (error) => action(SET_STATUS_FALSE),
    fetchCheckout: () => action(FETCH_CHECKOUT_REQUEST),
    deleteProduct: (productId, companyId) => action(REMOVE_FROM_CHECKOUT_REQUEST, { productId, companyId }),
    setActiveCard: (companyId) => action(SET_ACTIVE_CART_REQUEST, { companyId }),
    completeCart: (paymentInfoData) => action(COMPLETE_CHECKOUT_REQUEST, { paymentInfoData }),
    updateQuantity: (itemId, quantity, companyId) =>
        action(UPDATE_CHECKOUT_QUANTITY_REQUEST, { itemId, quantity, companyId }),
    updateOption: (itemId, optionId, companyId, cartId) =>
        action(UPDATE_CHECKOUT_OPTION_REQUEST, { itemId, optionId, companyId, cartId }),
    resetIsCompleted: () => action(RESET_IS_COMPLETED),
    resetData: () => action(CLEAR_CART)
};

export const watcher = function*() {
    yield takeEvery(ADD_TO_CHECKOUT_REQUEST, sagas.request);
    yield takeEvery(FETCH_CHECKOUT_REQUEST, sagas.requestFetchCart);
    yield takeEvery(REMOVE_FROM_CHECKOUT_REQUEST, sagas.removeProduct);
    yield takeEvery(COMPLETE_CHECKOUT_REQUEST, sagas.completeCart);
    yield takeLatest(UPDATE_CHECKOUT_QUANTITY_REQUEST, sagas.updateQuantity);
    yield takeEvery(UPDATE_CHECKOUT_OPTION_REQUEST, sagas.updateOption);
    yield takeEvery(SET_ACTIVE_CART_REQUEST, sagas.setActiveCard);
};

export const sagas = {
    *request({ id, companyId, hasOptions, showShoppingBagModal, fetchRelatedProducts, isAdditionalSale, saleOfferId }) {
        try {
            let payload = {};
            if (isAdditionalSale) {
                payload = { additional_sale_id: id, sale_offer_id: saleOfferId, company_id: companyId };
            } else {
                payload = { tipio_offer_id: id, has_options: hasOptions, company_id: companyId };
            }
            const response = yield axios.post('cart', payload);
            yield put({ type: ADD_TO_CHECKOUT_SUCCESS, data: response.data.data });
            if (showShoppingBagModal) {
                yield put({ type: TOGGLE_SHOPPING_BAG, payload: true });
            }
            if (fetchRelatedProducts) {
                yield put({ type: FETCH_RELATED_OFFERS_REQUEST, tipioOfferId: id });
            }
            yield put({
                type: ADD_SHOPPINGBAG_ITEM,
                data: { item: response.data.data.item }
            });
            yield put(cartActions.request());
        } catch (error) {
            yield put({ type: ADD_TO_CHECKOUT_FAILURE, error: error.response.data.message });
        }
    },
    requestFetchCart: function*() {
        try {
            yield put({ type: FETCH_PROFILE });
            const response = yield axios.get('/cart/grouped');
            yield put({ type: FETCH_CHECKOUT_SUCCESS, data: response.data.data });
            yield put({ type: FETCH_RELATED_OFFERS_REQUEST, tipioOfferId: 2 });
        } catch (error) {
            yield put({ type: FETCH_CHECKOUT_FAILURE, error: 'Noe gikk galt, prøv igjen senere!' });
        }
    },
    removeProduct: function*({ productId, companyId }) {
        try {
            const response = yield axios.delete('cart/' + productId);
            yield put({
                type: REMOVE_FROM_CHECKOUT_SUCCESS,
                data: { item: response.data.data.item, companyId, meta: response.data.data.meta }
            });
            yield put({
                type: REMOVE_SHOPPINGBAG_ITEMS,
                data: { item: response.data.data.item }
            });
        } catch (error) {
            yield put({
                type: SET_COMPANY_ID,
                payload: companyId
            });
            yield put({ type: REMOVE_FROM_CHECKOUT_FAILURE, error: 'Noe gikk galt, prøv igjen senere!' });
        }
    },
    completeCart: function*({ paymentInfoData }) {
        try {
            yield axios.post('cart/complete', paymentInfoData);
            yield put({ type: COMPLETE_CHECKOUT_SUCCES });
        } catch (error) {
            yield put({ type: COMPLETE_CHECKOUT_FAILURE, error: error.response.data.message });
        }
    },
    updateQuantity: function*({ itemId, quantity, companyId }) {
        try {
            if (quantity) {
                const response = yield axios.post('cart/update-quantity/' + itemId, { quantity });
                yield put({
                    type: UPDATE_CHECKOUT_QUANTITY_SUCCESS,
                    data: { item: response.data.data.item, meta: response.data.data.meta, companyId }
                });
                yield put({
                    type: SET_COMPANY_ID,
                    payload: companyId
                });
            }
        } catch (error) {
            yield put({
                type: SET_COMPANY_ID,
                payload: companyId
            });
            yield put({
                type: UPDATE_CHECKOUT_QUANTITY_FAILURE,
                error: 'Noe gikk galt, prøv igjen senere!'
            });
        }
    },
    updateOption: function*({ itemId, optionId, companyId, cartId }) {
        try {
            const response = yield axios.post('cart/update-option/' + cartId, {
                tipio_offer_option_id: itemId
            });
            yield put({ type: UPDATE_CHECKOUT_OPTION_SUCCES, data: { item: response.data.data, companyId } });
        } catch (error) {
            yield put({
                type: SET_COMPANY_ID,
                payload: companyId
            });
            yield put({ type: UPDATE_CHECKOUT_OPTION_FAILURE, error: 'Noe gikk galt, prøv igjen senere!' });
        }
    },
    setActiveCard: function*({ companyId }) {
        try {
            yield axios.post('/cart/active-cart', { company_id: companyId });
            yield put({ type: SET_ACTIVE_CART_SUCCES, data: { companyId } });
            yield put({
                type: FETCH_SHOPPINGBAG_REQUEST
            });
        } catch (error) {
            yield put({ type: SET_ACTIVE_CART_FAILURE, error: 'Noe gikk galt, prøv igjen senere!' });
        }
    }
};
