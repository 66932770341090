import { put, takeEvery } from 'redux-saga/effects';
import produce from 'immer';
import action from 'utils/action-creator';
import axios from 'utils/axios';
import { TOGGLE_SHOPPING_BAG } from '../ui';
import { FETCH_RELATED_OFFERS_REQUEST } from '../tipio/tipioOffer';
import { FETCH_PROFILE } from 'sagas/app/profile/profile';
import { REMOVE_SHOPPINGBAG_ITEMS, ADD_SHOPPINGBAG_ITEM } from '../shoppingBag/shoppingBag';
import { actions as cartActions } from './cartAdditionalSales';

export const ADD_TO_CART_REQUEST = 'cart/ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'cart/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'cart/ADD_TO_CART_FAILURE';
export const SET_STATUS_FALSE = 'cart/SET_STATUS_FALSE';
export const FETCH_CART_REQUEST = 'cart/FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'cart/FETCH_CART_SUCCESS';
export const FETCH_CART_FAILURE = 'cart/FETCH_CART_FAILURE';

export const REMOVE_FROM_CART_REQUEST = 'cart/REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS = 'cart/REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_FAILURE = 'cart/REMOVE_FROM_CART_FAILURE';

export const COMPLETE_CART_REQUEST = 'cart/COMPLETE_CART_REQUEST';
export const COMPLETE_CART_SUCCES = 'cart/COMPLETE_CART_SUCCES';
export const COMPLETE_CART_FAILURE = 'cart/COMPLETE_CART_FAILURE';

export const UPDATE_QUANTITY_REQUEST = 'cart/UPDATE_QUANTITY_REQUEST';
export const UPDATE_QUANTITY_SUCCESS = 'cart/UPDATE_QUANTITY_SUCCESS';
export const UPDATE_QUANTITY_FAILURE = 'cart/UPDATE_QUANTITY_FAILURE';

export const UPDATE_OPTION_REQUEST = 'cart/UPDATE_OPTION_REQUEST';
export const UPDATE_OPTION_SUCCES = 'cart/UPDATE_OPTION_SUCCES';
export const UPDATE_OPTION_FAILURE = 'cart/UPDATE_OPTION_FAILURE';

export const SET_CART_TO_PROCESSING_REQUEST = 'cart/SET_IS_PROCESSING_REQUEST';
export const SET_CART_TO_PROCESSING_SUCCESS = 'cart/SET_IS_PROCESSING_SUCCESS';
export const SET_CART_TO_PROCESSING_FAILURE = 'cart/SET_IS_PROCESSING_FAILURE';

export const RESET_IS_COMPLETED = 'cart/RESET_IS_COMPLETED';
export const COUPON_APPLIED = 'cart/COUPON_APPLIED';
export const CLEAR_CART = 'cart/CLEAR_CART';

const _state = {
    items: {},
    loading: false,
    status: false,
    isCompleted: false,
    updateCart: false,
    error: null,
    deleted_items: [],
    complete_error: null,
    couponApplied: false,
    coupons: []
};

export default function(state = _state, action) {
    switch (action.type) {
        case ADD_TO_CART_REQUEST:
            return { ...state, loading: true, status: false };
        case ADD_TO_CART_SUCCESS: {
            const nextState = produce(state, (draftState) => {
                const { items } = state;
                items.push(action.data.item);

                draftState.items = items;
                draftState.meta.total_price = parseInt(action.data.meta.total_price);
                draftState.loading = false;
                draftState.status = true;
                draftState.error = null;
            });
            return nextState;
        }
        case ADD_TO_CART_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                status: false
            };
        case SET_STATUS_FALSE:
            return { ...state, status: false };
        case FETCH_CART_REQUEST:
            return { ...state, loading: true };
        case FETCH_CART_SUCCESS:
            let datas = action.data.items;
            if (action.data.deleted_items.length) {
                action.data.deleted_items.forEach((x) => {
                    datas = [...datas, x];
                });
            }
            return {
                ...state,
                loading: false,
                items: datas,
                meta: action.data.meta
            };
        case FETCH_CART_FAILURE:
            return { ...state, loading: false, error: action.error };
        case REMOVE_FROM_CART_REQUEST:
            return { ...state, loading: true };
        case REMOVE_FROM_CART_SUCCESS: {
            let coupons = {};
            const items = state.items.filter((x) => x.id !== action.data.item.id);
            coupons = Object.keys(state.coupons).filter((x) => {
                if (state.coupons[x].cart_id !== action.data.item.id) {
                    return state.coupons[x];
                }
            });
            return {
                ...state,
                loading: false,
                items,
                meta: action.data.meta,
                coupons: { ...coupons }
            };
        }
        case REMOVE_FROM_CART_FAILURE:
            return { ...state, loading: false, error: action.error };
        case COMPLETE_CART_REQUEST:
            return { ...state };
        case COMPLETE_CART_SUCCES:
            return { ..._state, isCompleted: action.data.success };
        case COMPLETE_CART_FAILURE:
            return { ...state, loading: false, complete_error: action.error.context };
        case UPDATE_QUANTITY_REQUEST:
            return { ...state, updateCart: true };
        case UPDATE_QUANTITY_SUCCESS: {
            const nextState = produce(state, (draftState) => {
                const { items } = state;
                const itemIndex = items.findIndex((item) => item.id === action.data.item.id);

                items[itemIndex].quantity = action.data.item.quantity;

                draftState.items = items;
                draftState.meta = action.data.meta;
                draftState.updateCart = false;
            });
            return nextState;
        }
        case UPDATE_QUANTITY_FAILURE:
            return { ...state, updateCart: false, error: action.error };
        case UPDATE_OPTION_REQUEST:
            return { ...state, updateCart: true };
        case UPDATE_OPTION_SUCCES: {
            const nextState = produce(state, (draftState) => {
                const { items } = state;
                const itemIndex = items.findIndex((item) => item.id === action.data.id);
                items[itemIndex].tipio_offer_option_id = action.data.tipio_offer_option_id;
                items[itemIndex].tipio_offer_option_type = action.data.tipio_offer_option_type;
                items[itemIndex].tipio_offer_option_title = action.data.tipio_offer_option_title;
                items[itemIndex].quantity = action.data.quantity;
                items[itemIndex].tipio_offer.inventory_available = action.data.tipio_offer.inventory_available;
                // let t = items.filter((x) => {
                //     return x.id !== action.data.id && x.tipio_offer_option_id !== action.data.tipio_offer_option_id;
                // });
                const index = items.findIndex(
                    (x) => x.tipio_offer_option_id === action.data.tipio_offer_option_id && x.id !== action.data.id
                );
                if (index !== -1) {
                    items.splice(index, 1);
                }

                draftState.items = items;
                draftState.updateCart = false;
            });
            return nextState;
        }
        case UPDATE_OPTION_FAILURE:
            return { ...state, updateCart: false, error: action.error };
        case SET_CART_TO_PROCESSING_REQUEST:
            return { ...state, loading: true };
        case SET_CART_TO_PROCESSING_SUCCESS: {
            const items = state.items.filter((cartItem) => cartItem.klarna_order_id !== action.data);
            return { ...state, loading: false, items };
        }
        case COUPON_APPLIED:
            const { items } = state;
            const itemIndex = items.findIndex((item) => item.id === action.data.item.id);
            items[itemIndex].price = action.data.item.price;
            items[itemIndex].coupon = action.data.item.coupon;
            const coupons = items.filter((item) => item.coupon !== null);
            let saved_coupons = 0;
            coupons.map((coupon) => {
                saved_coupons += coupon.coupon.discount;
            });
            return { ...state, items, meta: { ...action.data.meta, saved_amount: saved_coupons } };
        case SET_CART_TO_PROCESSING_FAILURE:
            return { ...state, loading: false, error: action.error };
        case RESET_IS_COMPLETED:
            return { ...state, loading: false, isCompleted: false };
        case CLEAR_CART:
            return _state;
        default:
            return state;
    }
}

export const actions = {
    request: (id, companyId, hasOptions, showShoppingBagModal, fetchRelatedProducts, isAdditionalSale, saleOfferId) =>
        action(ADD_TO_CART_REQUEST, {
            id,
            companyId,
            hasOptions,
            showShoppingBagModal,
            fetchRelatedProducts,
            isAdditionalSale,
            saleOfferId
        }),
    success: (data) => action(ADD_TO_CART_SUCCESS, { data }),
    failure: (error) => action(ADD_TO_CART_FAILURE, { error }),
    setStatusFalse: (error) => action(SET_STATUS_FALSE),
    fetchCart: () => action(FETCH_CART_REQUEST),
    deleteProduct: (productId) => action(REMOVE_FROM_CART_REQUEST, { productId }),
    completeCart: () => action(COMPLETE_CART_REQUEST),
    updateQuantity: (itemId, quantity) => action(UPDATE_QUANTITY_REQUEST, { itemId, quantity }),
    updateOption: (itemId, cartId) => action(UPDATE_OPTION_REQUEST, { itemId, cartId }),
    resetIsCompleted: () => action(RESET_IS_COMPLETED),
    setCartToProcessing: (data) => action(SET_CART_TO_PROCESSING_REQUEST, data),
    couponApplied: (data) => action(COUPON_APPLIED, data)
};

export const sagas = {
    *request({ id, companyId, hasOptions, showShoppingBagModal, fetchRelatedProducts, isAdditionalSale, saleOfferId }) {
        try {
            let payload = {};
            if (isAdditionalSale) {
                payload = { additional_sale_id: id, sale_offer_id: saleOfferId, company_id: companyId };
            } else {
                payload = { tipio_offer_id: id, has_options: hasOptions, company_id: companyId };
            }
            const response = yield axios.post('cart', payload);
            yield put({ type: ADD_TO_CART_SUCCESS, data: response.data.data });
            if (showShoppingBagModal) {
                yield put({ type: TOGGLE_SHOPPING_BAG, payload: true });
            }
            if (fetchRelatedProducts) {
                yield put({ type: FETCH_RELATED_OFFERS_REQUEST, id });
            }
            yield put({
                type: ADD_SHOPPINGBAG_ITEM,
                data: { item: response.data.data.item }
            });
            yield put(cartActions.request({ active: true }));
        } catch (error) {
            yield put({ type: ADD_TO_CART_FAILURE, error: error.response.data.message });
        }
    },
    *requestFetchCart() {
        try {
            yield put({ type: FETCH_PROFILE });
            const response = yield axios.get('/cart/active-cart');
            yield put({ type: FETCH_CART_SUCCESS, data: response.data.data });
            yield put({ type: FETCH_RELATED_OFFERS_REQUEST, tipioOfferId: 2 });
        } catch (error) {
            yield put({ type: FETCH_CART_FAILURE, error });
        }
    },
    *removeProduct({ productId }) {
        try {
            const response = yield axios.delete('cart/' + productId);
            yield put({ type: REMOVE_FROM_CART_SUCCESS, data: response.data.data });
            yield put({
                type: REMOVE_SHOPPINGBAG_ITEMS,
                data: { item: response.data.data.item }
            });
            yield put(cartActions.request({ active: true }));
        } catch (error) {
            yield put({ type: REMOVE_FROM_CART_FAILURE, error: 'Noe gikk galt, prøv igjen senere!' });
        }
    },
    *completeCart() {
        try {
            const response = yield axios.get('cart/validate');
            yield put({ type: COMPLETE_CART_SUCCES, data: response.data.data });
        } catch (error) {
            yield put({ type: COMPLETE_CART_FAILURE, error: error.response.data });
        }
    },
    *updateQuantity({ itemId, quantity }) {
        try {
            const response = yield axios.post('cart/update-quantity/' + itemId, { quantity });
            yield put({ type: UPDATE_QUANTITY_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: UPDATE_QUANTITY_FAILURE, error: 'Noe gikk galt, prøv igjen senere!' });
        }
    },
    *updateOption({ itemId, cartId }) {
        try {
            const response = yield axios.post('cart/update-option/' + cartId, {
                tipio_offer_option_id: itemId
            });
            yield put({ type: UPDATE_OPTION_SUCCES, data: response.data.data });
        } catch (error) {
            yield put({ type: UPDATE_OPTION_FAILURE, error: 'Noe gikk galt, prøv igjen senere!', itemId: itemId });
        }
    },
    *setCartToProcessing({ klarnaOrderId }) {
        try {
            yield axios.patch('cart/set-processing', {
                klarna_order_id: klarnaOrderId
            });
            yield put({ type: SET_CART_TO_PROCESSING_SUCCESS, data: klarnaOrderId });
        } catch (error) {
            yield put({ type: SET_CART_TO_PROCESSING_FAILURE, error: 'Noe gikk galt, prøv igjen senere!' });
        }
    }
};

export const watcher = function*() {
    yield takeEvery(ADD_TO_CART_REQUEST, sagas.request);
    yield takeEvery(FETCH_CART_REQUEST, sagas.requestFetchCart);
    yield takeEvery(REMOVE_FROM_CART_REQUEST, sagas.removeProduct);
    yield takeEvery(COMPLETE_CART_REQUEST, sagas.completeCart);
    yield takeEvery(UPDATE_QUANTITY_REQUEST, sagas.updateQuantity);
    yield takeEvery(UPDATE_OPTION_REQUEST, sagas.updateOption);
    yield takeEvery(SET_CART_TO_PROCESSING_REQUEST, sagas.setCartToProcessing);
};
