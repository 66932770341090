/* eslint-disable indent */
import { actions as uiActions } from '../ui';
import { put, takeEvery } from 'redux-saga/effects';
import action from 'utils/action-creator';
import axios from 'utils/axios';

export const GET_FRONT_PAGE_DATA = '@frongpage/GET_FRONT_PAGE_DATA';

export const GET_VOTING_TIPIO_REQUEST = '@frongpage/GET_VOTING_TIPIO_REQUEST';
export const GET_VOTING_TIPIO_SUCCESS = '@frontpage/GET_VOTING_TIPIO_SUCCESS';
export const GET_VOTING_TIPIO_ERROR = '@frontpage/GET_VOTING_TIPIO_ERROR';

export const GET_BINDING_TIPIO_REQUEST = '@frontpage/GET_BINDING_TIPIO_REQUEST';
export const GET_BINDING_TIPIO_SUCCESS = '@frontpage/GET_BINDING_TIPIO_SUCCESS';
export const GET_BINDING_TIPIO_ERROR = '@frontpage/GET_BINDING_TIPIO_ERROR';

export const GET_HIGHLIGHTED_TIPIOS = '@frontpage/GET_HIGHLIGHTED_TIPIOS';
export const UPDATE_STATS = '@frontpage/UPDATE_STATS';
export const FRONTPAGE_SORTER = '@frontpage/FRONTPAGE_SORTER';

export const GET_HIGHLIGHTED_TIPIOS_SUCCESS = '@frontpage/GET_HIGHLIGHTED_TIPIOS_SUCCESS';
export const GET_HIGHLIGHTED_TIPIOS_ERROR = '@frontpage/GET_HIGHLIGHTED_TIPIOS_ERROR';

export const RELATED_TIPIO_JOIN_REQUEST = '@frongpage/RELATED_TIPIO_JOIN_REQUEST';
export const RELATED_TIPIO_JOIN_SUCCESS = '@frontpage/RELATED_TIPIO_JOIN__SUCCESS';
export const RELATED_TIPIO_JOIN_ERROR = '@frontpage/RELATED_TIPIO_JOIN__ERROR';

export const TIPIO_JOIN_REQUEST = '@frongpage/TIPIO_JOIN_REQUEST';
export const TIPIO_JOIN_SUCCESS = '@frontpage/TIPIO_JOIN__SUCCESS';
export const TIPIO_JOIN_ERROR = '@frontpage/TIPIO_JOIN__ERROR';

export const TIPIO_BIND_JOIN_REQUEST = '@frongpage/TIPIO_BIND_JOIN_REQUEST';
export const TIPIO_BIND_JOIN_SUCCESS = '@frontpage/TIPIO_BIND_JOIN__SUCCESS';
export const TIPIO_BIND_JOIN_ERROR = '@frontpage/TIPIO_BIND_JOIN__ERROR';

export const GET_USER_COUNT_SUCCESS = '@frontpage/GET_USER_COUNT_SUCCESS';
export const GET_USER_COUNT_REQUEST = '@frontpage/GET_USER_COUNT_REQUEST';
export const GET_USER_COUNT_ERROR = '@frontpage/GET_USER_COUNT_ERROR';

export const RESET_IS_COMPLETED = '@frontpage/RESET_IS_COMPLETED';

const _state = {
    loading: false,
    votingTipios: [],
    getVotingTipiosLoading: true,
    bindingTipios: [],
    getBindingTipiosLoading: true,
    error: null,
    highlightedTipios: [],
    highlightedTipiosStats: {},
    stats: {},
    relatedTipios: {},
    sorter: null,
    user_count: null,
    error_join: null,
    join_success: false
};

export default function(state = _state, action) {
    switch (action.type) {
        case GET_VOTING_TIPIO_REQUEST:
            return { ...state, getVotingTipiosLoading: true };
        case GET_VOTING_TIPIO_SUCCESS:
            return {
                ...state,
                loading: false,
                votingTipios: action.data.tipios,
                votingStats: action.data.stats && action.data.stats.data,
                getVotingTipiosLoading: false,
                relatedTipios: action.data.relatedTipios
            };
        case GET_USER_COUNT_SUCCESS:
            return { ...state, user_count: action.data };
        case GET_VOTING_TIPIO_ERROR:
            return {
                ...state,
                error: action.error,
                getVotingTipiosLoading: false
            };
        case GET_BINDING_TIPIO_REQUEST:
            return { ...state };
        case TIPIO_JOIN_ERROR:
            return {
                ...state,
                error_join: action.error,
                join_success: false
            };
        case RELATED_TIPIO_JOIN_ERROR:
            return {
                ...state,
                error_join: action.error,
                join_success: false
            };
        case GET_BINDING_TIPIO_SUCCESS:
            return {
                ...state,
                bindingTipios: action.data.tipios,
                bindingStats: action.data.stats.data,
                getBindingTipiosLoading: false
            };
        case GET_BINDING_TIPIO_ERROR:
            return {
                ...state,
                error: action.error,
                getBindingTipiosLoading: false
            };
        case GET_HIGHLIGHTED_TIPIOS:
            return {
                ...state,
                highlightedTipios: [],
                getHighlightedTipiosLoading: true
            };
        case GET_HIGHLIGHTED_TIPIOS_SUCCESS:
            return {
                ...state,
                highlightedTipios: action.data.tipios,
                highlightedTipiosStats: action.data.stats,
                getHighlightedTipiosLoading: false
            };
        case RELATED_TIPIO_JOIN_SUCCESS:
            const tipio_id = action.data.tipio_id;
            var meta = { ...state.relatedTipios.data.stats };
            meta.data[tipio_id].joined = true;
            meta.data[tipio_id].join_count = (parseInt(meta.data[tipio_id].join_count) + 1).toString();
            return { ...state, relatedTipios: { ...state.relatedTipios, stats: meta } };
        case UPDATE_STATS:
            return {
                ...state,
                join_success: true,
                votingStats: {
                    ...state.votingStats,
                    [action.payload]: {
                        ...state.votingStats[action.payload],
                        join_count:
                            state.votingStats[action.payload] &&
                            state.votingStats[action.payload].join_count &&
                            parseInt(state.votingStats[action.payload].join_count, 10) + 1,
                        joined: true
                    }
                },
                highlightedTipiosStats: {
                    ...state.highlightedTipiosStats,
                    [action.payload]: {
                        ...state.highlightedTipiosStats[action.payload],
                        join_count:
                            state.highlightedTipiosStats[action.payload] &&
                            state.highlightedTipiosStats[action.payload].join_count &&
                            parseInt(state.highlightedTipiosStats[action.payload].join_count, 10) + 1,
                        joined: true,
                        binded: true
                    }
                }
            };
        case TIPIO_JOIN_SUCCESS:
            return {
                ...state,
                join_success: true,
                votingStats: {
                    ...state.votingStats,
                    [action.data]: {
                        ...state.votingStats[action.data],
                        join_count:
                            state.votingStats[action.data] &&
                            state.votingStats[action.data].join_count &&
                            parseInt(state.votingStats[action.data].join_count, 10) + 1,
                        joined: true
                    }
                },
                highlightedTipiosStats: {
                    ...state.highlightedTipiosStats,
                    [action.data]: {
                        ...state.highlightedTipiosStats[action.data],
                        join_count:
                            state.highlightedTipiosStats[action.data] &&
                            state.highlightedTipiosStats[action.data].join_count &&
                            parseInt(state.highlightedTipiosStats[action.data].join_count, 10) + 1,
                        joined: true,
                        binded: true
                    }
                }
            };
        case TIPIO_BIND_JOIN_SUCCESS:
            return {
                ...state,
                bindingStats: {
                    ...state.bindingStats,
                    [action.data]: {
                        ...state.bindingStats[action.data],
                        unlocked: true,
                        unlock_count:
                            state.bindingStats[action.data] &&
                            state.bindingStats[action.data].unlock_count &&
                            parseInt(state.bindingStats[action.data].unlock_count, 10) + 1
                    }
                }
            };
        case FRONTPAGE_SORTER:
            return {
                ...state,
                sorter: action.sorter
            };
        case RESET_IS_COMPLETED:
            return { ...state, error_join: null, join_success: false };
        default:
            return state;
    }
}

export const actions = {
    getVotingTipios: (payload) => action(GET_VOTING_TIPIO_REQUEST, { payload }),
    getUserCount: () => action(GET_USER_COUNT_REQUEST),
    getBindingTipios: () => action(GET_BINDING_TIPIO_REQUEST),
    getHighlightedTipios: () => action(GET_HIGHLIGHTED_TIPIOS),
    updateStats: (tipioId) => action(UPDATE_STATS, { payload: tipioId }),
    joinRelatedTipios: (payload) => action(RELATED_TIPIO_JOIN_REQUEST, { payload }),
    joinTipios: (payload) => action(TIPIO_JOIN_REQUEST, { payload }),
    joinBindTipio: (payload) => action(TIPIO_BIND_JOIN_REQUEST, { payload }),
    frontpageSorter: (sorted) => action(FRONTPAGE_SORTER, { sorter: sorted }),
    resetIsCompleted: () => action(RESET_IS_COMPLETED)
};

export const sagas = {
    *getVotingTipios({ payload }) {
        try {
            let url = '/voting-tipios/page/1/static';
            if (payload.sorted) {
                url += `?sorter=${payload.sorted}`;
            }
            if (payload.filter && payload.filter.length) {
                if (!payload.sorted) {
                    url += `?filters=${payload.filter}`;
                } else {
                    url += `&filters=${payload.filter}`;
                }
            }
            const response = yield axios.get(url);

            yield put({ type: GET_VOTING_TIPIO_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: GET_VOTING_TIPIO_ERROR, error });
        }
    },
    *getBindingTipios() {
        try {
            const response = yield axios.get('/tipios/page/1/limit/8/type/2');
            yield put({ type: GET_BINDING_TIPIO_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: GET_BINDING_TIPIO_ERROR, error });
        }
    },
    *getUserCount() {
        try {
            const response = yield axios.get('/users/count');
            yield put({ type: GET_USER_COUNT_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: GET_VOTING_TIPIO_ERROR, error });
        }
    },
    *getHighlightedTipios() {
        try {
            const response = yield axios.get('/tipios/highlighted/1,2,3,4,5');
            yield put({
                type: GET_HIGHLIGHTED_TIPIOS_SUCCESS,
                data: response.data.data
            });
        } catch (error) {
            yield put({ type: GET_HIGHLIGHTED_TIPIOS_ERROR, error });
        }
    },
    *joinRelatedTipios({ payload }) {
        try {
            yield put(uiActions.notAuthorizedUserRequest());
            let response;
            if (payload.user) {
                response = yield axios.post('/temp-users/tipio-join', {
                    tipio_id: payload.tipio_id,
                    user: payload.user,
                    subscribe: payload.subscribe
                });
            } else {
                response = yield axios.post('/tipio-join', {
                    tipio_id: payload.tipio_id
                });
            }
            yield put({
                type: RELATED_TIPIO_JOIN_SUCCESS,
                data: { tipio_id: payload.tipio_id }
            });
            yield put(uiActions.notAuthorizedUserSuccess({ success: true, data: response.data.data }));
        } catch (error) {
            yield put({ type: RELATED_TIPIO_JOIN_ERROR, error: error.response.data.errors });
            yield put(uiActions.notAuthorizedUserError(error.response.data.errors));
        }
    },
    *joinTipios({ payload }) {
        try {
            yield put(uiActions.notAuthorizedUserRequest());
            let response;
            if (payload.user) {
                response = yield axios.post('/temp-users/tipio-join', {
                    tipio_id: payload.tipio_id,
                    user: payload.user,
                    subscribe: payload.subscribe
                });
            } else {
                response = yield axios.post('/tipio-join', {
                    tipio_id: payload.tipio_id
                });
            }
            yield put({
                type: TIPIO_JOIN_SUCCESS,
                data: payload.tipio_id
            });
            yield put(uiActions.notAuthorizedUserSuccess({ success: true, data: response.data.data }));
        } catch (error) {
            yield put({ type: TIPIO_JOIN_ERROR, error: error.response.data.errors });
            yield put(uiActions.notAuthorizedUserError(error.response.data.errors));
        }
    },
    *joinBindTipio({ payload }) {
        try {
            yield put(uiActions.notAuthorizedUserRequest());
            let response;
            if (payload.user) {
                response = yield axios.post('/temp-users/offer-unlock/', {
                    offer_unlock_demand: { offer_id: payload.offer_id },
                    user: payload.user,
                    subscribe: payload.subscribe
                });
            } else {
                response = yield axios.post('/offer-unlock/', {
                    offer_id: payload.offer_id
                });
            }
            yield put({
                type: TIPIO_BIND_JOIN_SUCCESS,
                data: payload.tipio_id
            });
            yield put(uiActions.notAuthorizedUserSuccess({ success: true, data: response.data.data }));
        } catch (error) {
            yield put({ type: TIPIO_BIND_JOIN_ERROR, error: error.response.data.errors });
            yield put(uiActions.notAuthorizedUserError(error.response.data.errors));
        }
    }
};

export const watcher = function* g() {
    yield takeEvery(GET_VOTING_TIPIO_REQUEST, sagas.getVotingTipios);
    yield takeEvery(GET_BINDING_TIPIO_REQUEST, sagas.getBindingTipios);
    yield takeEvery(GET_HIGHLIGHTED_TIPIOS, sagas.getHighlightedTipios);
    yield takeEvery(RELATED_TIPIO_JOIN_REQUEST, sagas.joinRelatedTipios);
    yield takeEvery(TIPIO_JOIN_REQUEST, sagas.joinTipios);
    yield takeEvery(TIPIO_BIND_JOIN_REQUEST, sagas.joinBindTipio);
    yield takeEvery(GET_USER_COUNT_REQUEST, sagas.getUserCount);
};
