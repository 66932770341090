import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';

export const REQUEST = '@app/search/bindingMeta/REQUEST';
export const SUCCESS = '@app/search/bindingMeta/SUCCESS';
export const ERROR = '@app/search/bindingMeta/ERROR';
export const RESET = '@app/search/bindingMeta/RESET';

const _state = {
    loading: false,
    data: 0
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                return state;
            case SUCCESS:
                draft.data = action.payload;
                break;
            case ERROR:
                draft.error = action.payload;
                break;
            case RESET:
                return _state;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    request: (payload) => createAction(REQUEST, { payload })
};

export const sagas = {
    *request({ payload }) {
        const { query, page, filter, sorter } = payload;
        try {
            let url = `/search/binding-meta/${page}?q=${query}&sorter=${sorter}`;
            if (filter.length) {
                url += `&filters=${filter}`;
            }
            const response = yield axios.get(url);
            const result = response.data;
            if (result.status === 200) {
                yield put(createAction(SUCCESS, { payload: result.data }));
            } else {
                yield put(createAction(ERROR, { payload: 'ERROR!' }));
            }
        } catch (error) {
            yield put(createAction(ERROR, { payload: 'ERROR!' }));
        }
    }
};

export const watcher = function* w() {
    yield takeLatest(REQUEST, sagas.request);
};
