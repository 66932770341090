import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';
import history from 'utils/history';
export const FETCH_TIPIO = 'tipioBind/Restore/FETCH_TIPIO';
export const FETCH_TIPIO_SUCCESS = 'tipioBind/Restore/FETCH_TIPIO_SUCCESS';
export const FETCH_TIPIO_ERROR = 'tipioBind/Restore/FETCH_TIPIO_ERROR';

export const RESTORE_TIPIO = 'tipioBind/Restore/RESTORE_TIPIO';
export const RESTORE_TIPIO_SUCCESS = 'tipioBind/Restore/RESTORE_TIPIO_SUCCESS';
export const RESTORE_TIPIO_ERROR = 'tipioBind/Restore/RESTORE_TIPIO_ERROR';

export const EDIT_TIPIO = 'tipioBind/Restore/EDIT_TIPIO';
export const EDIT_TIPIO_SUCCESS = 'tipioBind/Restore/EDIT_TIPIO_SUCCESS';
export const EDIT_TIPIO_ERROR = 'tipioBind/Restore/EDIT_TIPIO_ERROR';
export const SET_FORM_STATUS = '@app/tipioBind/edit/SET_FORM_STATUS';
export const SET_FORM_IS_SUBMITTING = '@app/tipioBind/edit/SET_FORM_IS_SUBMITTING';
export const CLEAN_UP = '@app/tipioBind/CLEAN_UP';

const initialState = {
    loading: true,
    error: null,
    item: {},
    restoreTipioLoading: false,
    restored: false,
    edited: false,
    isSubmitting: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_FORM_IS_SUBMITTING:
            return { ...state, isSubmitting: true };
        case FETCH_TIPIO:
            return initialState;
        case FETCH_TIPIO_SUCCESS:
            let restored = false;
            if (new Date(action.data.expires_in).getTime() > new Date().getTime()) {
                restored = false;
            }
            return { ...state, item: action.data, loading: false, restored };
        case FETCH_TIPIO_ERROR:
            return { ...state, error: action.error.toString(), loading: false };
        case RESTORE_TIPIO:
            return { ...state, restoreTipioLoading: true };
        case RESTORE_TIPIO_ERROR:
            return { ...state, error: action.error.toString(), restoreTipioLoading: false, isSubmitting: false };
        case EDIT_TIPIO_SUCCESS:
            state.item.title = action.data.title;
            state.item.market_price = action.data.market_price;
            state.item.brand = action.data.brand;
            state.item.description = action.data.description;
            state.item.category_id = action.data.category_id;
            state.item.images = action.data.files;
            state.item.temporaryImages = action.data.files.map((x) => ({ blob_url: x.blob_url || x.preview }));
            return { ...state, edited: true };
        case SET_FORM_STATUS:
            return { ...state, formStatus: action.payload };
        case RESTORE_TIPIO_SUCCESS:
            return { ...state, restored: true, isSubmitting: false, item: action.payload };
        case CLEAN_UP:
            return state;
        default:
            return state;
    }
}
export const actions = {
    fetchTipio: (tipioId) => ({ type: FETCH_TIPIO, id: tipioId }),
    restoreTipio: (data) => ({ type: RESTORE_TIPIO, data: data }),
    editTipio: (data) => ({ type: EDIT_TIPIO, data }),
    restoredSuccess: (data) => ({ type: RESTORE_TIPIO_SUCCESS, data }),
    setFormIsSubmiting: (payload) => ({ type: SET_FORM_IS_SUBMITTING, payload }),
    cleanup: () => ({ type: CLEAN_UP })
};

export const watcher = function*() {
    yield takeEvery(FETCH_TIPIO, sagas.fetchTipio);
    yield takeEvery(RESTORE_TIPIO, sagas.restoreTipio);
    yield takeEvery(EDIT_TIPIO, sagas.editTipio);
};

export const sagas = {
    fetchTipio: function* fetchTipio(payload) {
        try {
            const response = yield axios.get('/tipios/' + payload.id);
            yield put({ type: FETCH_TIPIO_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: FETCH_TIPIO_ERROR, error });
        }
    },
    restoreTipio: function* restoreTipio(payload) {
        yield put(actions.setFormIsSubmiting(true));
        try {
            const { data } = payload;
            const files = data.files?.filter((image) => image.tipio_file_store === undefined);
            const imgs = data.files?.filter((image) => !files.includes(image));
            const response = yield axios.post(
                '/tipios',
                Object.assign({}, data, { existingImages: imgs, files: undefined, id: undefined })
            );
            const result = response.data;
            try {
                for (let i = 0; i < files.length; i += 1) {
                    const formData = new FormData();
                    const file = files[i];
                    formData.append('files', file);

                    const imagesResponse = yield axios.post(`/tipios/${result.data.id}/files`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    const images = imagesResponse.data;
                    result.data.images = [...result.data.images, ...images.data];
                }
                yield put({ type: RESTORE_TIPIO_SUCCESS, payload: result.data });
                yield put({ type: SET_FORM_IS_SUBMITTING, payload: false });
            } catch (err) {
                yield put({ type: RESTORE_TIPIO_ERROR, error: 'Error while adding images' });
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.url) {
                const [err] = error.response.data.errors.url;
                if (err === 'errors.tipio_exists') {
                    const { context } = error.response.data;
                    history.push(`/tipio/exists/${context.tipio_id}`);
                }
            }
            yield put({ type: RESTORE_TIPIO_ERROR, error });
        }
    },
    editTipio: function* editTipio(payload) {
        try {
            yield put({ type: EDIT_TIPIO_SUCCESS, data: payload.data });
        } catch (error) {
            yield put({ type: EDIT_TIPIO_ERROR, error });
        }
    }
};
