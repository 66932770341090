import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';
import findCategory from 'utils/categories/findCategory';

export const FETCH_CATEGORIES = 'categories/index/FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'categories/index/FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_ERROR = 'categories/index/FETCH_CATEGORIES_ERROR';

export const NAVIGATION_PUSH = 'categories/NAVIGATION_PUSH';

export const SET_ACTIVE_CATEGORY = 'categories/SET_ACTIVE_CATEGORY';
export const GO_BACK = 'categories/GO_BACK';

const initialState = {
    allCategories: [],
    active: [],
    loading: false,
    navigation: []
};

// // TODO refactor this
// let path = [];
// const findCategory = (categories, id) => {
//     let found = null;
//     for (let i = 0; i < categories.length; i += 1) {
//         const category = categories[i];
//         if (category.id === id) {
//             found = category;
//             path.unshift(category);
//             return found;
//         }
//         if ('sub_categories' in category) {
//             found = findCategory(category.sub_categories, id);
//             if (found) { path.unshift(category); return found; }
//         }
//     };
//     return found;
// };

export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_CATEGORIES:
            return initialState;
        case FETCH_CATEGORIES_SUCCESS:
            return { ...state, allCategories: action.data, active: action.data, loading: false };
        case FETCH_CATEGORIES_ERROR:
            return { ...state, error: action.error.toString(), loading: false };
        case SET_ACTIVE_CATEGORY: {
            const { category, path, parent } = findCategory(state.allCategories, action.data.categoryId);
            return {
                ...state,
                active: category.sub_categories,
                path,
                parent,
                navigation: parent.sub_categories,
                loading: false
            };
        }
        case NAVIGATION_PUSH: {
            const { navigation } = state;
            navigation.push(action.data);
            return { ...state, navigation };
        }
        case GO_BACK: {
            return { ...state, active: state.allCategories, navigation: [], parent: {}, path: {} };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchCategories: () => ({ type: FETCH_CATEGORIES }),
    setActiveCategory: (categoryId) => ({ type: SET_ACTIVE_CATEGORY, data: { categoryId } }),
    navigationPush: (category) => ({ type: NAVIGATION_PUSH, data: { category } }),
    navigationPopUntilMatch: (category) => ({ type: SET_ACTIVE_CATEGORY, data: { category } }),
    goBack: () => ({ type: GO_BACK })
};

export const sagas = {
    fetchCategories: function* fetchCategories() {
        try {
            const response = yield axios.get('/categories');
            yield put({ type: FETCH_CATEGORIES_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: FETCH_CATEGORIES_ERROR, error });
        }
    }
};

export const watcher = function* w() {
    yield takeEvery(FETCH_CATEGORIES, sagas.fetchCategories);
};
