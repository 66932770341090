import produce from 'immer';
import createAction from 'utils/action-creator';

export const SET_CATEGORIES = '@app/filterCategories/set/SET_CATEGORIES';
export const SET_SORTED = '@app/filterCategories/set/SET_SORTED';

const _state = {
    data: [],
    sorted: 'latest'
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SET_CATEGORIES:
                draft.data = action.payload.categories;
                break;
            case SET_SORTED:
                draft.sorted = action.payload.sorted;
                break;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    setCategories: (payload) => createAction(SET_CATEGORIES, { payload }),
    setSorted: (payload) => createAction(SET_SORTED, { payload })
};
