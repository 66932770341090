import produce from 'immer';
import action from 'utils/action-creator';
import { put, takeEvery } from 'redux-saga/effects';
import { actions as authActions } from 'sagas/app/auth/auth';

export const TOGGLE_SHOPPING_BAG = 'ui/TOGGLE_SHOPPING_BAG';
export const HIDE_NAV_DROPDOWNS = 'ui/HIDE_NAV_DROPDOWNS';
export const TOGGLE_MENU = 'ui/TOGGLE_MENU';
export const TOGGLE_SEARCH = 'ui/TOGGLE_SEARCH';
export const TOGGLE_MENU_DROPDOWN = 'ui/TOGGLE_MENU_DROPDOWN';
export const TOGGLE_NAV_OVERLAY = 'ui/TOGGLE_NAV_OVERLAY';
export const TOGGLE_NOT_VERIFIED_USER = 'ui/TOGGLE_NOT_VERIFIED_USER';
export const TOGGLE_NOT_AUTHORIZED_USER = 'ui/TOGGLE_NOT_AUTHORIZED_USER';
export const TOGGLE_NOT_AUTHORIZED_USER_TYPE = 'ui/TOGGLE_NOT_AUTHORIZED_USER_TYPE';
export const NOT_AUTHORIZED_USER_JOIN_REQUEST = 'ui/NOT_AUTHORIZED_USER_JOIN_REQUEST';
export const NOT_AUTHORIZED_USER_JOIN_ERROR = 'ui/NOT_AUTHORIZED_USER_JOIN_ERROR';
export const NOT_AUTHORIZED_USER_JOIN_SUCCESS = 'ui/NOT_AUTHORIZED_USER_JOIN_SUCCESS';
export const NOT_AUTHORIZED_USER_JOIN_CREATED = 'ui/NOT_AUTHORIZED_USER_JOIN_CREATED';
export const RESET_JOIN_DATA = 'ui/RESET_JOIN_DATA';
export const TOGGLE_SEND_VERIFICATION_USER = 'ui/TOGGLE_SEND_VERIFICATION_USER';
export const TOGGLE_FILTERS_MODAL = 'ui/TOGGLE_FILTERS_MODAL';
export const TOGGLE_REGISTER_USER_MODAL = 'ui/TOGGLE_REGISTER_USER_MODAL';
export const TOGGLE_NOT_COMPLETED_USER = 'ui/TOGGLE_NOT_COMPLETED_USER';

const _state = {
    navOverlayVisible: false,
    menuVisible: false,
    menuDropDownVisible: false,
    searchVisible: false,
    shoppingBagVisible: false,
    filtersModalIsVisible: false,
    userNotVerifiedModal: false,
    sendVerificationModalVisiblity: false,
    registerUserModalVisible: false,
    userNotAuthorizedModal: false,
    join_loading: false,
    join_error: null,
    join_success: false,
    notCompletedUser: false,
    userNotAuthorizedType: null
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case TOGGLE_SHOPPING_BAG:
                draft.shoppingBagVisible = action.payload;
                draft.menuVisible = false;
                draft.navOverlayVisible = false;
                draft.searchVisible = false;
                break;
            case TOGGLE_NOT_COMPLETED_USER:
                draft.notCompletedUser = action.payload;
                break;
            case TOGGLE_MENU:
                draft.menuVisible = !state.menuVisible;
                draft.navOverlayVisible = !state.menuVisible;
                draft.menuDropDownVisible = false;
                draft.searchVisible = false;
                draft.shoppingBagVisible = false;
                break;
            case TOGGLE_MENU_DROPDOWN:
                draft.menuDropDownVisible = !state.menuDropDownVisible;
                break;
            case TOGGLE_SEARCH:
                draft.searchVisible = !state.searchVisible;
                draft.navOverlayVisible = !state.searchVisible;
                draft.menuVisible = false;
                draft.shoppingBagVisible = false;
                break;
            case HIDE_NAV_DROPDOWNS:
                return _state;
            case TOGGLE_NAV_OVERLAY:
                draft.navOverlayVisible = !state.navOverlayVisible;
                break;
            case TOGGLE_REGISTER_USER_MODAL:
                draft.registerUserModalVisible = action.payload;
                break;
            case TOGGLE_NOT_VERIFIED_USER:
                draft.userNotVerifiedModal = action.payload;
                break;
            case TOGGLE_NOT_AUTHORIZED_USER:
                draft.userNotAuthorizedModal = action.payload;
                draft.userNotAuthorizedType = null;
                break;
            case TOGGLE_NOT_AUTHORIZED_USER_TYPE:
                draft.userNotAuthorizedType = action.payload;
                break;
            case TOGGLE_SEND_VERIFICATION_USER:
                draft.sendVerificationModalVisiblity = action.payload;
                break;
            case TOGGLE_FILTERS_MODAL:
                draft.filtersModalIsVisible = action.payload;
                break;
            case NOT_AUTHORIZED_USER_JOIN_REQUEST:
                draft.join_loading = true;
                draft.join_success = false;
                draft.join_error = null;
                break;
            case NOT_AUTHORIZED_USER_JOIN_CREATED:
                draft.join_success = action.payload.success;
                draft.join_error = null;
                draft.join_loading = false;
                draft.userNotAuthorizedModal = false;
                break;
            case NOT_AUTHORIZED_USER_JOIN_ERROR:
                draft.join_error = action.payload;
                draft.join_success = false;
                draft.join_loading = false;
                break;
            case RESET_JOIN_DATA:
                draft.join_success = false;
                draft.join_error = null;
                draft.join_loading = false;
                break;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    toggleShoppingBag: (payload) => action(TOGGLE_SHOPPING_BAG, { payload }),
    toggleMenu: () => action(TOGGLE_MENU),
    toggleMenuDropdown: () => action(TOGGLE_MENU_DROPDOWN),
    toggleSearch: () => action(TOGGLE_SEARCH),
    hideNavDropdowns: () => action(HIDE_NAV_DROPDOWNS),
    toggleNavOverlay: () => action(TOGGLE_NAV_OVERLAY),
    toogleNotVerifiedUser: (payload) => action(TOGGLE_NOT_VERIFIED_USER, { payload }),
    toogleNotAuthorizedUser: (payload) => action(TOGGLE_NOT_AUTHORIZED_USER, { payload }),
    toogleNotAuthorizedUserType: (payload) => action(TOGGLE_NOT_AUTHORIZED_USER_TYPE, { payload }),
    toogleNotCompletedUser: (payload) => action(TOGGLE_NOT_COMPLETED_USER, { payload }),
    toogleRegisterUser: (payload) => action(TOGGLE_REGISTER_USER_MODAL, { payload }),
    toggleFiltersModal: (payload) => action(TOGGLE_FILTERS_MODAL, { payload }),
    toogleSendVerificationModalUser: (payload) => action(TOGGLE_SEND_VERIFICATION_USER, { payload }),
    notAuthorizedUserError: (payload) => action(NOT_AUTHORIZED_USER_JOIN_ERROR, { payload }),
    notAuthorizedUserSuccess: (payload) => action(NOT_AUTHORIZED_USER_JOIN_SUCCESS, { payload }),
    notAuthorizedUserCreated: (payload) => action(NOT_AUTHORIZED_USER_JOIN_CREATED, { payload }),
    notAuthorizedUserRequest: () => action(NOT_AUTHORIZED_USER_JOIN_REQUEST),
    resetJoinData: () => action(RESET_JOIN_DATA)
};

export const sagas = {
    *joinTipioSuccess({ payload }) {
        try {
            yield put({ type: NOT_AUTHORIZED_USER_JOIN_CREATED, payload });
            yield put(authActions.success(payload.data.token, payload.data.user));
        } catch (error) {
            yield put({ type: NOT_AUTHORIZED_USER_JOIN_ERROR, error });
        }
    }
};

export const watcher = function* w() {
    yield takeEvery(NOT_AUTHORIZED_USER_JOIN_SUCCESS, sagas.joinTipioSuccess);
};
