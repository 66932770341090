/* eslint-disable indent */
import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';

export const REQUEST = '@app/klarna/complete/REQUEST';
export const INIT = '@app/klarna/complete/INIT';
export const SUCCESS = '@app/klarna/complete/SUCCESS';
export const ERROR = '@app/klarna/complete/ERROR';
export const RESET = '@app/klarna/complete/RESET';

const _state = {
    data: null,
    loading: false,
    error: null,
    init: false
};

const reducer = (state = _state, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case REQUEST:
                draft.loading = true;
                draft.init = false;
                break;
            case INIT:
                draft.init = true;
                break;
            case SUCCESS:
                draft.loading = false;
                draft.data = payload;
                break;
            case ERROR:
                draft.error = payload;
                draft.loading = false;
                break;
            case RESET:
                return _state;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    request: (payload) => createAction(REQUEST, { payload }),
    init: () => createAction(INIT),
    reset: () => createAction(RESET)
};

export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.post('/klarna/complete', { order_id: payload });
            const result = response.data;
            yield put(createAction(SUCCESS, { payload: result.data }));
        } catch (error) {
            yield put(createAction(ERROR, { payload: 'ERROR!' }));
        }
    }
};

export const watcher = function* w() {
    yield takeLatest(REQUEST, sagas.request);
};
