import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from '../../sagas/app/scrollManager';
import getScrollPosition from '../../utils/ScrollManager/getScrollPosition';
class ScrollToTop extends React.Component {
    componentWillReceiveProps(nextProps) {
        let path = this.props.location.pathname.split('/');
        let nextPath = nextProps.location.pathname.split('/');
        if (this.props.location.pathname === '/') {
            this.props.dispatch(actions.saveScrollPosition('front_page', getScrollPosition()));
            this.scrollToTop();
        } else if (nextProps.location.pathname === '/') {
            /**
             * MOVING TO INDEX PAGE, DO NOTHING!
             */
        } else if (path[1] === 'all-votings') {
            if (path[1] === 'all-votings' && nextPath[1] === 'all-votings') {
                this.props.dispatch(actions.saveScrollPosition('view_all_voting', { x: 0, y: 0 }));
            } else {
                this.props.dispatch(actions.saveScrollPosition('view_all_voting', getScrollPosition()));
            }
            this.scrollToTop();
        } else if (path[1] === 'all-binding') {
            if (path[1] === 'all-binding' && nextPath[1] === 'all-binding') {
                this.props.dispatch(actions.saveScrollPosition('view_all_binding', { x: 0, y: 0 }));
            } else {
                this.props.dispatch(actions.saveScrollPosition('view_all_binding', getScrollPosition()));
            }
            this.scrollToTop();
        } else if (path[1] === 'search') {
            if (path[1] === 'search' && nextPath[1] === 'search') {
                this.props.dispatch(actions.saveScrollPosition('search', { x: 0, y: 0 }));
            } else {
                this.props.dispatch(actions.saveScrollPosition('search', getScrollPosition()));
            }
            this.scrollToTop();
        } else if (path[1] === 'tipios') {
            if (path[1] === 'tipios' && nextPath[1] === 'tipios') {
                this.props.dispatch(actions.saveScrollPosition('tipios', { x: 0, y: 0 }));
            } else {
                this.props.dispatch(actions.saveScrollPosition('tipios', getScrollPosition()));
            }
            this.scrollToTop();
        } else {
            this.scrollToTop();
        }
        if (
            (nextProps.location.pathname === '/' && this.props.location.pathname === '/auth/login') ||
            (nextProps.location.pathname === '/' && this.props.location.pathname === '/user/personaldetails')
        ) {
            this.scrollToTop();
        }
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    render() {
        return <></>;
    }
}

ScrollToTop.propTypes = {
    location: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default withRouter(connect()(ScrollToTop));
