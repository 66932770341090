import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';
import produce from 'immer';
import { actions as tipioStatusActions } from './tipioStatus';

export const FETCH_TIPIO = '@app/tipio/FETCH_TIPIO';
export const FETCH_TIPIO_SUCCESS = '@app/tipio/FETCH_TIPIO_SUCCESS';
export const FETCH_TIPIO_ERROR = '@app/tipio/FETCH_TIPIO_ERROR';
export const REQUEST_META = '@app/tipio/REQUEST_META';
export const SUCCESS_META = '@app/tipio/SUCCESS_META';
export const ERROR_META = '@app/tipio/ERROR_META';
export const INCREASE_INTERESTED_COUNTER = '@app/tipio/INCREASE_INTERESTED_COUNTER';
export const JOIN_TIPIO_SUCCESS = '@app/tipio/JOIN_TIPIO_SUCCESS';

const _state = {
    isLoading: true,
    tipioFetchError: null,
    meta: {
        joined_count: 0
    }
};

export default (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FETCH_TIPIO:
                return _state;
            case FETCH_TIPIO_SUCCESS:
                draft.tipio = action.data;
                draft.isLoading = false;
                break;
            case FETCH_TIPIO_ERROR:
                draft.tipioFetchError = action.error.toString();
                draft.isLoading = false;
                break;
            case REQUEST_META:
                draft.isLoading = true;
                break;
            case SUCCESS_META:
                draft.meta = action.data;
                draft.isLoading = false;
                break;
            case ERROR_META:
                draft.meta = action.data;
                draft.isLoading = false;
                break;
            case INCREASE_INTERESTED_COUNTER:
                draft.meta.joined_count += action.value;
                break;
            default:
                break;
        }
    });

export const actions = {
    request: (tipioId, incldueMeta = true) => ({ type: FETCH_TIPIO, id: tipioId, incldueMeta }),
    meta: (tipioId) => ({ type: REQUEST_META, id: tipioId }),
    increaseInterestedCounter: (value) => ({ type: INCREASE_INTERESTED_COUNTER, value })
};

export const sagas = {
    fetchTipio: function* fetchTipio(payload) {
        try {
            const response = yield axios.get('/tipios/' + payload.id);
            yield put({ type: FETCH_TIPIO_SUCCESS, data: response.data.data });
            if (payload.incldueMeta) {
                yield put(actions.meta(payload.id));
                yield put(tipioStatusActions.getTipioStatus(payload.id));
            }
        } catch (error) {
            yield put({ type: FETCH_TIPIO_ERROR, error });
        }
    },
    *meta(payload) {
        try {
            const response = yield axios.get('/company/tipio-meta/' + payload.id);
            yield put({ type: SUCCESS_META, data: response.data.data });
        } catch (error) {
            yield put({ type: ERROR_META, error });
        }
    }
};

export const watcher = function* fetch() {
    yield takeEvery(FETCH_TIPIO, sagas.fetchTipio);
    yield takeEvery(REQUEST_META, sagas.meta);
};
