import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';
import { actions as authActions } from 'sagas/app/auth/auth';

export const REQUEST = '@app/profile/editProfile/REQUEST';
export const FAILURE = '@app/profile/editProfile/FAILURE';
export const SUCCESS = '@app/profile/editProfile/SUCCESS';
export const CLEAR_FORM = '@app/profile/editProfile/CLEAR_FORM';

const _state = {
    error: null,
    data: false,
    loading: false
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                draft.loading = true;
                break;
            case SUCCESS:
                draft.data = true;
                draft.loading = false;
                break;
            case FAILURE:
                draft.error = action.payload;
                draft.loading = false;
                break;
            case CLEAR_FORM:
                return _state;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    request: (payload) => createAction(REQUEST, { payload }),
    failure: (payload) => createAction(FAILURE, { payload }),
    success: () => createAction(SUCCESS),
    clearForm: () => createAction(CLEAR_FORM)
};

export const sagas = {
    *request({ payload }) {
        try {
            const res = yield axios.patch('/users', payload);
            yield put(authActions.userCompletedSuccess(res.data.data));
            yield put(actions.success());
        } catch (error) {
            let errorDescription = '';
            if (error.response) {
                if (error.response.status === 422 || error.status === 500) {
                    if (error.response?.data?.errors) {
                        errorDescription = error.response?.data?.errors;
                    } else errorDescription = error.response.data.message;
                }
            }
            if (errorDescription === '') {
                errorDescription = error.toString();
            }
            yield put(actions.failure(errorDescription || 'Error!'));
        }
    }
};

export const watcher = function* w() {
    yield takeLatest(REQUEST, sagas.request);
};
