import action from 'utils/action-creator';
import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';

export const CREATE_TIPIO_INIT = 'CREATE_TIPIO_INIT';
export const SAVE_SELECTED_IMAGES_WITH_ORDER = 'SAVE_SELECTED_IMAGES_WITH_ORDER';

export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const TIPIO_SAVE_SUCCESS = 'TIPIO_SAVE_SUCCESS';
export const TIPIO_SAVE_REQUEST = 'TIPIO_SAVE_REQUEST';
export const TIPIO_CREATE_SUCCESS = 'TIPIO_CREATE_SUCCESS';
export const TIPIO_CREATE_FAILURE = 'TIPIO_CREATE_FAILURE';
export const PARSE_URL_FAILURE = 'PARSE_URL_FAILURE';
export const PARSE_URL_REQUEST = 'PARSE_URL_REQUEST';
export const PARSE_URL_SUCCESS = 'PARSE_URL_SUCCESS';
export const CATEGORY_CHANGED = 'CATEGORY_CHANGED';
export const TIPIO_CREATE_CLEANUP = 'TIPIO_CREATE_CLEANUP';

const _state = {
    title: '',
    price: 0
};

export default function(state = _state, action) {
    switch (action.type) {
        case CREATE_TIPIO_INIT:
            return { ...state, ...action.data, loading: false };
        case SAVE_SELECTED_IMAGES_WITH_ORDER:
            return {
                ...state,
                selectedImages: action.data.result,
                selectedOrder: action.data.selectedOrder,
                selectedMax: action.data.selectedMax
            };
        case FETCH_SUCCESS:
            return { ...state, ...action.payload };
        case FETCH_FAILURE:
            return { ...state, error: action.error };
        case TIPIO_SAVE_SUCCESS:
            return { ...state, tipio: action.payload, loading: false };
        case TIPIO_SAVE_REQUEST:
            return { ...state, loading: true };
        case TIPIO_CREATE_SUCCESS:
            return { ...state, okPage: true, error: null };
        case TIPIO_CREATE_FAILURE:
            return { ...state, loading: false, error: action.error };
        case PARSE_URL_REQUEST:
            return { loading: true };
        case PARSE_URL_FAILURE:
            return { ...state, error: action.data, loading: false };
        case CATEGORY_CHANGED:
            return { ...state, category_id: action.categoryId };
        case TIPIO_CREATE_CLEANUP:
            return _state;
        default:
            return state;
    }
}

export const actions = {
    request: () => action(PARSE_URL_REQUEST),
    init: (data) => action(CREATE_TIPIO_INIT, { data }),
    parseUrlFailure: (data) => action(PARSE_URL_FAILURE, { data }),
    saveSelectedImagesWithOrder: (data) => action(SAVE_SELECTED_IMAGES_WITH_ORDER, { data }),
    fetchCategories: {
        request: () => action(FETCH_REQUEST),
        success: (payload) => action(FETCH_SUCCESS, { payload }),
        failure: (error) => action(FETCH_FAILURE, { error })
    },
    saveTipio: (payload) => action(TIPIO_SAVE_SUCCESS, { payload }),
    tipioCreated: (payload) => action(TIPIO_CREATE_SUCCESS, { payload }),
    createTipioFailed: (error) => action(TIPIO_CREATE_FAILURE, { error }),
    saveTipioRequest: () => action(TIPIO_SAVE_REQUEST, {}),
    categoryChanged: (categoryId) => action(CATEGORY_CHANGED, { categoryId }),
    cleanup: () => action(TIPIO_CREATE_CLEANUP)
};

export const watcher = function*() {
    yield takeEvery(FETCH_REQUEST, sagas.fetchCategories);
};

export const sagas = {
    fetchCategories: function*() {
        try {
            const res = yield axios.get('categories');
            yield put(actions.fetchCategories.success({ categories: res.data.data }));
        } catch (error) {
            yield put(actions.fetchCategories.failure({ error: error.response.data }));
        }
    }
};
