import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';
import { USERADDRESS_CREATED_SUCCESS } from 'sagas/app/profile/profile';

export const REQUEST = '@app/User/Address/REQUEST';
export const SUCCESS = '@app/User/Address/SUCCESS';
export const ERROR = '@app/User/Address/ERROR';
export const RESET = '@app/User/Address/RESET';

const _state = {
    isLoading: false,
    error: null,
    success: false
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                return { ...state, isLoading: true, status: false };
            case RESET:
                return { isLoading: false, error: null, success: false };
            case SUCCESS:
                draft.isLoading = false;
                draft.success = true;
                draft.status = true;
                break;
            case ERROR:
                draft.isLoading = false;
                draft.error = action.payload;
                draft.success = false;
                break;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    request: (payload) => createAction(REQUEST, { payload }),
    reset: () => createAction(RESET)
};

export const sagas = {
    *request({ payload }) {
        try {
            let response;
            if (payload.id) {
                response = yield axios.patch('/user-addresses/' + payload.id, payload.values);
            } else response = yield axios.post('/user-addresses/', payload.values);
            const result = response.data;
            if (result.status === 200) {
                yield put(createAction(SUCCESS, { payload: result }));
                yield put(createAction(USERADDRESS_CREATED_SUCCESS, result));
            } else {
                yield put(createAction(ERROR, { payload: 'ERROR!' }));
            }
        } catch (error) {
            yield put(createAction(ERROR, { payload: 'ERROR!' }));
        }
    }
};

export const watcher = function* w() {
    yield takeLatest(REQUEST, sagas.request);
};
