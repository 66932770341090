const findCategory = (categories, id, path) => {
    let found = null;
    for (let i = 0; i < categories.length; i += 1) {
        const category = categories[i];
        if (category.id === id) {
            found = category;
            path.unshift(category);
            return found;
        }
        if ('sub_categories' in category) {
            found = findCategory(category.sub_categories, id, path);
            if (found) { path.unshift(category); return found; }
        }
    };
    return found;
};
export default function (categories, id) {
    const path = [];
    const category = findCategory(categories, parseInt(id, 10), path);
    const parent = path.length === 1 ? {...path[0], sub_categories: []} : path.slice(-2, -1)[0];
    return { category, path, parent };
};

