import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';
export const REQUEST = '@app/cart/additionalSales/list/REQUEST';
export const SUCCESS = '@app/cart/additionalSales/SUCCESS';
export const ERROR = '@app/cart/additionalSales/ERROR';
export const REMOVE_ADDISIONAL_SALE = '@app/cart/additionalSales/REMOVE';

const _state = {
    isLoading: true,
    error: null,
    data: {}
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                return _state;
            case REMOVE_ADDISIONAL_SALE:
            case SUCCESS:
                draft.data = action.payload;
                draft.isLoading = false;
                break;
            case ERROR:
                draft.error = action.payload;
                break;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    request: (payload) => createAction(REQUEST, { payload }),
    updateAddisionalSale: (data) => createAction(REMOVE_ADDISIONAL_SALE, { data })
};

export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.get(`/cart/additional-sales${payload?.active ? '?active=true' : ''}`);
            const result = response.data;
            if (result.status === 200) {
                yield put(createAction(SUCCESS, { payload: result.data }));
            } else {
                yield put(createAction(ERROR, { payload: 'ERROR!' }));
            }
        } catch (error) {
            yield put(createAction(ERROR, { payload: 'ERROR!' }));
        }
    }
};

export const watcher = function* w() {
    yield takeLatest(REQUEST, sagas.request);
};
