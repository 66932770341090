import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';
import produce from 'immer';
import { actions as uiActions } from '../ui';
import { actions as tipioActions } from './tipio';
import { actions as tipioStatusActions } from './tipioStatus';

export const FETCH_RELATED_TIPIO = 'tipioJoin/FETCH_RELATED_TIPIO';
export const FETCH_RELATED_TIPIO_SUCCESS = 'tipioJoin/FETCH_RELATED_TIPIO_SUCCESS';
export const FETCH_RELATED_TIPIO_ERROR = 'tipioJoin/FETCH_RELATED_TIPIO_ERROR';

export const JOIN_TIPIO = 'tipioJoin/JOIN_TIPIO';
export const JOIN_TIPIO_SUCCESS = 'tipioJoin/JOIN_TIPIO_SUCCESS';
export const JOIN_TIPIO_ERROR = 'tipioJoin/JOIN_TIPIO_ERROR';

export const JOIN_RELATED_TIPIO = 'relatedTipioJoin/JOIN_TIPIO';
export const JOIN_RELATED_TIPIO_SUCCESS = 'relatedTipioJoin/JOIN_TIPIO_SUCCESS';
export const JOIN_RELATED_TIPIO_ERROR = 'relatedTipioJoin/JOIN_TIPIO_ERROR';

export const CHECK_IF_USER_JOINED_TIPIO = 'tipioJoin/CHECK_IF_USER_JOINED_TIPIO';
export const CHECK_IF_USER_JOINED_TIPIO_SUCCESS = 'tipioJoin/CHECK_IF_USER_JOINED_TIPIO_SUCCESS';
export const CHECK_IF_USER_JOINED_TIPIO_ERROR = 'tipioJoin/CHECK_IF_USER_JOINED_TIPIO_ERROR';

export const CHECK_IF_VOTING_EXPIRED = 'tipioJoin/CHECK_IF_VOTING_EXPIRED';
export const CHECK_IF_VOTING_EXPIRED_SUCCESS = 'tipioJoin/CHECK_IF_VOTING_EXPIRED_SUCCESS';
export const CHECK_IF_VOTING_EXPIRED_ERROR = 'tipioJoin/CHECK_IF_VOTING_EXPIRED_ERROR';

export const SET_VOTING_EXPIRED_MODAL_SEEN = 'tipioJoin/SET_VOTING_EXPIRED_MODAL_SEEN';

export const NOT_INTERESTED = 'tipioJoin/NOT_INTERESTED';
export const NOT_INTERESTED_SUCCESS = 'tipioJoin/NOT_INTERESTED_SUCCESS';
export const NOT_INTERESTED_ERROR = 'tipioJoin/NOT_INTERESTED_ERROR';

const initialState = {
    loading: true,
    error: null,
    item: {},
    relatedTipios: [],
    joinTipioLoading: false,
    joined: false,
    votingExpired: false,
    votingExpiredModalSeen: false,
    checkIfVotingExpiredLoading: true
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_RELATED_TIPIO:
            return { ...state };
        case FETCH_RELATED_TIPIO_SUCCESS:
            return { ...state, relatedTipios: action.data, loading: false };
        case FETCH_RELATED_TIPIO_ERROR:
            return { ...state, error: action.error.toString(), loading: false };
        case JOIN_TIPIO:
            return { ...state, joinTipioLoading: true };
        case JOIN_TIPIO_SUCCESS: {
            const nextState = produce(state, (draftState) => {
                // draftState.item.meta.joined_count += 1;
                draftState.joinTipioLoading = false;
                draftState.joined = true;
            });
            return nextState;
        }
        case JOIN_RELATED_TIPIO_SUCCESS: {
            const nextState = produce(state, (draftState) => {
                // draftState.item.meta.joined_count += 1;
                const tipio_id = action.data.tipio_id;
                draftState.relatedTipios.stats.data[tipio_id] = {
                    ...draftState.relatedTipios.stats.data[tipio_id],
                    joined: true,
                    join_count: (parseInt(draftState.relatedTipios.stats.data[tipio_id].join_count) + 1).toString()
                };
            });
            return nextState;
        }
        case JOIN_TIPIO_ERROR:
            return {
                ...state,
                error: action.error.toString(),
                joinTipioLoading: false
            };
        case CHECK_IF_USER_JOINED_TIPIO:
            return { ...state, joinTipioLoading: true };
        case CHECK_IF_USER_JOINED_TIPIO_SUCCESS:
            return { ...state, joinTipioLoading: false, joined: true };
        case CHECK_IF_USER_JOINED_TIPIO_ERROR:
            if (action.error.response.status !== 403 && action.error.response.status !== 404) {
                return {
                    ...state,
                    joinTipioLoading: false,
                    error: action.error.toString()
                };
            }
            return { ...state, joinTipioLoading: false };
        case CHECK_IF_VOTING_EXPIRED:
            return { ...state, checkIfVotingExpiredLoading: true };
        case CHECK_IF_VOTING_EXPIRED_SUCCESS:
            return {
                ...state,
                checkIfVotingExpiredLoading: false,
                votingExpired: action.data.votingExpired
            };
        case CHECK_IF_VOTING_EXPIRED_ERROR:
            if (action.error.response.status !== 403 && action.error.response.status !== 404) {
                return {
                    ...state,
                    checkIfVotingExpiredLoading: false,
                    votingExpired: false,
                    error: action.error.toString()
                };
            }
            return { ...state, votingExpired: false };
        case SET_VOTING_EXPIRED_MODAL_SEEN:
            return { ...state, votingExpiredModalSeen: true };
        case NOT_INTERESTED:
            return { ...state, joinTipioLoading: true };
        case NOT_INTERESTED_SUCCESS: {
            const nextState = produce(state, (draftState) => {
                draftState.joined = false;
                draftState.joinTipioLoading = false;
            });
            return nextState;
        }
        case NOT_INTERESTED_ERROR:
            return {
                ...state,
                error: action.error.toString(),
                joinTipioLoading: false
            };
        default:
            return state;
    }
}

export const actions = {
    fetchRelatedTipios: (orderId) => ({ type: FETCH_RELATED_TIPIO, id: orderId }),

    joinTipio: (payload) => ({ type: JOIN_TIPIO, payload }),
    joinRelatedTipio: (payload) => ({ type: JOIN_RELATED_TIPIO, payload }),
    notInterested: (tipioId) => ({ type: NOT_INTERESTED, id: tipioId }),
    setVotingExpiredModalSeen: (status) => ({
        type: SET_VOTING_EXPIRED_MODAL_SEEN,
        status
    }),
    requestCheck: (data) => ({ type: CHECK_IF_USER_JOINED_TIPIO, data })
};

export const watcher = function*() {
    yield takeEvery(FETCH_RELATED_TIPIO, sagas.fetchRelatedTipios);
    yield takeEvery(JOIN_TIPIO, sagas.joinTipio);
    yield takeEvery(JOIN_RELATED_TIPIO, sagas.joinRelatedTipio);
    yield takeEvery(NOT_INTERESTED, sagas.notInterested);
    yield takeEvery(CHECK_IF_USER_JOINED_TIPIO, sagas.checkIfUserJoinedTipio);
    yield takeEvery(CHECK_IF_VOTING_EXPIRED, sagas.checkIfVotingExpired);
};

export const sagas = {
    fetchRelatedTipios: function* fetchRelatedTipios(payload) {
        try {
            const response = yield axios.get('/related/voting/' + payload.id);
            yield put({
                type: FETCH_RELATED_TIPIO_SUCCESS,
                data: response.data.data
            });
        } catch (error) {
            yield put({ type: FETCH_RELATED_TIPIO_ERROR, error });
        }
    },
    joinTipio: function* joinTipio({ payload }) {
        try {
            yield put(uiActions.notAuthorizedUserRequest());
            let response;
            if (payload.user) {
                response = yield axios.post('/temp-users/tipio-join', {
                    tipio_id: payload.tipio_id,
                    user: payload.user,
                    subscribe: payload.subscribe
                });
            } else {
                response = yield axios.post('/tipio-join', {
                    tipio_id: payload.tipio_id
                });
            }
            yield put(uiActions.notAuthorizedUserSuccess({ success: true, data: response.data.data }));
            yield put(tipioActions.increaseInterestedCounter(1));
            yield put({
                type: JOIN_TIPIO_SUCCESS,
                data: { tipio_id: payload.tipio_id }
            });
        } catch (error) {
            yield put({ type: JOIN_TIPIO_ERROR, error });
            yield put(uiActions.notAuthorizedUserError(error.response.data.errors));
        }
    },
    notInterested: function* notInterested(payload) {
        try {
            const response = yield axios.delete(`/tipio-join/${payload.id}`);
            yield put({
                type: NOT_INTERESTED_SUCCESS,
                data: response.data.data
            });
            yield put(tipioActions.increaseInterestedCounter(-1));
            yield put(tipioStatusActions.setJoined(false));
        } catch (error) {
            yield put({ type: NOT_INTERESTED_ERROR, error });
        }
    },
    checkIfUserJoinedTipio: function* checkIfUserJoinedTipio(payload) {
        try {
            const response = yield axios.get('/tipio-join/' + payload.data);
            yield put({
                type: CHECK_IF_USER_JOINED_TIPIO_SUCCESS,
                data: response.data.data
            });
        } catch (error) {
            yield put({ type: CHECK_IF_USER_JOINED_TIPIO_ERROR, error });
        }
    },
    checkIfVotingExpired: function* checkIfVotingExpired(payload) {
        try {
            const response = yield axios.get('/tipio-voting-expired/' + payload.data);
            yield put({
                type: CHECK_IF_VOTING_EXPIRED_SUCCESS,
                data: response.data.data
            });
        } catch (error) {
            yield put({ type: CHECK_IF_USER_JOINED_TIPIO_ERROR, error });
        }
    },
    joinRelatedTipio: function* joinTipio({ payload }) {
        try {
            yield put(uiActions.notAuthorizedUserRequest());
            let response;
            if (payload.user) {
                response = yield axios.post('/temp-users/tipio-join', {
                    tipio_id: payload.tipio_id,
                    user: payload.user,
                    subscribe: payload.subscribe
                });
            } else {
                response = yield axios.post('/tipio-join', {
                    tipio_id: payload.tipio_id
                });
            }
            yield put(uiActions.notAuthorizedUserSuccess({ success: true, data: response.data.data }));
            yield put({
                type: JOIN_RELATED_TIPIO_SUCCESS,
                data: { tipio_id: payload.tipio_id }
            });
        } catch (error) {
            yield put({ type: JOIN_RELATED_TIPIO_ERROR, error });
            yield put(uiActions.notAuthorizedUserError(error.response.data.errors));
        }
    }
};
