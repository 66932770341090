import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';
import describeTipioError from 'utils/describeTipioError';

export const REQUEST = '@app/tipio/report/REQUEST';
export const SUCCESS = '@app/tipio/report/SUCCESS';
export const SET_FORM_IS_SUBMITTING = '@app/tipio/report/SET_FORM_IS_SUBMITTING';
export const SET_FORM_STATUS = '@app/tipio/report/SET_FORM_STATUS';
export const SET_ERROR = '@app/tipio/report/SET_ERROR';

const _state = {
    error: null,
    isSubmitting: false,
    formStatus: false,
    data: null
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SET_FORM_IS_SUBMITTING:
                draft.isSubmitting = action.payload;
                break;
            case SET_FORM_STATUS:
                draft.formStatus = action.payload;
                break;
            case SUCCESS:
                draft.data = action.payload;
                break;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    request: (payload) => createAction(REQUEST, { payload }),
    setError: (payload) => createAction(SET_ERROR, { payload }),
    setFormIsSubmiting: (payload) => createAction(SET_FORM_IS_SUBMITTING, { payload }),
    success: (payload) => createAction(SUCCESS, { payload })
};

export const sagas = {
    *request(action) {
        yield put(actions.setFormIsSubmiting(true));
        const { payload } = action;
        try {
            const response = yield axios.post(`/report-tipio/${payload}`);
            const result = response.data;
            yield put(createAction(SUCCESS, { payload: result.data }));
            yield put(actions.setFormIsSubmiting(false));
            yield put(
                createAction(SET_FORM_STATUS, {
                    payload: {
                        success: true
                    }
                })
            );
        } catch ({ response }) {
            yield put(actions.setFormIsSubmiting(false));
            yield put(actions.setError((response.data && describeTipioError(response.data)) || 'Error!'));
        }
    },
    *setError(action) {
        yield put(createAction(SET_FORM_IS_SUBMITTING, { payload: false }));
        yield put(
            createAction(SET_FORM_STATUS, {
                payload: {
                    success: false,
                    message: action.payload
                }
            })
        );
    },
    *clearForm() {
        yield put(createAction(SET_FORM_STATUS, null));
    }
};

export const watcher = function* w() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(SET_ERROR, sagas.setError);
};
