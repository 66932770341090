import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import { replace } from 'react-router-redux';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';

export const TOGGLE_CONFIRMATION_MODAL = '@app/profile/deleteAccount/TOGGLE_CONFIRMATION_MODAL';
export const REQUEST = '@app/profile/deleteAccount/REQUEST';
export const SET_REQUEST_STATUS = '@app/profile/deleteAccount/SET_REQUEST_STATUS';
export const SET_ERROR = '@app/profile/deleteAccount/SET_ERROR';
export const SET_IS_LOADING = '@app/profile/deleteAccount/SET_IS_LOADING';
export const CLEAR_FORM = '@app/profile/deleteAccount/CLEAR_FORM';

const _state = {
    confirmationModalVisible: null,
    isLoading: false,
    error: null
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case TOGGLE_CONFIRMATION_MODAL:
                draft.confirmationModalVisible = !draft.confirmationModalVisible;
                break;
            case SET_IS_LOADING:
                draft.isLoading = action.payload;
                break;
            case SET_REQUEST_STATUS:
                draft.requestStatus = action.payload;
                break;
            case SET_ERROR:
                draft.error = action.payload;
                break;
            case CLEAR_FORM:
                return _state;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    togglConfirmationModal: () => createAction(TOGGLE_CONFIRMATION_MODAL),
    setRequestStatus: (payload) => createAction(SET_REQUEST_STATUS, { payload }),
    setError: (payload) => createAction(SET_ERROR, { payload }),
    request: () => createAction(REQUEST),
    clearForm: () => createAction(CLEAR_FORM)
};

export const sagas = {
    *request() {
        yield put(createAction(SET_IS_LOADING, { payload: true }));
        try {
            const response = yield axios.delete('/users');
            const result = response.data;
            if (result.status === 200) {
                yield put(createAction(SET_IS_LOADING, { payload: true }));
                yield put(replace('/profile/delete/success'));
            } else {
                yield put(actions.setError(response.data));
            }
        } catch (error) {
            // error response handle
            yield put(createAction(SET_IS_LOADING, { payload: true }));
            let errorDescription = '';
            if (error.response) {
                if (error.response.status === 422 || error.status === 500) {
                    errorDescription = error.response.data.message;
                }
            }
            if (errorDescription === '') {
                errorDescription = error.toString();
            }
            yield put(actions.setError(errorDescription || 'Error!'));
            yield put(createAction(TOGGLE_CONFIRMATION_MODAL));
        }
    },
    *setError(action) {
        yield put(createAction(SET_IS_LOADING, { payload: false }));
        yield put(
            createAction(SET_REQUEST_STATUS, {
                payload: {
                    success: false,
                    message: action.payload
                }
            })
        );
    }
};

export const watcher = function* w() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(SET_ERROR, sagas.setError);
};
