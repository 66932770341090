import { put, takeEvery } from 'redux-saga/effects';
import action from 'utils/action-creator';
import axios from 'utils/axios';
import produce from 'immer';

export const FETCH_RELATED_OFFERS_REQUEST = 'tipioOffers/FETCH_RELATED_OFFERS_REQUEST';
export const FETCH_RELATED_OFFERS_SUCCESS = 'tipioOffers/FETCH_RELATED_OFFERS_SUCCESS';
export const FETCH_RELATED_OFFERS_FAILURE = 'tipioOffers/FETCH_RELATED_OFFERS_FAILURE';

const _state = {
    relatedOffers: [],
    groupByCompany: false,
    loading: false
};

export default function(state = _state, action) {
    switch (action.type) {
        case FETCH_RELATED_OFFERS_REQUEST:
            return { ...state, loading: true };
        case FETCH_RELATED_OFFERS_SUCCESS: {
            const nextState = produce(state, (draftState) => {
                draftState.relatedOffers = action.data;
                draftState.groupByCompany = true;
                draftState.loading = false;
            });
            return nextState;
        }
        case FETCH_RELATED_OFFERS_FAILURE:
            return { ...state, loading: false, error: action.error, status: false };
        default:
            return state;
    }
}

export const actions = {
    requestRelatedOffers: (tipioOfferId) => action(FETCH_RELATED_OFFERS_REQUEST, { tipioOfferId })
};

export const watcher = function*() {
    yield takeEvery(FETCH_RELATED_OFFERS_REQUEST, sagas.requestRelatedOffers);
};

export const sagas = {
    requestRelatedOffers: function*({ tipioOfferId }) {
        try {
            // const response = yield axios.get('/tipio-offer-related/' + tipioOfferId + '/limit/8');
            const response = yield axios.get('/tipio-offer-related/group-by-company/' + tipioOfferId);
            yield put({ type: FETCH_RELATED_OFFERS_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: FETCH_RELATED_OFFERS_FAILURE, error });
        }
    }
};
