import { put, takeEvery } from 'redux-saga/effects';
import produce from 'immer';
import action from 'utils/action-creator';
import axios from 'utils/axios';
import { push } from 'react-router-redux';
import { FETCH_RELATED_OFFERS_REQUEST } from '../tipio/tipioOffer';
import { FETCH_PROFILE } from 'sagas/app/profile/profile';

export const ADD_TO_SHOPPINGBAG_REQUEST = 'cart/ADD_TO_SHOPPINGBAG_REQUEST';
export const ADD_TO_SHOPPINGBAG_SUCCESS = 'cart/ADD_TO_SHOPPINGBAG_SUCCESS';
export const ADD_TO_SHOPPINGBAG_FAILURE = 'cart/ADD_TO_SHOPPINGBAG_FAILURE';

export const FETCH_SHOPPINGBAG_REQUEST = 'cart/FETCH_SHOPPINGBAG_REQUEST';
export const FETCH_SHOPPINGBAG_SUCCESS = 'cart/FETCHSHOPPINGBAG_SUCCESS';
export const FETCH_SHOPPINGBAG_FAILURE = 'cart/FETCH_SHOPPINGBAG_FAILURE';

export const REMOVE_SHOPPINGBAG_ITEMS = 'cart/REMOVE_SHOPPINGBAG_ITEMS';
export const SET_SHOPPINGBAG_STATUS_FALSE = 'cart/SET_SHOPPINGBAG_STATUS_FALSE';
export const ADD_SHOPPINGBAG_ITEM = 'cart/ADD_SHOPPINGBAG_ITEM';

export const RESET_SHOPPINGBAG = 'cart/RESET_SHOPPINGBAG';

const _state = {
    items: {},
    loading: false,
    status: false,
    error: null
};

export default function(state = _state, action) {
    switch (action.type) {
        case ADD_TO_SHOPPINGBAG_REQUEST:
            return { ...state, loading: true, status: false };
        case ADD_TO_SHOPPINGBAG_SUCCESS: {
            const nextState = produce(state, (draftState) => {
                const { items } = state;

                const itemIndex = items.findIndex((item) => item.id === action.data.item.id);
                if (itemIndex === -1) {
                    items.push(action.data.item);
                } else {
                    items[itemIndex] = action.data.item;
                }
                const sorted = items.sort((a, b) =>
                    a.created_at > b.created_at ? -1 : b.created_at > a.created_at ? 1 : 0
                );
                draftState.items = sorted;
                draftState.meta = action.data.meta;
                draftState.loading = false;
                draftState.status = true;
                draftState.error = null;
            });
            return nextState;
        }
        case ADD_SHOPPINGBAG_ITEM: {
            const nextState = produce(state, (draftState) => {
                const data = state.items;
                data.push(action.data.item);
                draftState.items = data;
                draftState.error = null;
            });
            return nextState;
        }
        case ADD_TO_SHOPPINGBAG_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                status: false
            };
        case FETCH_SHOPPINGBAG_REQUEST:
            return { ...state, loading: true };
        case FETCH_SHOPPINGBAG_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.data.items,
                meta: action.data.meta
            };
        case FETCH_SHOPPINGBAG_FAILURE:
            return { ...state, loading: false, error: action.error };

        case REMOVE_SHOPPINGBAG_ITEMS:
            const items = state.items.filter((x) => x.id !== action.data.item.id);
            return {
                ...state,
                loading: false,
                items,
                meta: action.data.meta
            };
        case SET_SHOPPINGBAG_STATUS_FALSE:
            return { ...state, status: false };
        case RESET_SHOPPINGBAG:
            return { ...state, error: null };
        default:
            return state;
    }
}

export const actions = {
    request: (
        id,
        companyId,
        hasOptions,
        showShoppingBagModal,
        fetchRelatedProducts,
        isAdditionalSale,
        saleOfferId,
        tipioOfferOptionId,
        price,
        isIceProduct
    ) =>
        action(ADD_TO_SHOPPINGBAG_REQUEST, {
            id,
            companyId,
            hasOptions,
            showShoppingBagModal,
            fetchRelatedProducts,
            isAdditionalSale,
            saleOfferId,
            tipioOfferOptionId,
            price,
            isIceProduct
        }),
    success: (data) => action(ADD_TO_SHOPPINGBAG_SUCCESS, { data }),
    failure: (error) => action(ADD_TO_SHOPPINGBAG_FAILURE, { error }),
    fetchShoppingBag: () => action(FETCH_SHOPPINGBAG_REQUEST),
    updateShoppingBag: (data) => action(REMOVE_SHOPPINGBAG_ITEMS, { data }),
    setStatusFalse: (error) => action(SET_SHOPPINGBAG_STATUS_FALSE),
    addItem: (data) => action(ADD_SHOPPINGBAG_ITEM, { data }),
    resetData: () => action(RESET_SHOPPINGBAG)
};

export const sagas = {
    *request({
        id,
        companyId,
        hasOptions,
        showShoppingBagModal,
        fetchRelatedProducts,
        isAdditionalSale,
        saleOfferId,
        tipioOfferOptionId,
        price,
        isIceProduct
    }) {
        try {
            let payload = {};
            if (isAdditionalSale) {
                payload = { additional_sale_id: id, sale_offer_id: saleOfferId, company_id: companyId };
            } else {
                payload = {
                    tipio_offer_id: id,
                    has_options: hasOptions,
                    company_id: companyId
                };
                if (tipioOfferOptionId) {
                    payload = { ...payload, tipio_offer_option_id: tipioOfferOptionId };
                }
                if (isIceProduct) {
                    payload = { ...payload, ice_product: isIceProduct, price: price };
                }
            }
            const response = yield axios.post('cart', payload);
            yield put({ type: ADD_TO_SHOPPINGBAG_SUCCESS, data: response.data.data });
            if (fetchRelatedProducts) {
                yield put({ type: FETCH_RELATED_OFFERS_REQUEST, tipioOfferId: id });
            }
            yield put(push('/checkout'));
        } catch (error) {
            yield put({ type: ADD_TO_SHOPPINGBAG_FAILURE, error: error.response.data.message });
        }
    },
    *requestFetchShoppingBag() {
        try {
            yield put({ type: FETCH_PROFILE });
            const response = yield axios.get('/cart');
            yield put({ type: FETCH_SHOPPINGBAG_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: FETCH_SHOPPINGBAG_FAILURE, error });
        }
    }
};

export const watcher = function*() {
    yield takeEvery(ADD_TO_SHOPPINGBAG_REQUEST, sagas.request);
    yield takeEvery(FETCH_SHOPPINGBAG_REQUEST, sagas.requestFetchShoppingBag);
};
