import { watcher as updatePasswordWatchers } from './updatePassword';
import { watcher as userAddressWatchers } from './userAddress';
import { watcher as deleteAccountWatchers } from './deleteAccount';
import { watcher as editProfileWatchers } from './editProfile';
import { watcher as updatePictureWatchers } from './updatePicture';
import { watcher as userWatchers } from './profile';
import { watcher as personalDetailsWatchers } from './personalDetails';
import { watcher as user } from './user';

export const profileWatchers = [
    updatePasswordWatchers(),
    userAddressWatchers(),
    deleteAccountWatchers(),
    editProfileWatchers(),
    updatePictureWatchers(),
    userWatchers(),
    personalDetailsWatchers(),
    user()
];
