import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';

export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_ERROR = 'FETCH_ORDER_ERROR';

const initialState = {
    loading: false,
    data: {
        order_addresses: [],
        order_payment_cards: [],
        order_products: []
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_ORDER:
            return { ...state, loading: true, error: null, data: initialState.data };
        case FETCH_ORDER_SUCCESS:
            return { ...state, data: action.data, loading: false };
        case FETCH_ORDER_ERROR:
            return { ...state, error: action.error.toString(), loading: false };
        default:
            return state;
    }
}

export const actions = {
    fetchOrder: (orderId) => ({ type: FETCH_ORDER, id: orderId })
};

export const sagas = {
    fetchOrder: function* fetchOrder(payload) {
        try {
            const response = yield axios.get('/orders/' + payload.id);
            yield put({ type: FETCH_ORDER_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: FETCH_ORDER_ERROR, error });
        }
    }
};

export const watcher = function*() {
    yield takeEvery(FETCH_ORDER, sagas.fetchOrder);
};
