import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import ProtectedRoute from 'components/router/ProtectedRoute';
import AuthRoute from 'components/router/AuthRoute';
import {
    Profile,
    Settings,
    EditProfile,
    DeleteAccountSuccess,
    Orders,
    OrderDetails,
    Login,
    Register,
    Campaign,
    CampaignB,
    ForgotPassword,
    ResetPassword,
    RegisterUser,
    UserNotVerified,
    UserVerify,
    EmailVerify,
    SocialAccountNotConnected,
    SubmitLinkForm,
    SelectImages,
    Publish,
    CreateTipio,
    Search,
    SocialAuth,
    About,
    Frontpage,
    NewGoogleAccount,
    Tipios,
    ViewRestoreTipio,
    ViewAllVotings,
    ViewAllBindings,
    TipioVote,
    TipioVoteEdit,
    TipioBuy,
    LoginOrRegisterModal,
    TipioExists,
    TipioBind,
    ViewAdditionalSalePage,
    ViewTipio,
    Checkout,
    Cart,
    RestoreOffer,
    ParserSiteRules,
    NotFound,
    StripeCheckout,
    IceCheckout,
    SplitPage,
    CheckoutCompleted,
    PurchaseWithoutAuth
} from 'pages';

export default function() {
    return (
        <>
            <Switch>
                <Route exact path="/" component={Frontpage} />
                <AuthRoute exact path="/auth/login" component={Login} />
                <AuthRoute exact key="auth" path="/auth" component={LoginOrRegisterModal} />
                <AuthRoute exact path="/user/create" component={Register} />
                <AuthRoute exact path="/konkurranse" component={Campaign} />
                <AuthRoute exact path="/konkurranser" component={CampaignB} />
                <AuthRoute exact path="/password/email" component={ForgotPassword} />
                <Route exact path="/password/reset" component={ResetPassword} />
                <Route exact key="search" path="/search/page/:page" component={Search} />
                <Route
                    exact
                    key="socialAccountNotFound"
                    path="/social-account-notfound"
                    component={SocialAccountNotConnected}
                />
                <Route exact key="socialAuth" path="/social/auth" component={SocialAuth} />
                <Route exact key="verify" path="/user/verify" component={UserVerify} />
                <Route exact key="verify" path="/user/email-verify" component={EmailVerify} />
                <ProtectedRoute exact key="userNotVerified" path="/user/not-verified" component={UserNotVerified} />
                <AuthRoute exact key="userPersonalDetails" path="/user/personaldetails" component={RegisterUser} />
                <Route exact path="/all-votings/page/:page" component={ViewAllVotings} />
                <Route exact path="/all-binding/page/:page" component={ViewAllBindings} />
                <Route exact path="/tipio/bind/:id" component={withRouter(TipioBind)} />
                <Route exact path="/tipio/vote/:id" component={withRouter(TipioVote)} />
                <Route exact path="/auth" component={withRouter(TipioVote)} />
                <ProtectedRoute exact path="/profile" component={Profile} />
                <ProtectedRoute exact path="/profile/edit" component={EditProfile} />
                <ProtectedRoute exact path="/profile/delete/success" component={DeleteAccountSuccess} />
                <ProtectedRoute exact path="/settings" component={Settings} />
                <ProtectedRoute exact path="/user/orders" component={Orders} />
                <ProtectedRoute exact path="/user/orders/:orderId" component={OrderDetails} />
                <ProtectedRoute exact path="/tipios" component={Tipios} />
                <ProtectedRoute exact path="/tipio/create" component={CreateTipio} />
                <ProtectedRoute exact path="/tipio/new" component={SubmitLinkForm} />
                <ProtectedRoute exact path="/tipio/new/select-images" component={SelectImages} />
                <ProtectedRoute exact path="/tipio/new/complete" component={Publish} />
                <ProtectedRoute exact path="/tipio/view/:id" component={ViewRestoreTipio} />
                <ProtectedRoute exact path="/tipio/restore/:id" component={ViewTipio} />
                <ProtectedRoute exact path="/tipio/bind/restore/:id" component={RestoreOffer} />
                <ProtectedRoute exact path="/tipio/vote/:id/edit" component={withRouter(TipioVoteEdit)} />
                <ProtectedRoute exact path="/tipio/buy/:id" component={withRouter(TipioBuy)} />
                <Route exact path="/additional-sale/view/:id/:referingOfferId" component={ViewAdditionalSalePage} />
                <ProtectedRoute exact path="/tipio/exists/:id" component={withRouter(TipioExists)} />
                <Route exact path="/cart" component={PurchaseWithoutAuth} />
                <ProtectedRoute exact path="/checkout" component={Checkout} />
                <ProtectedRoute exact path="/checkout/active" component={Cart} />
                <ProtectedRoute exact path="/ice/checkout" component={IceCheckout} />
                <ProtectedRoute exact path="/stripe/checkout" component={StripeCheckout} />
                <ProtectedRoute exact path="/parser/site-rules" component={ParserSiteRules} />
                <ProtectedRoute exact path="/checkout/completed" component={CheckoutCompleted} />
                <Route exact path="/about" component={About} />
                <Route exact path="/frontpage/frontpage" component={Frontpage} />
                <Route exact path="/temp/newgoogleaccount" component={NewGoogleAccount} />
                <Route exact path="/temp/split" component={SplitPage} />
                <Route component={NotFound} />
            </Switch>
        </>
    );
}
