import decode from 'jwt-decode';
import subscribeToTokenChange from '../../events/subscribeToTokenChange';

export default (existingToken, { authActions, dispatch }) => {
    const decoded = decode(existingToken);
    const { id, exp } = decoded;

    const now = Date.now() / 1000;
    if (exp < now) {
        return;
    }

    subscribeToTokenChange(id, (data) => {
        dispatch(authActions.updateToken(data.signed.token));
    });
};
