import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import history from 'utils/history';
import { setupAxios } from 'utils/axios';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { ContentSpinner } from 'tipio-app-core';
import { configureStore } from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'initializers/ga';
import 'initializers/hotjar';
import 'initializers/i18n';
import 'initializers/toast';
import { BugsnagErrorBoundary } from './utils/bugsnag';
import { splitSdkConfig } from './utils/FetchSplitKey';

const initState = {};
const store = configureStore(history, initState);
setupAxios(store);

ReactDOM.render(
    <SplitFactory config={splitSdkConfig()} updateOnSdkTimedout>
        <BugsnagErrorBoundary>
            <Suspense fallback={<ContentSpinner />}>
                <Provider store={store}>
                    <App />
                </Provider>
            </Suspense>
        </BugsnagErrorBoundary>
    </SplitFactory>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
