import React, { useState, FC, KeyboardEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useL10N from 'hooks/useL10N';
import SearchIcon from 'static/assets/images/search.svg';
import { actions as authActions } from 'sagas/app/auth/auth';
import { actions as uiActions } from 'sagas/app/ui';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
// @ts-ignore
import { Overlay, Logo } from 'tipio-app-core';
import withL10N from 'hoc/withL10N';
import mixpanel from 'mixpanel-browser';
import encode from 'utils/search/encode';
import { DropDown, DropDownItem } from './Dropdown/DropDown';
import SearchNav from './SearchNav';

const Navigation: FC<RouteComponentProps> = (props) => {
    const [addOverlay, setAddOverlay] = useState(false);
    const dispatch = useDispatch();
    const boundUiActions = bindActionCreators(uiActions, dispatch);
    const { ui, auth, notification } = useSelector((state: RootStateOrAny) => state.app);
    const { t } = useL10N();
    const logoutOnClick = () => {
        boundUiActions.hideNavDropdowns();
        mixpanel.reset();
        dispatch(authActions.logout());
    };

    const searchHandleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            boundUiActions.hideNavDropdowns();
            setAddOverlay(false);
            const url = `/search/page/1${
                !!(e.target as HTMLTextAreaElement).value ? `?q=${encode((e.target as HTMLTextAreaElement).value)}` : ''
            }`;
            props.history.push(url);
        }
    };

    const searchSuggestionItemOnClick = (query: String) => {
        boundUiActions.hideNavDropdowns();
        const url = `/search/page/1${!!query ? `?q=${encode(query)}` : ''}`;
        props.history.push(url);
    };

    const searchButtonOnClick = () => {
        boundUiActions.toggleSearch();
        if (props.location.pathname.indexOf('/search') === 0) {
            props.history.push(`/search/page/1`);
        }
    };

    const _onOverlayClick = () => {
        boundUiActions.hideNavDropdowns();
        setAddOverlay(false);
    };
    return (
        <>
            <nav className="navigation">
                <div className="container">
                    <div className="nav-wrapper">
                        <a className="logo mr-5" href="/">
                            <Logo />
                        </a>

                        {/* Visible only on lg */}
                        <ul className="nav-list d-none d-md-block">
                            <li className="nav-list-item">
                                <button className="link-button nav-list-link nav-list-lg-link active">
                                    {t('private.menu.private')}
                                </button>
                            </li>
                            <li className="nav-list-item">
                                <a className="nav-list-link nav-list-lg-link" href="https://bedrift.tipio.no">
                                    {t('private.menu.company')}
                                </a>
                            </li>
                        </ul>

                        {/* Visible only on lg */}
                        <ul className="nav-list ml-auto">
                            <li className="nav-list-item mb-1">
                                {/* search link */}
                                <button
                                    className="link-button nav-list-link"
                                    onClick={(e) => {
                                        // e.preventDefault();
                                        boundUiActions.toggleSearch();
                                    }}
                                >
                                    <img src={SearchIcon} alt="" width="20" height="20" />
                                </button>

                                {/* Search dropdown */}
                                {ui.searchVisible && (
                                    <SearchNav
                                        searchButtonOnClick={searchButtonOnClick}
                                        searchHandleKeyDown={searchHandleKeyDown}
                                        searchSuggestionItemOnClick={searchSuggestionItemOnClick}
                                        addOverlay={addOverlay}
                                        setAddOverlay={setAddOverlay}
                                    />
                                )}
                            </li>

                            <li className="nav-list-item">
                                <div className="position-relative">
                                    <button
                                        className={`link-button hamburger-button ${ui.menuVisible ? 'show' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            boundUiActions.toggleMenu();
                                        }}
                                    >
                                        {t('private.menu.title')}
                                    </button>
                                    {/* Removed notificaton for prod */}
                                    {/* {!ui.menuVisible && !!notification.total && (
                                        <div className="nav-list-link-menu-badge">
                                            <NavBadge count={notification.total} />
                                        </div>
                                    )} */}
                                </div>

                                {ui.menuVisible && (
                                    <div className="menu-content show">
                                        <div className="tab-content">
                                            <ul>
                                                <li>
                                                    <Link
                                                        to={'/tipio/new'}
                                                        className="add-new"
                                                        onClick={boundUiActions.hideNavDropdowns}
                                                    >
                                                        <p>
                                                            <svg width={35} height={35} viewBox="0 0 35 35">
                                                                <g fill="none" fillRule="evenodd">
                                                                    <circle
                                                                        fill="#4ABCAC"
                                                                        cx={17.28}
                                                                        cy={17.28}
                                                                        r={17.28}
                                                                    />
                                                                    <path
                                                                        d="M17.06 10a.89.89 0 01.89.89v5.279h5.288a.89.89 0 010 1.781H17.95v5.288a.89.89 0 01-1.78 0l-.001-5.288H10.89a.89.89 0 010-1.78l5.279-.001V10.89a.89.89 0 01.891-.89z"
                                                                        fill="#381B81"
                                                                        fillRule="nonzero"
                                                                    />
                                                                </g>
                                                            </svg>
                                                            <span>{t('private.menu.addTipio')}</span>
                                                        </p>
                                                    </Link>
                                                </li>

                                                {auth.isAuthenticated && (
                                                    <li>
                                                        <DropDown
                                                            title={t('private.menu.myTipio')}
                                                            badgeCount={notification && notification.total}
                                                        >
                                                            <DropDownItem>
                                                                <Link
                                                                    to="/tipios?page=1&filter=voting-tipios-private"
                                                                    onClick={boundUiActions.hideNavDropdowns}
                                                                >
                                                                    {t('private.menu.myTipioInterested')}
                                                                    {/* {notification &&
                                                                            notification.votingTipiosCount > 0 && (
                                                                                <span className="subNav-badge">
                                                                                    <NavBadge
                                                                                        count={notification.votingTipiosCount}
                                                                                    />
                                                                                </span>
                                                                            )} */}
                                                                </Link>
                                                            </DropDownItem>
                                                            <DropDownItem>
                                                                <Link
                                                                    to="/tipios?page=1&filter=received-offers"
                                                                    onClick={boundUiActions.hideNavDropdowns}
                                                                >
                                                                    <span>
                                                                        {t('private.menu.recivedOffers')}
                                                                        {/* Removed for production */}
                                                                        {/* {notification &&
                                                                            notification.receivedOffersCount > 0 && (
                                                                                <span className="subNav-badge">
                                                                                    <NavBadge
                                                                                        count={
                                                                                            notification.receivedOffersCount
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            )} */}
                                                                    </span>
                                                                </Link>
                                                            </DropDownItem>
                                                            <DropDownItem>
                                                                <Link
                                                                    to="/tipios?page=1&filter=tipio-binding-page"
                                                                    onClick={boundUiActions.hideNavDropdowns}
                                                                >
                                                                    {t('private.menu.orders')}
                                                                </Link>
                                                            </DropDownItem>
                                                        </DropDown>
                                                    </li>
                                                )}
                                                {auth.isAuthenticated && (
                                                    <li>
                                                        <DropDown title={t('private.menu.myAccount')}>
                                                            <DropDownItem>
                                                                <Link
                                                                    to={'/user/orders'}
                                                                    onClick={boundUiActions.hideNavDropdowns}
                                                                >
                                                                    {t('private.menu.myOrders')}
                                                                </Link>
                                                            </DropDownItem>
                                                            <DropDownItem>
                                                                <Link
                                                                    to={'/profile'}
                                                                    onClick={boundUiActions.hideNavDropdowns}
                                                                >
                                                                    {t('private.menu.myProfile')}
                                                                </Link>
                                                            </DropDownItem>
                                                            <DropDownItem>
                                                                <Link
                                                                    to={'/settings'}
                                                                    onClick={boundUiActions.hideNavDropdowns}
                                                                >
                                                                    {t('private.menu.settings')}
                                                                </Link>
                                                            </DropDownItem>
                                                        </DropDown>
                                                    </li>
                                                )}
                                                <li>
                                                    <Link to="/about" onClick={boundUiActions.hideNavDropdowns}>
                                                        {t('private.menu.about')}
                                                    </Link>
                                                </li>
                                                {!auth.isAuthenticated && (
                                                    <>
                                                        <li></li>
                                                        <li>
                                                            <a href="/user/create">{t('private.menu.register')}</a>
                                                        </li>
                                                        <li>
                                                            <a href="/auth/login">{t('private.menu.login')}</a>
                                                        </li>
                                                    </>
                                                )}
                                                {auth.isAuthenticated && (
                                                    <li className="mt-5">
                                                        <a href="/" onClick={logoutOnClick}>
                                                            {t('private.menu.logout')}
                                                        </a>
                                                    </li>
                                                )}
                                                <li>
                                                    <a href="https://bedrift.tipio.no">
                                                        {t('private.menu.goToCompany')}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
                {ui.navOverlayVisible && (
                    <Overlay onClick={_onOverlayClick} opacity={ui.searchVisible && !addOverlay ? 0 : 0.5} top="88px" />
                )}
            </nav>
        </>
    );
};

export default withRouter(withL10N(Navigation));
