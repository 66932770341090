import { put, takeEvery } from 'redux-saga/effects';
import axios from 'utils/axios';
import action from 'utils/action-creator';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR';
export const USERADDRESS_CREATED_SUCCESS = 'USERADDRESS_CREATED_SUCCESS';
export const USERPAYMENTCARD_CREATED_SUCCESS = 'USERPAYMENTCARD_CREATED_SUCCESS';

export const DELETE_SHIPPING_ADDRESS = 'profile/DELETE_SHIPPING_ADDRESS';
export const DELETE_SHIPPING_ADDRESS_SUCCESS = 'profile/DELETE_SHIPPING_ADDRESS_SUCCESS';
export const DELETE_SHIPPING_ADDRESS_FAILURE = 'profile/DELETE_SHIPPING_ADDRESS_FAILURE';

export const INITIALIZE_EMPTY_SHIPPING_ADDRESS = 'profile/INITIALIZE_EMPTY_SHIPPING_ADDRESS';
export const DELETE_EMPTY_SHIPPING_ADDRESS = 'profile/DELETE_EMPTY_SHIPPING_ADDRESS';
export const UPDATE_PROFILE_PICTURE = '@app/profile/updataPicture/UPDATE_PROFILE_PICTURE';

const initState = {
    loading: true,
    user_addresses: [],
    user_payment_cards: []
};

export default function(state = initState, action) {
    switch (action.type) {
        case FETCH_PROFILE:
            return { ...state, loading: true, error: null };
        case FETCH_PROFILE_SUCCESS:
            return { ...state, ...action.data, loading: false };
        case FETCH_PROFILE_ERROR:
            return { ...state, error: action.error.toString(), loading: false };
        case UPDATE_PROFILE_PICTURE:
            return { ...state, profile_picture: action.data };
        case USERADDRESS_CREATED_SUCCESS: {
            const userAdresses = state.user_addresses.filter((x) => x.id);
            const index = userAdresses.findIndex((e) => e.id === action.data.id);
            if (index === -1) {
                userAdresses.push(action.data);
            } else {
                userAdresses[index] = action.data;
            }
            return { ...state, user_addresses: userAdresses, loading: false };
        }
        case USERPAYMENTCARD_CREATED_SUCCESS: {
            const userPaymentCards = state.user_payment_cards;
            const index = userPaymentCards.findIndex((e) => e.id === action.data.id);
            if (index === -1) {
                userPaymentCards.push(action.data);
            } else {
                userPaymentCards[index] = action.data;
            }
            return { ...state, user_payment_cards: userPaymentCards, loading: false };
        }
        case DELETE_SHIPPING_ADDRESS_SUCCESS: {
            const userAdresses = state.user_addresses;
            const userAdressesUpdated = userAdresses.filter((e) => e.id !== action.data.id && e.type !== 2);
            return { ...state, user_addresses: userAdressesUpdated, loading: false };
        }
        case DELETE_SHIPPING_ADDRESS:
            return { ...state, loading: true };
        case DELETE_SHIPPING_ADDRESS_FAILURE:
            return { ...state, error: action.error.toString(), loading: false };
        case INITIALIZE_EMPTY_SHIPPING_ADDRESS: {
            const userAdresses = state.user_addresses;
            userAdresses.push({ type: 2 });
            return { ...state, user_addresses: userAdresses };
        }
        case DELETE_EMPTY_SHIPPING_ADDRESS: {
            const userAdresses = state.user_addresses.filter((e) => e.type !== 2);
            return { ...state, user_addresses: userAdresses };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchProfile: () => ({ type: FETCH_PROFILE }),
    fetchProfileSuccess: (data) => action(FETCH_PROFILE_SUCCESS, { data }),
    fetchProfileFailure: (error) => action(FETCH_PROFILE_ERROR, { error }),
    updateProfilePicture: (data) => action(UPDATE_PROFILE_PICTURE, { data }),
    userAddressCreated: (data) => action(USERADDRESS_CREATED_SUCCESS, { data }),
    userPaymentCardCreated: (data) => action(USERPAYMENTCARD_CREATED_SUCCESS, { data }),
    deleteShippingAddress: (data) => action(DELETE_SHIPPING_ADDRESS, { data }),
    initializeEmptyShippingAddress: () => action(INITIALIZE_EMPTY_SHIPPING_ADDRESS),
    deleteEmptyShippingAddress: () => action(DELETE_EMPTY_SHIPPING_ADDRESS)
};

export const watcher = function*() {
    yield takeEvery(FETCH_PROFILE, sagas.fetchProfile);
    yield takeEvery(DELETE_SHIPPING_ADDRESS, sagas.deleteShippingAddress);
};

export const sagas = {
    fetchProfile: function* fetchUserSaga(payload) {
        try {
            const response = yield axios.get('/users', payload);
            yield put({ type: FETCH_PROFILE_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: FETCH_PROFILE_ERROR, error });
        }
    },
    deleteShippingAddress: function* deleteShippingAddress(payload) {
        try {
            const response = yield axios.delete('/user-addresses/' + payload.data);
            yield put({ type: DELETE_SHIPPING_ADDRESS_SUCCESS, data: response.data.data });
        } catch (error) {
            yield put({ type: DELETE_SHIPPING_ADDRESS_FAILURE, error });
        }
    }
};
