import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';
import history from 'utils/history';
import { CREATE_TIPIO_INIT } from './create-tipio';

export const REQUEST = '@app/tipio/submitLink/REQUEST';
export const SUCCESS = '@app/tipio/submitLink/SUCCESS';
export const ERROR = '@app/tipio/submitLink/ERROR';
export const CLEAN_UP = '@app/tipio/submitLink/CLEAN_UP';

const _state = {
    error: null,
    isSubmitting: false,
    success: false
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REQUEST:
                return { ...state, isSubmitting: true };
            case SUCCESS:
                draft.success = true;
                draft.isSubmitting = false;
                break;
            case ERROR:
                draft.error = action.payload;
                draft.isSubmitting = false;
                draft.success = false;
                break;
            case CLEAN_UP:
                return { isSubmitting: false, success: false, error: null };
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    request: (payload) => createAction(REQUEST, { payload }),
    cleanUp: (payload) => createAction(CLEAN_UP, { payload })
};

export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.get('/parse/' + encodeURIComponent(payload));
            yield put(createAction(SUCCESS, { payload: response.data.data }));
            yield put(createAction(CREATE_TIPIO_INIT, { data: response.data.data }));
            history.push('new/select-images');
        } catch (error) {
            yield put(createAction(ERROR, { payload: error }));
        }
    }
};

export const watcher = function* w() {
    yield takeLatest(REQUEST, sagas.request);
};
