import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'utils/history';

import auth from 'sagas/app/auth/auth';
import forgotPassword from 'sagas/app/auth/forgotPassword';
import resetPassword from 'sagas/app/auth/resetPassword';
import register from 'sagas/app/auth/register';
import personalDetails from 'sagas/app/profile/personalDetails';
import users from 'sagas/app/profile/user';
import createTipio from 'sagas/app/tipio/create/create-tipio';
import favorite from 'sagas/app/favorite';
import editTipio from 'sagas/app/tipio/editTipio';
import profile from 'sagas/app/profile/profile';
import updatePassword from 'sagas/app/profile/updatePassword';
import editProfile from 'sagas/app/profile/editProfile';
import order from 'sagas/app/orders/order';
import orderDetail from 'sagas/app/orders/orderDetail';
import tipioView from 'sagas/app/tipio/tipioView';
import cart from 'sagas/app/checkout/cart';
import checkout from 'sagas/app/checkout/checkout';
import purchseWithoutAuth from 'sagas/app/checkout/purchaseWithoutAuth';
import ui from 'sagas/app/ui';
import iceCheckout from 'sagas/app/checkout/iceCheckout';
import tipioOffer from 'sagas/app/tipio/tipioOffer';
import tipioJoin from 'sagas/app/tipio/tipioJoin';
import tipioRestore from 'sagas/app/tipio/tipioRestore';
import categories from 'sagas/app/categories/index';
import tipioStatus from 'sagas/app/tipio/tipioStatus';
import frontpage from 'sagas/app/frontpage/frontpage';
import tipioBind from 'sagas/app/tipioBind/tipioBind';
import tipio from 'sagas/app/tipio/tipio';
import notification from 'sagas/app/notification';
import saveTipio from 'sagas/app/tipio/create/saveTipio';
import saveTipioWithImages from 'sagas/app/tipio/create/saveTipioWithImages';
import cartAdditionalSales from 'sagas/app/checkout/cartAdditionalSales';
import deleteAccount from 'sagas/app/profile/deleteAccount';
import additionalSale from 'sagas/app/additionalSale';
import scrollManager from 'sagas/app/scrollManager';
import reportTipio from 'sagas/app/tipio/reportTipio';
import viewAllVotings from 'sagas/app/viewAllTipio/viewAllVotings';
import viewAllBindings from 'sagas/app/viewAllTipio/viewAllBindings';
import settings from 'sagas/app/settings/settings';
import filterCategories from 'sagas/app/categories/filterCategories';
import completeUser from 'sagas/app/user/completePassword';
import parserSiteRulesGet from 'sagas/app/parserSiteRules/get';
import parserSiteRulesPost from 'sagas/app/parserSiteRules/post';
import parserSiteRulesPatch from 'sagas/app/parserSiteRules/patch';
import parserSiteRulesParse from 'sagas/app/parserSiteRules/parse';
import tipioBindRestore from 'sagas/app/tipioBind/tipioBindRestore';
import search from 'sagas/app/search';
import searchMeta from 'sagas/app/search/meta';
import searchBindingMeta from 'sagas/app/search/bindingMeta';
import shoppingBag from 'sagas/app/shoppingBag/shoppingBag';
import klarnaCheckout from 'sagas/app/klarna';
import klarnaConfirmation from 'sagas/app/klarna/confirmation';
import klarnaComplete from 'sagas/app/klarna/complete';
import stripeCheckout from 'sagas/app/stripe';
import tipios from 'sagas/app/tipios';
import userAddress from 'sagas/app/profile/userAddress';
import updatePicture from 'sagas/app/profile/updatePicture';
import submitLink from 'sagas/app/tipio/create/submitLink';
import socialAuth from 'sagas/app/auth/socials';
import userVerify from 'sagas/app/user/verifyUser';
import resendVerifyUser from 'sagas/app/user/resendVerifyUser';
import coupons from 'sagas/app/checkout/coupons';

export default function createReducer(injectedReducers = {}) {
    const rootReducer = combineReducers({
        app: combineReducers({
            auth,
            forgotPassword,
            resetPassword,
            coupons,
            register,
            personalDetails,
            users,
            createTipio,
            submitLink,
            favorite,
            editTipio,
            profile,
            updatePassword,
            editProfile,
            order,
            orderDetail,
            tipioView,
            cart,
            checkout,
            shoppingBag,
            ui,
            tipioOffer,
            tipioJoin,
            tipioRestore,
            tipioBindRestore,
            categories,
            tipioStatus,
            frontpage,
            tipioBind,
            tipio,
            notification,
            saveTipio,
            saveTipioWithImages,
            cartAdditionalSales,
            deleteAccount,
            additionalSale,
            scrollManager,
            reportTipio,
            viewAllVotings,
            viewAllBindings,
            settings,
            completeUser,
            search,
            searchMeta,
            searchBindingMeta,
            filterCategories,
            parserSiteRulesGet,
            parserSiteRulesPatch,
            parserSiteRulesPost,
            parserSiteRulesParse,
            klarnaCheckout,
            klarnaConfirmation,
            klarnaComplete,
            stripeCheckout,
            tipios,
            userAddress,
            updatePicture,
            socialAuth,
            userVerify,
            resendVerifyUser,
            iceCheckout,
            purchseWithoutAuth,
            ...injectedReducers
        })
    });

    // Wrap the root reducer and return a new root reducer with router state
    const mergeWithRouterState = connectRouter(history);
    return mergeWithRouterState(rootReducer);
}
