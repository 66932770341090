import produce from 'immer';

export const SAVE_SCROLL_POSITION = '@app/scrollManager/SAVE_SCROLL_POSITION';

export const memoryStore = {
    _data: new Map(),
    get(key) {
      if (!key) {
        return null
      }
  
      return this._data.get(key) || null
    },
    set(key, data) {
      if (!key) {
        return
      }
      return this._data.set(key, data)
    }
  }

const _state = {
    data: memoryStore,
};

export default (state = _state, action) => (
    produce(state, draft => {
        switch (action.type) {
        case SAVE_SCROLL_POSITION: {
            const { key, scrollPosition } = action.paylod;
            draft.data.set(key, scrollPosition);
            break;
        }
        default:
            break;
        }
    })
);

export const actions = {
    saveScrollPosition: (key, scrollPosition) => ({ type: SAVE_SCROLL_POSITION, paylod: { key, scrollPosition } }),
};
