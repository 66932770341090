import React, { FC } from 'react';
import Header from './Header';
// @ts-ignore
import { Footer } from 'tipio-app-core';

const Layout: FC = (props) => {
    const isCardPath = window.location.pathname !== '/cart';
    return (
        <>
            <Header />
            {/* Content start */}
            <main className="main">{props.children}</main>

            {/* Content end */}
            {isCardPath && <Footer />}
        </>
    );
};

export default Layout;
