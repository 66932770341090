import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, location, ...rest }) => {
    const { isAuthenticated } = useSelector((state) => state.app.auth);

    if (!isAuthenticated) return <Redirect to={'/auth?redirect=' + location.pathname} />;

    return <Route {...rest} component={Component} />;
};

export default ProtectedRoute;
