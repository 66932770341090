import openSocket from 'socket.io-client';
import { API_URL } from '../config';

const socket = openSocket(API_URL, { transports: ['websocket'] });

function subscribeToTokenChange(userId, cb) {
    socket.on(`tokenChanged_${userId}`, cb);
}

export default subscribeToTokenChange;
