import React, { useState, FC, MouseEvent } from 'react';
// @ts-ignore
import cx from 'classnames';
// @ts-ignore
// import { NavBadge } from 'tipio-app-core';

type dropDownType = {
    title: string;
    afterTitle?: string;
    badgeCount?: string;
};
export const DropDown: FC<dropDownType> = ({ title, badgeCount, children }) => {
    const [dropDownVisibility, setDropDownVisibility] = useState(false);
    return (
        <>
            <div
                className="dropdown-toggle mb-2"
                onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    setDropDownVisibility(!dropDownVisibility);
                }}
            >
                {/* eslint-disable-next-line react/prop-types */}
                <span>
                    {title}
                    {/* Removed notificaton for prod */}
                    {/* {!!badgeCount && (
                        <span className="subNav-badge">
                            <NavBadge count={badgeCount} />
                        </span>
                    )} */}
                </span>

                {/* eslint-disable-next-line react/prop-types */}
                <svg
                    className={cx({ downArrow: dropDownVisibility })}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                >
                    <g
                        className="nc-icon-wrapper"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        fill="#ffffff"
                        stroke="#ffffff"
                    >
                        <polyline
                            fill="none"
                            stroke="#ffffff"
                            strokeMiterlimit="10"
                            points="2,7 12,17 22,7 "
                            transform="translate(0, 0)"
                        />
                    </g>
                </svg>
            </div>
            <ul className={`dropdown-list ${dropDownVisibility ? 'show' : 'd-none'}`}>
                {React.Children.map(children, (child) => {
                    return child;
                })}
            </ul>
        </>
    );
};

export const DropDownItem: FC = (props) => {
    return <li>{props.children}</li>;
};
