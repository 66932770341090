import { put, takeEvery } from 'redux-saga/effects';
import produce from 'immer';
import axios from 'utils/axios';
import { actions as uiActions } from '../ui';
import { actions as authActions } from 'sagas/app/auth/auth';

export const USER_RESEND_PASSWORD_REQUEST = '@app/user/password/VERIFY_REQUEST';
export const USER_RESEND_PASSWORD_SUCCESS = '@app/user/password/VERIFY_REQUEST_SUCCESS';
export const USER_RESEND_PASSWORD_ERROR = '@app/user/password/VERIFY_REQUEST_ERROR';
export const USER_RESEND_PASSWORD_CLEAR = '@app/user/password/VERIFY_REQUEST_CLEAR';

const _state = {
    loading: false,
    error: null,
    success: false
};

export default (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case USER_RESEND_PASSWORD_REQUEST: {
                draft.loading = true;
                break;
            }
            case USER_RESEND_PASSWORD_SUCCESS: {
                draft.loading = false;
                draft.success = true;
                break;
            }
            case USER_RESEND_PASSWORD_ERROR: {
                draft.loading = false;
                draft.error = action.payload;
                break;
            }
            case USER_RESEND_PASSWORD_CLEAR:
                return _state;
            default:
                break;
        }
    });

export const actions = {
    resendUserPassword: (payload) => ({ type: USER_RESEND_PASSWORD_REQUEST, payload }),
    clearData: () => ({ type: USER_RESEND_PASSWORD_CLEAR })
};

export const sagas = {
    *resendUserPassword({ payload }) {
        try {
            const response = yield axios.patch('/temp-users/complete-password', payload);
            yield put({ type: USER_RESEND_PASSWORD_SUCCESS });
            yield put(uiActions.toogleNotCompletedUser(false));
            yield put(authActions.success(response.data.data.token, response.data.data.user));
        } catch (error) {
            yield put({ type: USER_RESEND_PASSWORD_ERROR, error });
        }
    }
};

export const watcher = function*() {
    yield takeEvery(USER_RESEND_PASSWORD_REQUEST, sagas.resendUserPassword);
};
