import React, { FC } from 'react';
import Navigation from '../components/Navigation';

const Header: FC = () => {
    return (
        <header className="header">
            <Navigation />
        </header>
    );
};

export default Header;
