import { put, takeEvery } from 'redux-saga/effects';
import action from 'utils/action-creator';
import axios from 'utils/axios';

export const REQUEST = '@app/auth/resetPassword/REQUEST';
export const SUCCESS = '@app/auth/resetPassword/SUCCESS';
export const FAILURE = '@app/auth/resetPassword/FAILURE';
export const CLEAR = '@app/auth/resetPassword/CLEAR';

export default function(state = {}, { type, payload }) {
    switch (type) {
        case REQUEST:
            return { loading: true };
        case SUCCESS:
            return { success: true };
        case FAILURE:
            return { error: payload, success: false };
        case CLEAR:
            return {};
        default:
            return state;
    }
}

export const actions = {
    request: (payload) => action(REQUEST, { payload }),
    success: () => action(SUCCESS),
    failure: (payload) => action(FAILURE, { payload }),
    clear: () => action(CLEAR)
};

export const sagas = {
    *request({ payload }) {
        try {
            yield axios.post('/password/reset', payload);
            yield put(actions.success());
        } catch (error) {
            if (error.response) {
                if (error.response.status === 422 || error.response.status === 404 || error.status === 500) {
                    yield put(actions.failure(error.response.data.message));
                } else {
                    yield put(actions.failure(error.toString()));
                }
            } else {
                yield put(actions.failure(error.toString()));
            }
        }
    }
};

export const watcher = function*() {
    yield takeEvery(REQUEST, sagas.request);
};
