import { put, takeEvery } from 'redux-saga/effects';
import produce from 'immer';
import axios from 'utils/axios';

export const USER_RESEND_VERIFY_REQUEST = '@app/user/resend/VERIFY_REQUEST';
export const USER_RESEND_VERIFY_SUCCESS = '@app/user/resend/VERIFY_REQUEST_SUCCESS';
export const USER_RESEND_VERIFY_ERROR = '@app/user/resend/VERIFY_REQUEST_ERROR';
export const USER_RESEND_VERIFY_CLEAR = '@app/user/resend/VERIFY_REQUEST_CLEAR';

const _state = {
    loading: false,
    error: null,
    success: false
};

export default (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case USER_RESEND_VERIFY_REQUEST: {
                draft.loading = true;
                break;
            }
            case USER_RESEND_VERIFY_SUCCESS: {
                draft.loading = false;
                draft.success = true;
                break;
            }
            case USER_RESEND_VERIFY_ERROR: {
                draft.loading = false;
                draft.error = action.payload;
                break;
            }
            case USER_RESEND_VERIFY_CLEAR:
                return _state;
            default:
                break;
        }
    });

export const actions = {
    resendUserVerify: (payload) => ({ type: USER_RESEND_VERIFY_REQUEST, payload }),
    clearData: () => ({ type: USER_RESEND_VERIFY_CLEAR })
};

export const sagas = {
    *resendUserVerify({ payload }) {
        try {
            yield axios.post('/email/verify/resend', payload);
            yield put({ type: USER_RESEND_VERIFY_SUCCESS });
        } catch (error) {
            yield put({ type: USER_RESEND_VERIFY_ERROR, error });
        }
    }
};

export const watcher = function*() {
    yield takeEvery(USER_RESEND_VERIFY_REQUEST, sagas.resendUserVerify);
};
