import { put, takeEvery } from 'redux-saga/effects';
import produce from 'immer';
import action from 'utils/action-creator';
import getData from 'utils/purchaseWithoutAuth/getData';
import getAdditionalSaleData from 'utils/purchaseWithoutAuth/getAdditionalSaleData';
import history from 'utils/history';
import axios from 'utils/axios';
import { actions as authActions } from 'sagas/app/auth/auth';

export const ADD_TO_CART_REQUEST = 'cart/Without/Auth/ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'cart/Without/Auth/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'cart/Without/Auth/ADD_TO_CART_FAILURE';

export const ADD_TO_CART_ADDSALE_REQUEST = 'cart/Without/Auth/ADD_TO_CART_ADDSALE_REQUEST';
export const ADD_TO_CART_ADDSALE_SUCCESS = 'cart/Without/Auth/ADD_TO_CART_ADDSALE_SUCCESS';
export const ADD_TO_CART_ADDSALE_FAILURE = 'cart/Without/Auth/ADD_TO_CART_ADDSALE_FAILURE';

export const FETCH_CART_REQUEST = 'cart/Without/Auth/FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'cart/Without/Auth/FETCH_CART_SUCCESS';
export const FETCH_CART_FAILURE = 'cart/Without/Auth/FETCH_CART_FAILURE';

export const REMOVE_FROM_CART_REQUEST = 'cart/Without/Auth/REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS = 'cart/Without/Auth/REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_FAILURE = 'cart/Without/Auth/REMOVE_FROM_CART_FAILURE';

export const COMPLETE_ICE_REQUEST = 'cart/Without/Auth/COMPLETE_ICE_REQUEST';
export const COMPLETE_ICE_SUCCES = 'cart/Without/Auth/COMPLETE_ICE_SUCCES';
export const COMPLETE_ICE_FAILURE = 'cart/Without/Auth/COMPLETE_ICE_FAILURE';

export const COMPLETE_CART_REQUEST = 'cart/Without/Auth/COMPLETE_CART_REQUEST';
export const COMPLETE_CART_SUCCES = 'cart/Without/Auth/COMPLETE_CART_SUCCES';
export const COMPLETE_CART_FAILURE = 'cart/Without/Auth/COMPLETE_CART_FAILURE';

export const UPDATE_QUANTITY_REQUEST = 'cart/Without/Auth/UPDATE_QUANTITY_REQUEST';
export const UPDATE_QUANTITY_SUCCESS = 'cart/Without/Auth/UPDATE_QUANTITY_SUCCESS';
export const UPDATE_QUANTITY_FAILURE = 'cart/Without/Auth/UPDATE_QUANTITY_FAILURE';

export const UPDATE_OPTION_REQUEST = 'cart/Without/Auth/UPDATE_OPTION_REQUEST';
export const UPDATE_OPTION_SUCCES = 'cart/Without/Auth/UPDATE_OPTION_SUCCES';
export const UPDATE_OPTION_FAILURE = 'cart/Without/Auth/UPDATE_OPTION_FAILURE';

export const RESET_IS_COMPLETED = 'cart/Without/Auth/RESET_IS_COMPLETED';
export const SET_STATUS_FALSE = 'cart/Without/Auth/SET_STATUS_FALSE';
export const CLEAR_CART = 'cart/Without/Auth/CLEAR_CART';

const _state = {
    items: {},
    loading: false,
    status: false,
    isCompleted: false,
    isCompletedIce: false,
    updateCart: false,
    error: null,
    meta: {},
    isloading: false
};

export default function(state = _state, action) {
    switch (action.type) {
        case ADD_TO_CART_REQUEST:
            return { ...state, loading: true, status: false };
        case ADD_TO_CART_SUCCESS: {
            sessionStorage.setItem('cartItems', JSON.stringify(action.data));
            return {
                ...state,
                loading: false,
                items: action.data.items,
                meta: action.data.meta,
                error: null,
                status: true
            };
        }
        case ADD_TO_CART_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                status: false
            };
        case ADD_TO_CART_ADDSALE_REQUEST:
            return { ...state, loading: true, status: false };
        case ADD_TO_CART_ADDSALE_SUCCESS: {
            sessionStorage.setItem('cartItems', JSON.stringify(action.data));
            return {
                ...state,
                loading: false,
                items: action.data.items,
                meta: action.data.meta,
                error: null,
                status: true
            };
        }
        case ADD_TO_CART_ADDSALE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                status: false
            };
        case FETCH_CART_REQUEST:
            return { ...state, loading: true };
        case FETCH_CART_SUCCESS:
            let datas = action.data.items;
            return {
                ...state,
                loading: false,
                items: datas,
                meta: action.data.meta
            };
        case FETCH_CART_FAILURE:
            return { ...state, loading: false, error: action.error };
        case SET_STATUS_FALSE:
            return { ...state, status: false };
        case REMOVE_FROM_CART_REQUEST:
            return { ...state, loading: true };
        case REMOVE_FROM_CART_SUCCESS: {
            const items = state.items.filter((x) => x.id !== action.data.id);
            sessionStorage.setItem('cartItems', JSON.stringify());
            return {
                ...state,
                loading: false,
                items,
                meta: {}
            };
        }
        case REMOVE_FROM_CART_FAILURE:
            return { ...state, loading: false, error: action.error };
        case COMPLETE_CART_REQUEST:
            return { ...state, isloading: true };
        case COMPLETE_CART_SUCCES:
            return { ..._state, isCompleted: action.data.success, isloading: false };
        case COMPLETE_CART_FAILURE:
            return { ...state, isloading: false, error: action.error };
        case COMPLETE_ICE_REQUEST:
            return { ...state, isloading: true };
        case COMPLETE_ICE_SUCCES:
            return { ..._state, isCompletedIce: action.data.success, isloading: true };
        case COMPLETE_ICE_FAILURE:
            return { ...state, isloading: false, error: action.error };
        case UPDATE_QUANTITY_REQUEST:
            return { ...state, updateCart: true };
        case UPDATE_QUANTITY_SUCCESS: {
            const nextState = produce(state, (draftState) => {
                const { items } = state;
                items[0].quantity = action.data.quantity;
                draftState.items = items;
                draftState.meta.total_price = state.meta.total_price * action.data.quantity;
                draftState.updateCart = false;
            });
            sessionStorage.setItem('cartItems', JSON.stringify({ items: nextState.items, meta: nextState.meta }));
            return nextState;
        }
        case UPDATE_QUANTITY_FAILURE:
            return { ...state, updateCart: false, error: action.error };
        case UPDATE_OPTION_REQUEST:
            return { ...state, updateCart: true };
        case UPDATE_OPTION_SUCCES: {
            const nextState = produce(state, (draftState) => {
                const { items } = state;
                const itemIndex = items.findIndex((item) => item.id === action.data.id);
                items[itemIndex].tipio_offer_option_id = action.data.tipio_offer_option_id;
                items[itemIndex].tipio_offer_option_title = action.data.tipio_offer_option_title;
                items[itemIndex].quantity = '1';
                draftState.items = items;
                draftState.updateCart = false;
            });
            return nextState;
        }
        case UPDATE_OPTION_FAILURE:
            return { ...state, updateCart: false, error: action.error };
        case RESET_IS_COMPLETED:
            return { ...state, loading: false, isCompleted: false };
        case CLEAR_CART:
            return _state;
        default:
            return state;
    }
}

export const actions = {
    request: (tipio, company_id, offer_id, hasOptions, sizeValue, price, isIceProducts) =>
        action(ADD_TO_CART_REQUEST, {
            tipio,
            company_id,
            offer_id,
            hasOptions,
            sizeValue,
            price,
            isIceProducts
        }),
    requestAdditonalSale: (
        additionalSale,
        company_id,
        price,
        additionalSaleId,
        additionalSale_sale_offer_id,
        tipio_id,
        binding_expires_in
    ) =>
        action(ADD_TO_CART_ADDSALE_REQUEST, {
            additionalSale,
            company_id,
            price,
            additionalSaleId,
            additionalSale_sale_offer_id,
            tipio_id,
            binding_expires_in
        }),
    success: (data) => action(ADD_TO_CART_SUCCESS, { data }),
    fetchCart: () => action(FETCH_CART_REQUEST),
    failure: (error) => action(ADD_TO_CART_FAILURE, { error }),
    setStatusFalse: (error) => action(SET_STATUS_FALSE),
    deleteProduct: (productId) => action(REMOVE_FROM_CART_REQUEST, { productId }),
    completeCart: (payload) => action(COMPLETE_CART_REQUEST, { payload }),
    completeIce: (payload) => action(COMPLETE_ICE_REQUEST, { payload }),
    updateQuantity: (item) => action(UPDATE_QUANTITY_REQUEST, { item }),
    updateOption: (itemId, cartId, itemTitle) => action(UPDATE_OPTION_REQUEST, { itemId, cartId, itemTitle }),
    resetIsCompleted: () => action(RESET_IS_COMPLETED)
};

export const sagas = {
    *request({ tipio, company_id, offer_id, hasOptions, sizeValue, price, isIceProducts }) {
        try {
            let items;

            const { item, meta } = getData(tipio, company_id, offer_id, hasOptions, sizeValue, price, isIceProducts);
            items = [item];
            const dispatchData = { items, meta };
            yield put({ type: ADD_TO_CART_SUCCESS, data: dispatchData });
            history.push('/cart');
        } catch (error) {
            yield put({ type: ADD_TO_CART_FAILURE, error: 'Noe gikk galt, prøv igjen senere!' });
        }
    },

    *requestAdditonalSale({
        additionalSale,
        company_id,
        price,
        additionalSaleId,
        additionalSale_sale_offer_id,
        tipio_id,
        binding_expires_in
    }) {
        try {
            let items;
            const { item, meta } = getAdditionalSaleData(
                additionalSale,
                company_id,
                price,
                additionalSaleId,
                additionalSale_sale_offer_id,
                tipio_id,
                binding_expires_in
            );
            items = [item];
            const dispatchData = { items, meta };
            yield put({ type: ADD_TO_CART_ADDSALE_SUCCESS, data: dispatchData });
            history.push('/cart');
        } catch (error) {
            yield put({ type: ADD_TO_CART_ADDSALE_FAILURE, error: 'Noe gikk galt, prøv igjen senere!' });
        }
    },

    *requestFetchCart() {
        try {
            const purchaseData = sessionStorage.getItem('cartItems');
            yield put({ type: FETCH_CART_SUCCESS, data: JSON.parse(purchaseData) });
        } catch (error) {
            yield put({ type: FETCH_CART_FAILURE, error });
        }
    },
    *removeProduct({ productId }) {
        try {
            yield put({ type: REMOVE_FROM_CART_SUCCESS, data: { id: productId } });
        } catch (error) {
            yield put({ type: REMOVE_FROM_CART_FAILURE, error: 'Noe gikk galt, prøv igjen senere!' });
        }
    },
    *completeCart({ payload }) {
        try {
            const response = yield axios.post('temp-users/cart', payload);
            yield put({ type: COMPLETE_CART_SUCCES, data: { success: true } });
            yield put(authActions.success(response.data.data.token, response.data.data.user));
        } catch (error) {
            yield put({ type: COMPLETE_CART_FAILURE, error: error.response.data.errors });
        }
    },
    *completeIce({ payload }) {
        try {
            const response = yield axios.post('temp-users/cart', payload);
            yield put({ type: COMPLETE_ICE_SUCCES, data: { success: true } });
            yield put(authActions.success(response.data.data.token, response.data.data.user));
        } catch (error) {
            yield put({ type: COMPLETE_ICE_FAILURE, error: error.response.data.message });
        }
    },
    *updateQuantity({ item }) {
        try {
            yield put({ type: UPDATE_QUANTITY_SUCCESS, data: item });
        } catch (error) {
            yield put({ type: UPDATE_QUANTITY_FAILURE, error: 'Noe gikk galt, prøv igjen senere!' });
        }
    },
    *updateOption({ itemId, cartId, itemTitle }) {
        try {
            yield put({
                type: UPDATE_OPTION_SUCCES,
                data: { tipio_offer_option_id: itemId, id: cartId, tipio_offer_option_title: itemTitle }
            });
        } catch (error) {
            yield put({ type: UPDATE_OPTION_FAILURE, error: 'Noe gikk galt, prøv igjen senere!' });
        }
    }
};

export const watcher = function*() {
    yield takeEvery(ADD_TO_CART_REQUEST, sagas.request);
    yield takeEvery(ADD_TO_CART_ADDSALE_REQUEST, sagas.requestAdditonalSale);
    yield takeEvery(FETCH_CART_REQUEST, sagas.requestFetchCart);
    yield takeEvery(REMOVE_FROM_CART_REQUEST, sagas.removeProduct);
    yield takeEvery(COMPLETE_CART_REQUEST, sagas.completeCart);
    yield takeEvery(COMPLETE_ICE_REQUEST, sagas.completeIce);
    yield takeEvery(UPDATE_QUANTITY_REQUEST, sagas.updateQuantity);
    yield takeEvery(UPDATE_OPTION_REQUEST, sagas.updateOption);
};
