import { all } from 'redux-saga/effects';
import { watcher as uiWatcher } from './app/ui';
import { watcher as favorite } from './app/favorite';
import { watcher as frontPage } from './app/frontpage/frontpage';
import { watcher as tipioBind } from './app/tipioBind/tipioBind';
import { watcher as notification } from './app/notification';
import { watcher as categories } from './app/categories/index';
import { watcher as additionalSale } from './app/additionalSale';
import { watcher as votingTipios } from './app/viewAllTipio/viewAllVotings';
import { watcher as bindingTipios } from './app/viewAllTipio/viewAllBindings';
import { watcher as settings } from './app/settings/settings';
import { watcher as parserSiteRulesGet } from 'sagas/app/parserSiteRules/get';
import { watcher as parserSiteRulesPost } from 'sagas/app/parserSiteRules/post';
import { watcher as parserSiteRulesPatch } from 'sagas/app/parserSiteRules/patch';
import { watcher as parserSiteRulesParse } from 'sagas/app/parserSiteRules/parse';
import { watcher as search } from 'sagas/app/search';
import { watcher as searchMeta } from 'sagas/app/search/meta';
import { watcher as searchBindingMeta } from 'sagas/app/search/bindingMeta';
import { watcher as shoppingBag } from 'sagas/app/shoppingBag/shoppingBag';
import { watcher as klarnaCheckout } from 'sagas/app/klarna';
import { watcher as klarnaConfirmation } from 'sagas/app/klarna/confirmation';
import { watcher as klarnaComplete } from 'sagas/app/klarna/complete';
import { watcher as stripeCheckout } from 'sagas/app/stripe';
import { watcher as tipioBindRestore } from 'sagas/app/tipioBind/tipioBindRestore';
import { ordersWatchers } from 'sagas/app/orders';
import { watcher as tipios } from 'sagas/app/tipios';
// this will be change
import { profileWatchers } from 'sagas/app/profile';
import { authWatchers } from 'sagas/app/auth';
import { checkoutsWatchers } from 'sagas/app/checkout';
import { tipiosWatchers } from 'sagas/app/tipio';
import { userWatchers } from 'sagas/app/user';

export default function* root() {
    yield all([
        uiWatcher(),
        favorite(),
        categories(),
        frontPage(),
        tipioBind(),
        notification(),
        additionalSale(),
        votingTipios(),
        bindingTipios(),
        settings(),
        search(),
        searchMeta(),
        searchBindingMeta(),
        parserSiteRulesGet(),
        parserSiteRulesPost(),
        parserSiteRulesPatch(),
        parserSiteRulesParse(),
        shoppingBag(),
        klarnaCheckout(),
        klarnaConfirmation(),
        klarnaComplete(),
        stripeCheckout(),
        tipioBindRestore(),
        tipios(),
        ...ordersWatchers,
        ...profileWatchers,
        ...authWatchers,
        ...checkoutsWatchers,
        ...tipiosWatchers,
        ...userWatchers
    ]);
}
