import { put, takeEvery } from 'redux-saga/effects';
import action from 'utils/action-creator';
import axios from 'utils/axios';
import produce from 'immer';
import { actions as authActions } from 'sagas/app/auth/auth';
import history from 'utils/history';

export const GET_REQUEST = '@app/auth/socialAuth/GET_REQUEST';
export const POST_REQUEST = '@app/auth/socialAuth/POST_REQUEST';
export const POST_FAILURE = '@app/auth/socialAuth/POST_FAILURE';
export const FORM_STATUS = '@app/auth/socialAuth/FORM_STATUS';

const _state = {
    formStatus: { loading: false },
    error: null
};

export default (state = _state, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case POST_REQUEST:
                draft.formStatus = { loading: true };
                draft.error = null;
                break;
            case FORM_STATUS:
                draft.formStatus = payload;
                break;
            case POST_FAILURE:
                draft.formStatus.loading = false;
                draft.error = payload;
                break;
            default:
                break;
        }
    });

export const actions = {
    request: (payload) => action(GET_REQUEST, { payload }),
    post: (payload) => action(POST_REQUEST, { payload }),
    failure: (payload) => action(POST_FAILURE, { payload }),
    setFormStatus: (payload) => action(FORM_STATUS, { payload })
};

export const sagas = {
    *request({ payload }) {
        try {
            const response = yield axios.get(
                'socials/generate-auth-url/' + payload.targetSocial + '/' + payload.action
            );
            window.location.href = response.data.data;
        } catch (error) {
            //nothing happend
        }
    },
    *post({ payload }) {
        try {
            const response = yield axios.post('/socials', payload);
            yield put(authActions.success(response.data.data.token, response.data.data.user));
            yield put(actions.setFormStatus({ loading: false }));
        } catch (error) {
            if (error.response.status === 422) {
                history.replace('/social-account-notfound?provider=' + payload.provider);
            } else {
                if (error.response) {
                    yield put(actions.failure(error.response.data.message));
                } else {
                    yield put(actions.failure(error.toString()));
                }
            }
            yield put(actions.setFormStatus({ loading: false }));
        }
    }
};

export const watcher = function*() {
    yield takeEvery(GET_REQUEST, sagas.request);
    yield takeEvery(POST_REQUEST, sagas.post);
};
