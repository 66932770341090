import { put, takeEvery } from 'redux-saga/effects';
import action from 'utils/action-creator';
import axios from 'utils/axios';
import { COUPON_APPLIED } from './cart';

export const COUPONS_REQUEST = 'cart/COUPONS_REQUEST';
export const COUPONS_SUCCES = 'cart/COUPONS_SUCCES';
export const COUPONS_FAILURE = 'cart/COUPONS_FAILURE';
export const COUPONS_RESET_IS_COMPLETED = 'cart/COUPONS_RESET_IS_COMPLETED';

const _state = {
    loading: false,
    success: [],
    error: []
};

export default function(state = _state, action) {
    switch (action.type) {
        case COUPONS_REQUEST:
            return { ...state, loading: true };
        case COUPONS_SUCCES:
            return {
                ...state,
                loading: false,
                success: [...state.success, action.data],
                error: state.error.filter((error) => error.cart_id !== action.data)
            };
        case COUPONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: [...state.error, action.error],
                success: state.success.filter((couponsuccess) => couponsuccess.cart_id !== action.data.cart_id)
            };
        case COUPONS_RESET_IS_COMPLETED:
            return { ...state, loading: false, success: [], error: [] };
        default:
            return state;
    }
}

export const actions = {
    requestCoupon: (payload) => action(COUPONS_REQUEST, { payload }),
    resetIsCompleted: () => action(COUPONS_RESET_IS_COMPLETED)
};

export const sagas = {
    *requestCoupon({ payload }) {
        const cart_id = payload.cart_id;
        try {
            const response = yield axios.post('/cart/apply-coupon/' + cart_id, { code: payload.code });
            yield put({ type: COUPONS_SUCCES, data: cart_id });
            yield put({ type: COUPON_APPLIED, data: response.data.data });
        } catch (error) {
            yield put({ type: COUPONS_FAILURE, error: { cart_id, message: error.response.data.message } });
        }
    }
};

export const watcher = function*() {
    yield takeEvery(COUPONS_REQUEST, sagas.requestCoupon);
};
