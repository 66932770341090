import loadable from '@loadable/component';
import { ContentSpinner } from 'tipio-app-core';

/**
 * Auth Routes
 */
const loading = ContentSpinner({ pt: true });

export const Login = loadable(() => import('./auth/login/Login'), {
    fallback: loading
});

export const ForgotPassword = loadable(() => import('./auth/forgotPassword/ForgotPassword'), {
    fallback: loading
});

export const ResetPassword = loadable(() => import('./auth/resetPassword/ResetPassword'), {
    fallback: loading
});

export const SocialAuth = loadable(() => import('./socialAuth/socialAuth/SocialAuth'), {
    fallback: loading
});

export const CheckoutCompleted = loadable(() => import('./checkout/Completed'), {
    fallback: loading
});

export const PurchaseWithoutAuth = loadable(() => import('./cart/PurchaseWithoutAuth'), {
    fallback: loading
});

export const SocialAccountNotConnected = loadable(
    () => import('./socialAuth/socialAccountNotConnected/SocialAccountNotConnected'),
    {
        fallback: loading
    }
);

/**
 * User Routes
 */

export const Settings = loadable(() => import('./user/settings/Settings'), {
    fallback: loading
});

export const Register = loadable(() => import('./user/register/Register'), {
    fallback: loading
});

export const Campaign = loadable(() => import('./user/campaign/Campaign'), {
    fallback: loading
});

export const CampaignB = loadable(() => import('./user/campaign/CampaignB'), {
    fallback: loading
});

export const RegisterUser = loadable(() => import('./user/register/RegisterUser.tsx'), {
    fallback: loading
});

export const LoginOrRegisterModal = loadable(() => import('./auth/LoginOrRegisterUserModal'), {
    fallback: loading
});

export const UserNotVerified = loadable(() => import('./user/userNotVerified/UserNotVerified.tsx'), {
    fallback: loading
});

export const UserVerify = loadable(() => import('./user/userVerify/UserVerify'), {
    fallback: loading
});

export const EmailVerify = loadable(() => import('./user/emailVerify'), {
    fallback: loading
});

/**
 * Profile Routes
 */

export const Profile = loadable(() => import('./profile'), {
    fallback: loading
});

export const EditProfile = loadable(() => import('./profile/EditProfile'), {
    fallback: loading
});

export const DeleteAccountSuccess = loadable(() => import('./profile/DeleteAccountSuccess'), {
    fallback: loading
});

/**
 * Order Routes
 */

export const Orders = loadable(() => import('./orders/Orders'), {
    fallback: loading
});

export const OrderDetails = loadable(() => import('./orders/OrderDetails'), {
    fallback: loading
});

/**
 * Tipio Routes
 */

export const SubmitLinkForm = loadable(() => import('./tipio/submitLinkForm/SubmitLinkForm'), {
    fallback: loading
});

export const SelectImages = loadable(() => import('./tipio/selectImages/SelectImagesPage'), {
    fallback: loading
});

export const Publish = loadable(() => import('./tipio/publish/Publish'), {
    fallback: loading
});

export const CreateTipio = loadable(() => import('./tipio/create/Create'), {
    fallback: loading
});

export const ViewRestoreTipio = loadable(() => import('./tipio/restore/ViewTipioInformation'), {
    fallback: loading
});

export const ViewTipio = loadable(() => import('./tipio/restore/ViewTipio'), {
    fallback: loading
});

export const Tipios = loadable(() => import('./tipios/Tipios'), {
    fallback: loading
});

export const TipioVote = loadable(() => import('./tipioVote/TipioVote'), {
    fallback: loading
});

export const TipioVoteEdit = loadable(() => import('./tipioVote/TipioVoteEdit'), {
    fallback: loading
});

export const TipioBuy = loadable(() => import('./tipioView/tipioView/TipioBuy'), {
    fallback: loading
});

export const TipioExists = loadable(() => import('./tipioView/tipioView/TipioExists'), {
    fallback: loading
});

export const TipioBind = loadable(() => import('./tipioBind/TipioBind'), {
    fallback: loading
});

export const ViewAdditionalSalePage = loadable(() => import('./tipioBind/ViewAdditionalSale'), {
    fallback: loading
});

export const RestoreOffer = loadable(() => import('./tipioBind/RestoreOffer'), {
    fallback: loading
});

export const ParserSiteRules = loadable(() => import('./parserSiteRules'), {
    fallback: loading
});

/**
 * Search Routes
 */

export const Search = loadable(() => import('./search/Search'), {
    fallback: loading
});

/**
 * About Routes
 */

export const About = loadable(() => import('./about/About'), {
    fallback: loading
});

/**
 * FrontPage Routes
 */

export const Frontpage = loadable(() => import('./frontpage/Frontpage'), {
    fallback: loading
});

/**
 * Binding Routes
 */

export const ViewAllBindings = loadable(() => import('./viewAllBindings/ViewAllBindings'), {
    fallback: loading
});

/**
 * Voting Routes
 */

export const ViewAllVotings = loadable(() => import('./viewAllVotings/ViewAllVotings'), {
    fallback: loading
});

/**
 * Checkout/Cart Routes
 */

export const Checkout = loadable(() => import('./checkout'), {
    fallback: loading
});

export const Cart = loadable(() => import('./cart/Cart'), {
    fallback: loading
});

export const IceCheckout = loadable(() => import('./cart/IceCheckout'), {
    fallback: loading
});
export const StripeCheckout = loadable(() => import('./checkout/StripeCheckout'), {
    fallback: loading
});
/**
 * NotFound Routes
 */
export const NotFound = loadable(() => import('./NotFound'), {
    fallback: loading
});
/**
 * Temp Routes
 */
export const NewGoogleAccount = loadable(() => import('./temp/NewGoogleAccount'), {
    fallback: loading
});

export const SplitPage = loadable(() => import('./temp/SplitPage'), {
    fallback: loading
});
