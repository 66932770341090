import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import createAction from 'utils/action-creator';
import axios from 'utils/axios';
import describeTipioError from 'utils/describeTipioError';
import history from 'utils/history';

export const REQUEST = '@app/tipio/save/create/REQUEST';
export const SUCCESS = '@app/tipio/save/create/SUCCESS';
export const SET_FORM_IS_SUBMITTING = '@app/tipio/save/create/SET_FORM_IS_SUBMITTING';
export const SET_FORM_STATUS = '@app/tipio/save/create/SET_FORM_STATUS';
export const SET_ERROR = '@app/tipio/save/create/SET_ERROR';
export const CLEAR_FORM = '@app/tipio/save/create/CLEAR_FORM';
export const CLEAN_UP = '@app/tipio/save/create/CLEAN_UP';

const _state = {
    error: null,
    isSubmitting: false,
    formStatus: false,
    tipio: null
};

const reducer = (state = _state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SET_FORM_IS_SUBMITTING:
                draft.isSubmitting = action.payload;
                break;
            case SET_FORM_STATUS:
                draft.formStatus = action.payload;
                break;
            case SUCCESS:
                draft.tipio = action.payload;
                break;
            case CLEAN_UP:
                return _state;
            default:
                break;
        }
    });
export default reducer;

export const actions = {
    request: (payload) => createAction(REQUEST, { payload }),
    setError: (payload) => createAction(SET_ERROR, { payload }),
    setFormIsSubmiting: (payload) => createAction(SET_FORM_IS_SUBMITTING, { payload }),
    clear: (payload) => createAction(CLEAR_FORM, { payload }),
    success: (payload) => createAction(SUCCESS, { payload }),
    cleanup: () => createAction(CLEAN_UP)
};

export const sagas = {
    *request(action) {
        yield put(actions.setFormIsSubmiting(true));
        const { payload } = action;
        try {
            const response = yield axios.post('/tipios', payload);
            const result = response.data;
            yield put(createAction(SUCCESS, { payload: result.data }));
            yield put(actions.setFormIsSubmiting(false));
            yield put(
                createAction(SET_FORM_STATUS, {
                    payload: {
                        success: true
                    }
                })
            );
        } catch (error) {
            yield put(actions.setFormIsSubmiting(false));
            yield put(actions.setError((error.response.data && describeTipioError(error.response.data)) || 'Error!'));

            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.url) {
                const [err] = error.response.data.errors.url;
                if (err === 'errors.tipio_exists') {
                    const { context } = error.response.data;
                    history.push(`/tipio/exists/${context.tipio_id}`);
                }
            }
        }
    },
    *setError(action) {
        yield put(createAction(SET_FORM_IS_SUBMITTING, { payload: false }));
        yield put(
            createAction(SET_FORM_STATUS, {
                payload: {
                    success: false,
                    message: action.payload
                }
            })
        );
    },
    *clearForm() {
        yield put(createAction(SET_FORM_STATUS, null));
    }
};

export const watcher = function* w() {
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(SET_ERROR, sagas.setError);
    yield takeLatest(CLEAR_FORM, sagas.clearForm);
};
