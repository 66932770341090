import React from 'react';
import useL10N from '../hooks/useL10N';

const withL10N = (WrappedComponent) => {
    return (props) => {
        const { t } = useL10N();

        return <WrappedComponent {...props} t={t} />;
    };
};

export default withL10N;
